import { Pressable, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Button } from "../../components/input";
import React, { useContext, useEffect, useState } from "react";
import { CartItem } from "../../components/shop";
import { ProductsContext } from "../../context/productsContext";
import { Card } from "../../components/card";
import Toast from "react-native-toast-message";
import {
  getProductCartItems,
  postClearProductCartItems,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { ActivityIndicator } from "react-native";
export const Cart = ({ navigation }) => {
  const { user, userToken } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const {
    cart,
    isProductsLoading,
    setProductsApiError,
    setIsProductsLoading,
    setCart,
    selectedCartItem,
  } = useContext(ProductsContext);
  const [haveCoupon, setHaveCoupon] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isClearingCart, setIsClearingCart] = useState(false);
  const back = navigation.canGoBack();
  useEffect(() => {
    const getCartItems = async () => {
      try {
        setIsProductsLoading(true);
        setProductsApiError(null);
        const res = await getProductCartItems(userToken);
        console.log("cart, getCartItems api res : ", res);
        if (res.status === 200) {
          setCart(res.data.data);
        } else {
          console.log("cart, getCartItems api res error : ", res.data);
          setProductsApiError(res.data);
        }
      } catch (error) {
        console.log("cart, getCartItems error : ", error);
      } finally {
        setIsProductsLoading(false);
      }
    };
    getCartItems();
  }, []);
  useEffect(() => {
    let total = 0;
    cart.forEach((e) => {
      const amount =
        ((e.quantity || 0) * (e.item.sale_price || e.item.price || 0)) / 100;
      total = total + amount;
    });
    setTotalAmount(total.toFixed(2));
  }, [cart]);
  const dismiss = () => {
    if (back) {
      navigation.goBack();
    } else {
      navigation.navigate("home");
    }
  };
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const onClearCart = async () => {
    try {
      setIsClearingCart(true);
      const res = await postClearProductCartItems({ token: userToken });
      if (res.status === 200) {
        setCart([]);
      } else {
        console.log("cart, onClearCart api res error : ", res.data);
      }
    } catch (error) {
      console.log("cart, onClearCart error : ", error);
    } finally {
      setIsClearingCart(false);
    }
  };

  const calculateAmountForEachItem = (id, qty) => {
    const filterArray = cart.filter((e) => e.id === id);
    let amt = 0;
    if (filterArray.length >= 1) {
      amt =
        (qty * (filterArray[0].item.sale_price || filterArray[0].item.price)) /
        100;
      return amt;
    }
  };
  // console.log(selectedCartItem, "selectedCartItem");

  return (
    <View className="flex-1 bg-transparent">
      <Pressable
        className="w-full h-full absolute top-0 left-0 bg-transparent"
        onPress={dismiss}
      ></Pressable>
      <View className="absolute w-full h-full lg:w-96 lg:h-[70%] xl:h-[50%] top-0 right-0 lg:top-16 lg:right-52 bg-app-e lg:bg-white rounded-none lg:rounded-lg lg:border lg:border-gray-200 overflow-hidden">
        <View className="w-full h-16 lg:h-14 bg-app-e lg:bg-app-a border-b border-b-transparent lg:border-b-gray-200 items-center flex-row px-1 lg:px-4">
          <Pressable
            className="p-3 rounded-full active:bg-app-c3 flex lg:hidden"
            onPress={dismiss}
          >
            <RemixIcon
              name="arrow-left-line"
              className="flex lg:hidden"
              color={colors.app.a}
              size="22"
            ></RemixIcon>
            <RemixIcon
              name="arrow-left-line"
              className="hidden lg:flex"
              color={colors.app.d1}
              size="22"
            ></RemixIcon>
          </Pressable>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px]  px-2">
            Cart
          </Font>
          <View className="flex-1"></View>
        </View>
        <View className="rounded-t-lg lg:rounded-none overflow-hidden flex-1">
          <ScrollView className="rounded-t-lg lg:rounded-none bg-gray-100 w-full p-4">
            <View className="flex-1 w-full justify-center items-center mb-6">
              {cart.length > 0 && !isProductsLoading ? (
                cart.map((e, i) => (
                  <Card key={`cart_${i}`}>
                    <View className="flex-1 w-full">
                      <View className="flex-1 w-full">
                        <CartItem
                          // setSelectedItem={setSelectedItem}
                          // selectedItem={selectedItem}
                          multipleSelection={true}
                          data={e}
                          input={true}
                        ></CartItem>
                      </View>
                      <View className="flex-row px-4 pt-2 pb-0">
                        <Font className="text-app-d" weight={600}>
                          Total
                        </Font>
                        <View className="flex-1"></View>

                        <Font className="text-app-d" weight={600}>
                          ${calculateAmountForEachItem(e.id, e.quantity)}
                        </Font>
                      </View>
                    </View>
                  </Card>
                ))
              ) : cart.length === 0 && !isProductsLoading ? (
                <Card
                  // title={`Reviews`}
                  className="flex"
                >
                  <View className="w-full flex-row h-80 justify-center items-center">
                    <Font className="text-app-d1">No items in your cart.</Font>
                  </View>
                </Card>
              ) : (
                <Card className="w-full flex-1 ">
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                </Card>
              )}
              {/* ------------ clear cart ------------------ */}
              {/* {cart.length > 0 && !isProductsLoading && !isClearingCart ? (
                <View className="flex-1 w-full pt-2">
                  <Button
                    label="Clear cart"
                    type="primary"
                    onPress={onClearCart}
                  ></Button>
                </View>
              ) : (
                isClearingCart && (
                    <View className="w-full pt-2 flex-1 lg:w-1/2 bg-app-e h-10 rounded-lg justify-center">
                      <ActivityIndicator
                        size={"small"}
                        color={colors.app.a}
                      ></ActivityIndicator>
                    </View>
                )
              )} */}
            </View>
          </ScrollView>
          {cart.length > 0 && !isProductsLoading && (
            <View className="w-full border-t border-gray-200 bg-app-a">
              <View className=" flex-row px-4 py-2 my-2">
                <View className="justify-center">
                  <View className="flex-row my-1">
                    <Font className="text-app-d1" weight={700}>
                      Total:
                    </Font>
                    <Font className="text-app-d ml-2" weight={700}>
                      $ {totalAmount}
                    </Font>
                  </View>
                  <View className="flex-row my-1">
                    <Font className="text-app-d1" weight={700}>
                      Wallet Balance:
                    </Font>
                    <Font className="text-app-d ml-2" weight={700}>
                      ${" "}
                      {((userData?.wallet_balance?.amount || 0) / 100).toFixed(
                        2
                      )}
                    </Font>
                  </View>
                </View>
                <View className="flex-1"></View>
                <View className="w-32">
                  <Button
                    label="Checkout"
                    type={`${(user && selectedCartItem.length) ? "primary" : "disabled"}`}
                    className="py-2 px-4"
                    // disabled={selectedCartItem.length == 0 ? true : false}
                    onPress={
                      user
                        ? () => {
                            selectedCartItem.length == 0
                              ? showToast("error", "Please select an Item !", "")
                              : navigation.navigate("checkout");
                          }
                        : () => {}
                    }
                  ></Button>
                </View>
              </View>
              {!user && (
                <View className="flex-row w-4/5 mx-auto justify-center py-1 mb-3 bg-app-c rounded-lg">
                  <Font className="text-app-d">Please login to proceed.</Font>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
