import React, { useContext, useState } from "react";
import {
  ActivityIndicator,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { FaqItem } from "../../components/faq";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Input } from "../../components/input";
import { axiosPost } from "../../services/api";
import axios from "axios";
import Toast from "react-native-toast-message";

export const ContactUs = ({ navigation, route }) => {
  const [contactUsData, setContactUsData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [inputError, setInputError] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };

  const isInputValid = () => {};

  const sendContactInfo = async () => {
    setIsLoading(true);

    // contactUsData.name === ""
    //   ? setInputError((e) => ({ ...e, name: true }))
    //   : setInputError((e) => ({ ...e, name: false }));

    // contactUsData.email === ""
    //   ? setInputError((e) => ({ ...e, email: true }))
    //   : setInputError((e) => ({ ...e, email: false }));

    // contactUsData.phone === ""
    //   ? setInputError((e) => ({ ...e, phone: true }))
    //   : setInputError((e) => ({ ...e, phone: false }));

    // contactUsData.message === ""
    //   ? setInputError((e) => ({ ...e, message: true }))
    //   : setInputError((e) => ({ ...e, message: false }));

    if (
      contactUsData.name === "" ||
      contactUsData.email === "" ||
      contactUsData.phone === "" ||
      contactUsData.message === ""
    ) {
      showToast("error", "Error", "Please fill all the fields.");
      setIsLoading(false);
      return;
    }
    const data = {
      name: contactUsData.name,
      email: contactUsData.email,
      phone: contactUsData.phone,
      message: contactUsData.message,
    };

    const response = await axiosPost({
      Url: `/enquiries/contact-us`,
      data,
    })
      .then((res) => {
        setContactUsData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        showToast("success", "Success", "Successful!");
      })
      .catch((error) => {
        console.log("contact us , sendContactInfo error : ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const setIsInputValid = ({ e, type }) => {
    switch (type) {
      case "email":
        if (e === true) {
          setInputError((e) => ({ ...e, email: false }));
        } else {
          setInputError((e) => ({ ...e, email: true }));
        }
        break;
      default:
        break;
    }
  };
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">Contact Us</Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Contact Us">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-col lg:flex-row mb-1">
                    <Input
                      type="text"
                      label={"Name"}
                      placeholder="Full name"
                      name="name"
                      className="w-full lg:w-1/2"
                      value={contactUsData.name}
                      setValue={(v) =>
                        setContactUsData((e) => ({
                          ...e,
                          name: v,
                        }))
                      }
                    ></Input>
                    {inputError.name && (
                      <Text className="w-full lg:w-1/2 mb-2 ml-0 lg:ml-4 lg:mb-0 break-all break-normal text-red-500 ">
                        Please Enter The Valid Name
                      </Text>
                    )}
                    <Input
                      type="email"
                      label={"Email"}
                      placeholder="Email address"
                      className="w-full lg:w-3/4 lg:ml-4"
                      value={contactUsData.email}
                      setValue={(v) =>
                        setContactUsData((e) => ({
                          ...e,
                          email: v,
                        }))
                      }
                      validation={true}
                      isValid={(e) => setIsInputValid({ e, type: "email" })}
                    ></Input>
                  </View>
                  <View className="flex-col lg:flex-row mb-1">
                    {inputError.email ? (
                      <Text className="w-full lg:w-1/2 mb-2 ml-0 lg:ml-4 lg:mb-0 break-all break-normal text-red-500 ">
                        Please Enter The Valid Email
                      </Text>
                    ) : null}
                  </View>
                  <View className="flex-row mb-1.5 mt-2">
                    <Input
                      type="number"
                      label={"Contact"}
                      placeholder="Contact number"
                      value={contactUsData.phone}
                      setValue={(v) =>
                        setContactUsData((e) => ({
                          ...e,
                          phone: v,
                        }))
                      }
                      validation={false}
                    ></Input>
                  </View>
                  {inputError.phone ? (
                    <Text className="w-full lg:w-1/2 mb-2 lg:mb-0 break-all break-normal text-red-500">
                      Please Enter The Valid Field
                    </Text>
                  ) : null}
                  <View className="flex-row mb-1.5 mt-2 w-full">
                    <Input
                      type="text"
                      label={"Message"}
                      placeholder="Message"
                      className="w-full"
                      value={contactUsData.message}
                      setValue={(v) =>
                        setContactUsData((e) => ({
                          ...e,
                          message: v,
                        }))
                      }
                      validation={false}
                    ></Input>
                  </View>
                  {inputError.message ? (
                    <Text className="w-full lg:w-1/2 mb-2 lg:mb-0 break-all break-normal text-red-500">
                      Please Enter The Valid Field
                    </Text>
                  ) : null}
                  <View className="mt-3">
                    <Button
                      type="primary"
                      label="Send"
                      className="lg:w-60 lg:self-end"
                      onPress={sendContactInfo}
                      waitState={isLoading}
                    ></Button>
                  </View>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
    </View>
  );
};
