import {
  View,
  Image,
  Pressable,
  ScrollView,
  ActivityIndicator,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { useContext, useState } from "react";
import { Card } from "../../components/card";
import RemixIcon from "react-native-remix-icon";
import {
  DEV_API,
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { getFullDate, getFullDateTime } from "../../../utils/date-time-util";
import colors from "../../../colors";
import Toast from "react-native-toast-message";
import { useEffect } from "react";
import { Unautorized } from "./unautorized";
import { Widget } from "../../components/widget";
import { Footer } from "../../components/footer";
// import { SafeAreaView } from "react-native-web";
import { AntDesign } from "@expo/vector-icons";
import { UserContext } from "../../context/userContext";
import ConfirmModal from "../../components/ConfirmModal";

export function Reviews({ navigation, route }) {
  const slug = route?.params?.slug;
  const { user, userToken, isAuthLoading } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const [newReview, setNewReview] = useState({
    message: "",
    rating: 0,
  });
  const [newReply, setNewReply] = useState({
    message: "",
  });
  const myReviewId = 1000;
  const [reviews, setReviews] = useState([]);
  const [replies, setReplies] = useState({});
  const [userReview, setUserReview] = useState(null);
  const [userReplies, setUserReplies] = useState(null);
  const [isReviewsLoading, setIsReviewsLoading] = useState(false);
  const [replyToReviewInput, setReplyToReviewInput] = useState(null);
  const [addReviewVisible, setAddReviewVisible] = useState();
  const [isEditModal, setIsEditModal] = useState({
    visible: false,
    message: "",
    rating: "",
    id: "",
    index: "",
  });
  const [isDeleteModal, setIsDeleteModal] = useState({
    visible: false,
    id: "",
  });
  const [showReviewId, setShowReviewId] = useState({
    id: null,
    showReplies: false,
    showAddReplyToReview: false,
  });
  const [isDeleteReviewInProgress, setIsDeleteReviewInProgress] =
    useState(false);

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const getListingReviewData = async () => {
    await GetReviews();
    await GetMyReview();
  };
  const GetProductReviewsData = async () => {
    await GetReviews();
    await GetMyReview();
  };
  useEffect(() => {
    if (route.params.type === "listing") {
      getListingReviewData();
    } else {
      GetProductReviewsData();
    }
  }, [route.params.slug]);

  const GetMyReview = async () => {
    setIsReviewsLoading(true);
    await axiosGet({
      Url: `/${route.params.type === "listing" ? "listings" : "products"}/${
        route.params.slug
      }/reviews/mine`,
      token: userToken,
    })
      .then((data) => {
        // console.log(data,"Data review check");

        setUserReview(data?.data?.data);
        setIsReviewsLoading(false);
      })

      .catch((error) => {
        setUserReview(null);
        console.log("Review, GetMyReview error: ", error);
        showToast("error", "Error :", error);
      })
      .finally(() => {
        setIsReviewsLoading(false);
      });
  };
  let data;
  const GetReviews = async () => {
    setIsReviewsLoading(true);
    const res = await axiosGet({
      Url: `/${route.params.type === "listing" ? "listings" : "products"}/${
        route.params.slug
      }/reviews`,
      token: userToken,
    })
      .then((data) => {
        setReviews(data.data?.data);
        setIsReviewsLoading(false);
      })
      .catch((error) => {
        console.log("Review, getReview error: ", error);
        showToast("error", "Error :", error);
      })
      .finally(() => {
        setIsReviewsLoading(false);
      });
  };
  console.log("reviews -------------: ", reviews);
  useEffect(() => {
    if (route.params.slug) {
      GetProductReviews();
    }
  }, [route.params.slug]);
  const GetProductReviews = async () => {
    setIsReviewsLoading(true);
    if (route.params.type === "product") {
      await axiosGet({
        Url: `/products/${route.params.slug}/reviews`,
        token: userToken,
      })
        .then((data) => {
          setReviews(data.data?.data);
          setIsReviewsLoading(false);
        })
        .catch((error) => {
          console.log("Review, GetProductReviews error: ", error);
          showToast("error", "Error :", error);
        })
        .finally(() => {
          setIsReviewsLoading(false);
        });
    }
  };

  const getRatingStars = ({ rating }) => {
    console.log(rating, "rating");

    const rating1 = [...Array(Math.floor(rating)).keys()];
    const ishalfStar = rating1 % 1 === 0.5;
    return (
      <>
        {rating1.map((e, i) => (
          <RemixIcon
            name="star-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
            key={`icon_${i}`}
          ></RemixIcon>
        ))}
        {ishalfStar && (
          <RemixIcon
            name="star-half-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
          ></RemixIcon>
        )}
      </>
    );
  };

  const submitReviewHandler = async () => {
    setAddReviewVisible(true);
    if (newReview.review !== "") {
      await axiosPost({
        Url: `/listings/${slug}/reviews/`,

        token: userToken,
        data: newReview,
      })
        .then((data) => {
          setNewReview({
            message: "",
            rating: 0,
          });
          const updatedReviews = [...reviews];
          updatedReviews.unshift(data.data.data);
          setReviews(updatedReviews);
          setUserReview(data.data.data);
          showToast("success", "Success", "Successful.");
          setAddReviewVisible(false);
          console.log(data, " review data ");
        })
        .catch((error) => {
          showToast("error", "Review can only be posted once.", "");
          console.log(
            "review, submitReviewHandler, error ---------------- : ",
            error
          );
        })
        .finally(() => {});
    }
  };

  const updateReviewHandler = async (rating, message, id) => {
    setAddReviewVisible(true);
    if (rating == "" || message == "") {
      return;
    }
    await axiosPatch({
      Url: `/${
        route.params.type === "product" ? "products" : "listings"
      }/${slug}/reviews/${id}`,

      token: userToken,
      data: { rating, message },
    })
      .then((data) => {
        // setNewReview({
        //   message: "",
        //   rating: 0,
        // });
        const updatedReviews = [...reviews];
        // updatedReviews.unshift(data.data.data);
        const index = updatedReviews.findIndex(
          (r) => r.user_id === userData.id
        );
        if (index > -1) {
          updatedReviews[index].rating = rating;
          updatedReviews[index].message = message;
          setUserReview(updatedReviews[index]);
          setReviews(updatedReviews);
        }
        setIsEditModal((...e) => ({ ...e, visible: false }));
        showToast("success", "Success", "Edited Review");
        setAddReviewVisible(false);
        console.log(data, " review data ");
      })
      .catch((error) => {
        showToast("error", "Review can only be posted once.", "");
        console.log(
          "review, submitReviewHandler, error ---------------- : ",
          error
        );
      })
      .finally(() => {});
  };
  const SubmitRepliesHandler = async (review_id) => {
    // setAddReviewVisible(true);
    //if (newReview.review !== "") {

    await axiosPost({
      Url: `/listings/${route.params.slug}/reviews/${review_id}/replies/`,

      token: userToken,
      data: newReply,
    })
      //console.log(("route.params.slug" ,route.params.slug))
      .then((data) => {
        setNewReply({
          message: "",
        });

        // setReplyToReviewInput("");
        // const array = [...vendorReviews?.reviews];
        // const index = array.findIndex((e) => e.id === slug);
        // if (index > -1) {
        //   array[index].replies.unshift(data.data.data);
        // }
        // setVendorReviews((e) => ({ ...e, reviews: array }));
        // showToast("success", "Success", "Successful.");

        const updatedReviews = [...replies];
        const index = updatedReviews.findIndex((e) => e.id === review_id);
        if (index > -1) {
          updatedReviews[index].replies.unshift(data.data.data);
        }

        updatedReviews.unshift(data.data.data);
        setReviews((e) => ({ ...e, reviews: updatedReviews }));
        //setUserReview(data?.data);
        // setUserReplies(data.data.data);
        showToast("success", "Success", "Successful.");
        //setAddReviewVisible(false);
      })
      // console.log("Replies" ,data)
      .catch((error) => {
        console.log(
          "review, submitReviewHandler, error ---------------- : ",
          error
        );
        if (error.response) {
          // The request was made and the server responded with a status code
          // showToast("error", "Response Status:", error.response.status);
          showToast("error", "Error", error.response);
        } else if (error.request) {
          // The request was made but no response was received
          showToast("error", "No response received:", error.request);
          console.log("No response received : ", error.response.data);
        } else {
          // Something happened in setting up the request that triggered an error
          showToast("error", "Error:", error.message);
          console.log("Error : ", error.response.data);
        }
        // showToast("error", "Error Config:", error.config);
        console.log("Error Config : ", error.response.data);
      })
      .finally(() => {});
    //}
  };
  const submitProductReviewHandler = async () => {
    setAddReviewVisible(true);
    if (newReview.review !== "") {
      await axiosPost({
        Url: `/products/${slug}/reviews/`,
        token: userToken,
        data: newReview,
      })
        .then((data) => {
          setNewReview({
            message: "",
            rating: 0,
          });
          const updatedReviews = [...reviews];
          updatedReviews.unshift(data.data.data);
          setReviews(updatedReviews);
          setUserReview(data.data.data);
          showToast("success", "Success", "Successful.");
          setAddReviewVisible(false);
        })
        .catch((error) => {
          console.log("review , submitProductReviewHandler error : ", error);
        })
        .finally(() => {});
    }
  };
  const onDeleteReview = async (review_id) => {
    setIsDeleteReviewInProgress(true);
    await axiosDelete({
      Url: `/${route.params.type === "listing" ? "listings" : "products"}/${
        route.params.slug
      }/reviews/${review_id}`,
      token: userToken,
    })
      .then((data) => {
        setUserReview(null);
        setReviews((e) => e.filter((ee) => ee.id !== review_id));
        showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("vendor, onDeleteReview error: ", err);
      })
      .finally(() => {
        setIsDeleteReviewInProgress(false);
      });
  };

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50 h-full">
      <View className="flex-1">
        <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
          <ScrollView
            className="flex-1 p-4 rounded-t-2xl h-4"
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: "center",
            }}
            overScrollMode="never"
            keyboardShouldPersistTaps="always"
            // refreshControl={
            //   <RefreshControl
            //     refreshing={refreshing}
            //     onRefresh={() => {
            //       getWishlist();
            //     }}
            //   />
            // }
          >
            <View className="flex-1 w-full justify-center items-center ">
              <View className="flex-col w-full lg:w-11/12 lg:px-4">
                <View className="my-5 hidden lg:flex">
                  <Font className="text-xl font-semibold text-app-d">
                    Reviews
                  </Font>
                </View>
                <View className="w-full flex-col lg:flex-row">
                  <View className="w-full flex-1">
                    {/* ------------------ add your review && your reviews ------------- */}
                    {!isReviewsLoading && userReview === null ? (
                      <Card title="Add a review" className="mt-1">
                        <View className="w-full flex-col sm:flex-row justify-between items-center p-2 ">
                          <View className="w-full">
                            <View className="w-full flex-row items-center justify-start py-3">
                              {[...Array(5).keys()].map((e, i) => (
                                <Pressable
                                  onPress={() =>
                                    setNewReview((prev) => ({
                                      ...prev,
                                      rating: i + 1,
                                    }))
                                  }
                                  key={`starts_${i}`}
                                >
                                  <RemixIcon
                                    name={
                                      newReview.rating === 0
                                        ? "ri-star-line"
                                        : i + 1 <= newReview.rating
                                        ? "ri-star-fill"
                                        : "ri-star-line"
                                    }
                                    size={20}
                                    color={colors.app.i}
                                    className="flex-shrink-0"
                                    key={`icon_${i}`}
                                  ></RemixIcon>
                                </Pressable>
                              ))}
                            </View>
                            <View className="flex-row items-start justify-center pb-3">
                              <Input
                                type="longtext"
                                value={newReview.message}
                                setValue={(e) => {
                                  setNewReview((ee) => ({
                                    ...ee,
                                    message: e,
                                  }));
                                }}
                                // label="Email"
                                placeholder="Review"
                                // validation={true}
                                // isValid={(e) => setIsInputValid({ e, type: "email" })}
                              ></Input>
                            </View>
                            <View className="w-full flex-row items-center justify-center">
                              <Button
                                type="primary"
                                label="Post review"
                                onPress={
                                  route.params.type === "product"
                                    ? submitProductReviewHandler
                                    : submitReviewHandler
                                }
                              />
                            </View>
                          </View>
                        </View>
                      </Card>
                    ) : (
                      !isReviewsLoading &&
                      userReview !== null && (
                        <Card
                          title={`Your review`}
                          Widget={() => (
                            // <Pressable
                            //   className="px-2 py-1 bg-app-e3 rounded-md"
                            //   onPress={() => {
                            //     onDeleteReview(userReview?.id);
                            //   }}
                            // >
                            //   {isDeleteReviewInProgress ? (
                            //     <ActivityIndicator
                            //       size={"small"}
                            //       color={colors.app.e}
                            //     ></ActivityIndicator>
                            //   ) : (
                            //     <AntDesign
                            //       name="delete"
                            //       size={20}
                            //       color={colors.app.e}
                            //     />
                            //   )}
                            // </Pressable>
                            <View className="ml-2 flex-row">
                              <Pressable
                                className="p-0.5 mr-2"
                                onPress={() => {
                                  setIsEditModal(() => ({
                                    visible: true,
                                    message: userReview?.message,
                                    rating: userReview?.rating,
                                    id: userReview?.id,
                                    // index: i,
                                  }));
                                }}
                              >
                                <RemixIcon
                                  name="ri-pencil-fill"
                                  className="self-start"
                                  size={22}
                                  color={colors.app.d2}
                                  // color="white"
                                />
                              </Pressable>
                              <Pressable
                                className="p-0.5"
                                onPress={() => {
                                  setIsDeleteModal(() => ({
                                    visible: true,
                                    id: userReview?.id,
                                  }));
                                }}
                              >
                                <AntDesign
                                  name="delete"
                                  size={20}
                                  color={colors.app.e}
                                />
                              </Pressable>
                            </View>
                          )}
                          className="flex-1 w-full"
                        >
                          <ScrollView
                            className="flex-1 w-full p-4 py-0"
                            keyboardShouldPersistTaps="always"
                          >
                            <View className="w-full flex-row justify-between items-center bg-app-b rounded-lg">
                              <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                                <Image
                                  // resizeMode="contain"
                                  source={
                                    userReview?.user_profile_image_url &&
                                    userReview?.user_profile_image_url !== ""
                                      ? {
                                          uri: `${DEV_API}/media/${userReview?.user_profile_image_url}`,
                                        }
                                      : require("../../../assets/user1.jpg")
                                  }
                                  className="w-10 h-10 rounded-full"
                                ></Image>
                              </View>
                              <View className="flex-1 flex-col rounded-md bg-app-c2">
                                <View className="w-full flex-row px-3 pt-1">
                                  <Font className="py-1 font-bold">
                                    {userReview?.user_name}
                                  </Font>
                                </View>
                                <View className="w-full pt-1 px-3">
                                  <Font className="text-xs text-app-d1">
                                    {getFullDateTime(userReview?.created_at)}
                                  </Font>
                                </View>

                                <View className="w-full flex-row py-2 px-3">
                                  {getRatingStars({
                                    rating: userReview?.rating,
                                  })}
                                </View>
                                <View className="w-full px-3 pb-3">
                                  <Font>{userReview?.message}</Font>
                                </View>
                                <View className="w-full flex-row items-center px-3 py-1">
                                  <View className="w-full flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {userReview?.replies?.length ?? 0}
                                    </Font>
                                    <Pressable
                                      onPress={() =>
                                        setShowReviewId((prev) => ({
                                          ...prev,
                                          id:
                                            prev.id ===
                                              `${userReview?.id}_user` &&
                                            prev.showReplies
                                              ? null
                                              : prev.id ===
                                                  `${userReview?.id}_user` &&
                                                prev.showAddReplyToReview
                                              ? `${userReview?.id}_user`
                                              : `${userReview?.id}_user`,
                                          showReplies:
                                            prev.id ===
                                              `${userReview?.id}_user` &&
                                            prev.showReplies
                                              ? false
                                              : prev.id ===
                                                  `${userReview?.id}_user` &&
                                                !prev.showReplies
                                              ? true
                                              : true,
                                          showAddReplyToReview: false,
                                        }))
                                      }
                                    >
                                      <Font className="text-xs pr-2 text-app-d">
                                        Replies
                                      </Font>
                                    </Pressable>
                                  </View>
                                </View>
                                <View className="w-full flex-col ">
                                  {/* ---------------------- add reply to a review ------------------------ */}
                                  {showReviewId.id ===
                                    `${userReview?.id}_user` &&
                                    showReviewId.showAddReplyToReview && (
                                      <View className="w-full flex-col py-1 px-3 my-3">
                                        <View className="w-full pb-1">
                                          <Font>Reply to this review</Font>
                                        </View>
                                        <View className="sm:w-full flex-col sm:flex-row justify-between">
                                          <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                            <Input
                                              type="text"
                                              value={replyToReviewInput}
                                              setValue={(ee) => {
                                                setReplyToReviewInput(ee);
                                              }}
                                              placeholder="reply to this comment..."
                                            ></Input>
                                            <Pressable className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md">
                                              <RemixIcon
                                                name="ri-send-plane-2-line"
                                                size={15}
                                              />
                                            </Pressable>
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                  {/* ---------------------- show replies to a review ------------------------ */}
                                  {showReviewId.id ===
                                    `${userReview?.id}_user` &&
                                    showReviewId.showReplies &&
                                    userReview?.replies?.map((eee, i) => (
                                      <View
                                        className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                        key={`comment_replies${i}`}
                                      >
                                        <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                          <Image
                                            // resizeMode="contain"
                                            source={require("../../../assets/user1.jpg")}
                                            className="w-10 h-10 rounded-full"
                                          ></Image>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row px-3 pt-1">
                                            <Font className="w-full py-1 font-bold">
                                              user_name
                                            </Font>
                                          </View>
                                          <View className="w-full pt-1 px-3">
                                            <Font className="text-xs text-app-d1">
                                              1-2-23
                                            </Font>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                              <Font>
                                                this is reply to other comment
                                              </Font>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    ))}
                                </View>
                              </View>
                            </View>
                          </ScrollView>
                        </Card>
                      )
                    )}
                    {reviews?.length > 0 && !isReviewsLoading ? (
                      <Card title={`All reviews`} className="flex-1 w-full">
                        <ScrollView
                          className="flex-1 w-full p-4 pt-0"
                          keyboardShouldPersistTaps="always"
                        >
                          {reviews?.map((e, i) => (
                            <View
                              className="w-full flex-row justify-between items-center bg-app-b rounded-lg p-2"
                              key={`reviews${i}`}
                            >
                              <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                                <Image
                                  // resizeMode="contain"
                                  source={
                                    e?.user_profile_image_url &&
                                    e?.user_profile_image_url !== ""
                                      ? {
                                          uri: `${DEV_API}/media/${e?.user_profile_image_url}`,
                                        }
                                      : require("../../../assets/user1.jpg")
                                  }
                                  className="w-10 h-10 rounded-full"
                                ></Image>
                              </View>

                              <View className="flex-1 flex-col rounded-md bg-app-c2">
                                <View className=" w-full flex-row justify-between">
                                  <View className="w-full flex-row px-3 pt-1">
                                    <Font className="py-1 font-bold">
                                      {e?.user_name}
                                    </Font>

                                    {e?.user_role === "vendor" && (
                                      <Font className="ml-2 p-1 bg-app-e rounded-md text-app-a text-xs">
                                        {e?.user_role}
                                      </Font>
                                    )}
                                  </View>
                                </View>
                                <View className="w-full pt-1 px-3">
                                  <Font className="text-xs text-app-d1">
                                    {getFullDateTime(e?.created_at) ?? ""}
                                  </Font>
                                </View>

                                <View className="w-full flex-row py-2 px-3">
                                  {getRatingStars({
                                    rating: e?.rating ?? 5,
                                  })}
                                </View>
                                <View className="w-full px-3 pb-3">
                                  <Font>{e?.message}</Font>
                                </View>
                                <View className="w-full flex-row items-center px-3 py-1">
                                  {/* <View className="w-1/3 flex-row">
                                    <Font className="text-xs pr-2 text-app-d1">
                                      {e.likes ?? 100}
                                    </Font>
                                    <Pressable
                                      onPress={() =>
                                        onLikeAReview({
                                          id: e.id,
                                          type: e.is_liked ? "unlike" : "like",
                                        })
                                      }
                                      className="flex-row items-center"
                                    >
                                      <RemixIcon
                                        name={
                                          e.is_liked
                                            ? "ri-thumb-up-fill"
                                            : "ri-thumb-up-line"
                                        }
                                        size={11}
                                        color={
                                          e.is_liked
                                            ? colors.app.f
                                            : colors.app.d1
                                        }
                                      />
                                    </Pressable>
                                  </View> */}
                                  {/* <View className="w-1/2 flex-row">
                                    <Pressable
                                      onPress={() =>
                                        setShowReviewId((prev) => ({
                                          ...prev,
                                          id:
                                            prev.id === e.id &&
                                            prev.showAddReplyToReview
                                              ? null
                                              : prev.id === e.id &&
                                                prev.showReplies
                                              ? e.id
                                              : e.id,
                                          showAddReplyToReview:
                                            prev.id === e.id &&
                                            prev.showAddReplyToReview
                                              ? false
                                              : true,
                                          showReplies: false,
                                        }))
                                      }
                                    >
                                      <Font className="text-xs pr-2 text-app-d">
                                        Reply
                                      </Font>
                                    </Pressable>
                                  </View> */}
                                  {!e?.replies?.length ? (
                                    <></>
                                  ) : (
                                    <View className="w-1/2 flex-row">
                                      {/* <Font className="text-xs pr-2 text-app-d1">
                                      {e?.replies ?? 100}
                                    </Font> */}
                                      <Pressable
                                        onPress={() =>
                                          setShowReviewId((prev) => ({
                                            ...prev,
                                            id:
                                              prev.id === e.id &&
                                              prev.showReplies
                                                ? null
                                                : prev.id === e.id &&
                                                  prev.showAddReplyToReview
                                                ? e.id
                                                : e.id,
                                            showReplies:
                                              prev.id === e.id &&
                                              prev.showReplies
                                                ? false
                                                : prev.id === e.id &&
                                                  !prev.showReplies
                                                ? true
                                                : true,
                                            showAddReplyToReview: false,
                                          }))
                                        }
                                      >
                                        <Font className="text-xs pr-2 text-app-d">
                                          ({e?.replies?.length}) All replies
                                        </Font>
                                      </Pressable>
                                    </View>
                                  )}
                                </View>
                                <View className="w-full flex-col ">
                                  {console.log(showReviewId, "showReviewId")}
                                  {/* {showReviewId?.id === e?.id &&
                                    showReviewId?.showAddReplyToReview && (
                                      <View className="w-full flex-col py-1 px-3 my-3">
                                        <View className="w-full pb-1">
                                          <Font>Reply to this review </Font>
                                        </View>
                                        <View className="sm:w-full flex-col sm:flex-row justify-between">
                                          <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                            <Input
                                              type="text"
                                              value={replyToReviewInput}
                                              setValue={(ee) => {
                                                setReplyToReviewInput(ee);
                                              }}
                                              placeholder="reply to this comment..."
                                            ></Input>
                                            <Pressable className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md">
                                              <RemixIcon
                                                name="ri-send-plane-2-line"
                                                size={15}
                                                onPress={() => {
                                                  SubmitRepliesHandler(
                                                    showReviewId.id
                                                  );
                                                }}
                                              />
                                            </Pressable>
                                          </View>
                                        </View>
                                      </View>
                                    )} */}
                                  {/* ---------------------- replies to a review ------------------------ */}
                                  {console.log(e?.replies, "replies check")}
                                  {showReviewId?.id === e?.id &&
                                    showReviewId?.showReplies &&
                                    e?.replies?.map((eee, i) => (
                                      <View
                                        className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                        key={`comment_replies${i}`}
                                      >
                                        <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                          <Image
                                            // resizeMode="contain"
                                            source={require("../../../assets/user1.jpg")}
                                            className="w-10 h-10 rounded-full"
                                          ></Image>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row px-3 pt-1">
                                            <Font className="w-full py-1 font-bold">
                                              {eee?.user_name}
                                            </Font>
                                          </View>
                                          <View className="w-full pt-1 px-3">
                                            <Font className="text-xs text-app-d1">
                                              {eee?.created_at ?? "1-2-23"}
                                            </Font>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                              <Font>{eee?.message}</Font>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    ))}
                                </View>
                              </View>
                            </View>
                          ))}
                        </ScrollView>
                      </Card>
                    ) : reviews?.length === 0 && !isReviewsLoading ? (
                      <Card className="flex">
                        <View className="w-full flex-row h-80 justify-center items-center">
                          <Font className="text-app-d1">No reviews found.</Font>
                        </View>
                      </Card>
                    ) : (
                      <Card className="flex">
                        <View className="flex-1 w-full justify-center h-40">
                          <ActivityIndicator
                            size={"small"}
                            color={colors.app.e}
                          ></ActivityIndicator>
                        </View>
                      </Card>
                    )}
                  </View>
                  <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                    <View className="w-4/5">
                      <Widget></Widget>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Footer></Footer>
          </ScrollView>
        </View>
      </View>
      <Modal
        animationType="fade"
        transparent={true}
        onRequestClose={() =>
          setIsEditModal((prev) => ({ ...prev, visible: false }))
        }
        visible={isEditModal.visible}
      >
        <TouchableWithoutFeedback
          onPress={() =>
            setIsEditModal((prev) => ({ ...prev, visible: false }))
          }
        >
          <View
            style={{
              padding: 10,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            className="w-full h-full justify-center items-center bg-black/50"
          >
            <TouchableWithoutFeedback>
              <View
                className="w-full lg:w-[640px]"
                style={{
                  // position: "absolute",
                  // left: buttonPosition.left,
                  // top: buttonPosition.top,
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 10,

                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  elevation: 5,
                }}
              >
                <Card title="Update review" className="mt-1">
                  <View className="w-full flex-col sm:flex-row justify-between items-center p-2 ">
                    <View className="w-full">
                      <View className="w-full flex-row items-center justify-start py-3">
                        {[...Array(5).keys()].map((e, i) => (
                          <Pressable
                            onPress={() =>
                              setIsEditModal((prev) => ({
                                ...prev,
                                rating: i + 1,
                              }))
                            }
                            key={`starts_${i}`}
                          >
                            <RemixIcon
                              name={
                                isEditModal.rating === 0
                                  ? "ri-star-line"
                                  : i + 1 <= isEditModal.rating
                                  ? "ri-star-fill"
                                  : "ri-star-line"
                              }
                              size={20}
                              color={colors.app.i}
                              className="flex-shrink-0"
                              key={`icon_${i}`}
                            ></RemixIcon>
                          </Pressable>
                        ))}
                      </View>
                      <View className="flex-row items-start justify-center pb-3">
                        <Input
                          type="longtext"
                          value={isEditModal.message}
                          setValue={(e) => {
                            setIsEditModal((ee) => ({
                              ...ee,
                              message: e,
                            }));
                          }}
                          // label="Email"
                          placeholder="Review"
                          // validation={true}
                          // isValid={(e) => setIsInputValid({ e, type: "email" })}
                        ></Input>
                      </View>
                      <View className="w-full flex-row items-center justify-center">
                        <Button
                          type="primary"
                          label="Update review"
                          onPress={() =>
                            updateReviewHandler(
                              isEditModal.rating,
                              isEditModal.message,
                              isEditModal.id
                            )
                          }
                        />
                      </View>
                    </View>
                  </View>
                </Card>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <ConfirmModal
        visible={isDeleteModal.visible}
        onCancel={() => setIsDeleteModal((e) => ({ ...e, visible: false }))}
        onConfirm={() => {
          setIsDeleteModal((e) => ({ ...e, visible: false }));
          // closeDeleteModal();

          onDeleteReview(isDeleteModal.id);
        }}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete Review ?"}
        BodyComponent={false}
      ></ConfirmModal>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
}
