import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  Pressable,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { Font } from "../../components/font";
import { Card } from "../../components/card";
import { Button } from "../../components/input";
import colors from "../../../colors";
import { Maps } from "../../components/maps";
import { Footer } from "../../components/footer";
import { Gallery } from "../../components/gallery";
import { BusinessesContext } from "../../context/businessesContext";
import {
  axiosGet,
  deleteBookmark,
  DEV_API,
  getBookmark,
  getListingById,
  postBookmark,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { ActivityIndicator } from "react-native";
import WebView from "react-native-webview";
import Toast from "react-native-toast-message";
import { MessagesContext } from "../../context/messagesContext";
import VideoPlayer from "../../components/VideoPlayer";

export function ListingDetails({ navigation, route }) {
  const { user, userToken } = useContext(AuthContext);
  const { setIsUserLoading, isUserLoading } = useContext(UserContext);
  const {
    businessDetails,
    isBusinessLoading,
    setIsBusinessLoading,
    setBusinessDetails,
    setBusinessApiError,
  } = useContext(BusinessesContext);
  const {
    channels,
    currentChannelMessages,
    currentChatData,
    setChannels,
    setCurrentChannelMessages,
    setUpdateChannelUnreadCount,
    resetCurrentChatData,
    setCurrentChatData,
    dispatchMessageEvents,
  } = React.useContext(MessagesContext);
  // const [unitCount, setUnitCount] = useState(0);
  const [bookmark, setBookmark] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const calculateOpeningHours = ({ startTime, closeTime }) => {
    const startHr = Math.floor(startTime / 60);
    const startMin = Math.floor(startTime % 60);
    const closeHr = Math.floor(closeTime / 60);
    const closeMin = Math.floor(closeTime % 60);

    const opensAt =
      (startHr <= 12 ? startHr : startHr - 12) +
      ":" +
      (startMin < 10 ? `0${startMin}` : startMin) +
      " " +
      (startHr < 12 ? "AM" : "PM");
    const closesAt =
      (closeHr <= 12 ? closeHr : closeHr - 12) +
      ":" +
      (closeMin < 10 ? `0${closeMin}` : closeMin) +
      " " +
      (closeHr < 12 ? "AM" : "PM");
    return opensAt + " - " + closesAt;
  };
  useEffect(() => {
    const getData = async () => {
      try {
        setIsBusinessLoading(true);
        setBusinessApiError(null);
        const res = await getListingById({ id: route.params.id });
        if (res.status === 200) {
          const reviewsArray = [
            ...res.data.data?.reviews.map((e, i) => ({
              ...e,
              isGoogleReview: false,
            })),
          ];
          const googleReviewsArray = [
            ...res.data.data?.google_reviews.map((e, i) => ({
              ...e,
              isGoogleReview: true,
            })),
          ];
          setBusinessDetails({
            ...res.data.data,
            reviews: reviewsArray,
            googleReviews: googleReviewsArray,
            totalReview: reviewsArray.length ?? 0,
            totalGoogleReview: googleReviewsArray.length ?? 0,
            total_reviews:
              res.data.data?.total_reviews +
                res.data.data?.google_reviews.length ?? 0,
          });

          // await setBusinessDetails(res.data.data);
        } else {
          if (res.status === 502) {
            showToast(
              "error",
              "Listing error",
              "Something went wrong on our side."
            );
          }
          console.log("listing-details, getlistingbyid res error : ", res.data);
          setBusinessApiError(res.data);
        }
      } catch (error) {
        console.log("listing-details, getlistingbyid error : ", error);
      } finally {
        setIsBusinessLoading(false);
      }
    };
    getData();
  }, []);

  const getWishlist = async (token) => {
    setIsUserLoading(true);
    try {
      const res = await getBookmark({ token });
      if (res.status === 200) {
        const filterArray = res.data.data.filter(
          (e) => e.listing.id === businessDetails.id
        );
        if (filterArray.length === 1) {
          const wishlistArray = filterArray.map((e) => {
            return { id: e.id, listingId: e.listing.id };
          });
          setBookmark(wishlistArray[0]);
        } else {
          setBookmark(null);
        }
      } else {
        if (res.status === 502) {
          showToast(
            "error",
            "Wishlist error",
            "Something went wrong on our side."
          );
        }
        console.log("listing-details, getbookmar res error: ", res.data);
      }
    } catch (error) {
      console.log("listing-details, getbookmar error : ", error);
    } finally {
      setIsUserLoading(false);
    }
  };

  useEffect(() => {
    if (user && userToken !== null && businessDetails !== null) {
      getWishlist(userToken);
    }
  }, [user, userToken, businessDetails]);

  const getRatingStars = ({ rating }) => {
    const rating1 = [...Array(Math.floor(rating)).keys()];
    const ishalfStar = rating1 % 1 === 0.5;
    return (
      <>
        {rating1.map((e, i) => (
          <RemixIcon
            name="star-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
            key={`icon_${i}`}
          ></RemixIcon>
        ))}
        {ishalfStar && (
          <RemixIcon
            name="star-half-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
          ></RemixIcon>
        )}
      </>
    );
  };
  const onBookmark = async () => {
    try {
      if (bookmark) {
        const res = await deleteBookmark({
          bookmark_id: bookmark.id,
          token: userToken,
        });
        if (res.status === 204) {
          showToast("success", "Success", "Wishlist removed.");
          setBookmark(null);
        } else {
          console.log("listing-details, onBookmark delete------- : ", res.data);
        }
      } else {
        const res = await postBookmark({
          id: businessDetails.id,
          token: userToken,
        });
        if (res.status === 201) {
          showToast("success", "Success", "Wishlist added.");
          setBookmark({ id: res.data.data.id, listingId: businessDetails.id });
        } else {
          if (res.status === 502) {
            showToast(
              "error",
              "Bookmark error",
              "Something went wrong on our side."
            );
          }
          console.log("listing-details, onBookmark post------- : ", res.data);
        }
      }
    } catch (error) {
      console.log("listing-details, onBookmark post error------- : ", error);
    }
  };
  const getMessages = async (channel_id) => {
    await axiosGet({
      Url: `/chat/channels/${channel_id}/messages`,
      token: userToken,
      param: {
        // from: 0,
        // to: 2,
        limit: 200,
      },
    })
      .then((data) => {
        setCurrentChannelMessages({ data: [...data.data.data].reverse() });
        setUpdateChannelUnreadCount({ id: channel_id });
        navigation.navigate("messages", { contact_vendor: true });
      })
      .catch((err) => {
        showToast("error", "Messages error", err);

        console.log("messages, getChatList error: ", err);
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  };
  const getChatList = async ({ vendor_id }) => {
    await axiosGet({
      Url: "/chat/channels",
      token: userToken,
    })
      .then((data) => {
        const updatedList = data.data.data.map((e, i) => {
          return {
            ...e,
            unread: 0,
          };
        });
        let channelIndex;
        if (updatedList?.length > 0) {
          channelIndex = updatedList.findIndex(
            (e) => e.vendor.id === vendor_id
          );
          if (channelIndex > -1) {
            if (channelIndex === 0) {
              setCurrentChatData({
                data: updatedList[channelIndex],
                currIndex: channelIndex,
              });
              setChannels({ data: updatedList });
              getMessages(updatedList[channelIndex].id);
            } else {
              let updateCurrChannelList = [...updatedList];
              updateCurrChannelList.unshift(
                ...updatedList.splice(channelIndex, 1)
              );
              setChannels({ data: updateCurrChannelList });
              setCurrentChatData({
                data: updatedList[channelIndex],
                currIndex: 0,
              });
              getMessages(updatedList[channelIndex].id);
            }
          }
        }
      })
      .catch((err) => {
        showToast("error", "Chatlist error", err);
        console.log("messages, getChatList error: ", err);
      })
      .finally(() => {
        // setIsUserLoading(false);
      });
  };
  const onContactVendor = async ({ vendor_id }) => {
    let channelExists = false;
    let channelIndex;
    if (channels && channels?.length > 0) {
      channelIndex = await channels.findIndex((e) => e.vendor_id === vendor_id);
      if (channelIndex > -1) {
        channelExists = true;
      }
    }
    if (channels?.length > 0 && !!channelExists) {
      setCurrentChatData({
        data: channels[channelIndex],
        currIndex: channelIndex,
      });
      getMessages(channels[channelIndex]?.id);
    } else if (channels?.length > 0 && !channelExists) {
      dispatchMessageEvents.joinChannel({
        vendor_id: vendor_id,
        cb: (res) => {
          let updateCurrChannelList = [...channels];
          updateCurrChannelList.unshift(res.data.channel);
          setChannels({ data: updateCurrChannelList });
          setCurrentChatData({ data: res.data.channel, currIndex: 0 });
          getMessages(res.data.channel.id);
        },
      });
    } else if (channels?.length === 0 && !channelExists) {
      dispatchMessageEvents.joinChannel({
        vendor_id: vendor_id,
        cb: (res) => {
          getChatList({ vendor_id: vendor_id });
        },
      });
    }
  };

  return (
    <View className="flex-1 bg-app-e">
      <View className="flex-1 rounded-t-2xl lg:rounded-none overflow-hidden bg-app-b">
        <ScrollView
          className="flex-1 h-4"
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: "center",
          }}
          overScrollMode="never"
        >
          <View className="flex-1 h-full w-full p-4 items-center">
            {businessDetails && !isBusinessLoading && !isUserLoading ? (
              <View className="flex-col lg:flex-row max-w-screen-xl w-full ">
                {/* ------------------ left col view ------------------- */}
                <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                  {/* --------------------- gallery --------------------- */}
                  <View className="h-96 mb-4 lg:mb-8">
                    <Gallery className="h-full"></Gallery>
                  </View>
                  {/* ---------------- save listing lg:hidden  --------------- */}
                  {/* {user && (
                      <Pressable
                        className="w-full p-4 border  flex-row rounded-lg items-center mt-4 flex lg:hidden bg-app-a active:bg-app-c1 border-gray-200"
                        onPress={onBookmark}
                      >
                        {!isUserLoading && (
                          <>
                            <RemixIcon
                              name={
                                bookmark ? "bookmark-fill" : "bookmark-line"
                              }
                              color={bookmark ? colors.app.e : colors.app.d1}
                              size={24}
                              className="mr-2"
                            ></RemixIcon>

                            <Font className="text-md text-app-d ">
                              {bookmark
                                ? "Listing is saved!"
                                : "Save this listing!"}
                            </Font>
                          </>
                        )}
                      </Pressable>
                    )} */}
                  {/* <View className="h-8"></View> */}
                  {/* -------------------- details -------------------- */}
                  <Card title="Details">
                    <View className="flex-1 w-full p-4 pt-0">
                      <Font
                        className="text-xl lg:text-2xl text-app-d  leading-5 mb-2"
                        numberOfLines={2}
                      >
                        {businessDetails?.title}
                      </Font>
                      <View className="flex-row justify-between items-center mb-2 pr-3">
                        <View className="flex-row">
                          <View className="mr-3 pr-3 border-r-2 border-gray-400 items-center py-1">
                            <Font
                              className="text-md text-app-d1 leading-4"
                              numberOfLines={2}
                            >
                              {businessDetails?.listing_category?.title === null
                                ? "Listing category"
                                : businessDetails?.listing_category?.title}
                            </Font>
                          </View>
                          <View className="flex-row items-center w-24 p-1">
                            <RemixIcon
                              name="checkbox-circle-fill"
                              color={colors.app.g}
                              size={20}
                              className="mr-1"
                            ></RemixIcon>
                            <Font className="text-app-d1 text-md">
                              Verified!
                            </Font>
                          </View>
                        </View>
                        <View className="flex-col items-end relative">
                          <TouchableOpacity
                            onPress={user ? () => onBookmark() : () => {}}
                          >
                            <Ionicons
                              name={
                                bookmark
                                  ? "ios-bookmark"
                                  : "ios-bookmark-outline"
                              }
                              size={22}
                              color={user ? colors.app.e : colors.app.d1}
                              // onPress={user ? () => onBookmark() : () => {}}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                        className="flex-row items-center mb-2"
                        style={{ marginVertical: 4 }}
                      >
                        <RemixIcon
                          name="star-fill"
                          size={16}
                          color={colors.app.i}
                          className="mr-1 flex-shrink-0"
                        ></RemixIcon>
                        <Font className="text-md text-app-d1" numberOfLines={1}>
                          {" "}
                          {businessDetails?.average_rating} |{" "}
                          {businessDetails?.total_reviews} reviews
                        </Font>
                      </View>
                      {businessDetails?.city !== null &&
                        businessDetails?.state !== null &&
                        businessDetails?.country !== null && (
                          <View
                            className="flex-row items-center"
                            style={{ marginVertical: 2 }}
                          >
                            <RemixIcon
                              name="map-pin-fill"
                              color={colors.app.d1}
                              size={20}
                              className="mr-1"
                            ></RemixIcon>
                            <Font
                              className="text-sm text-app-d1 leading-4"
                              numberOfLines={2}
                            >
                              {businessDetails?.city}
                              {`, ${businessDetails?.state}`}
                              {`, ${businessDetails?.country}`}
                            </Font>
                          </View>
                        )}
                      {businessDetails?.description !== null && (
                        <View className="mt-4">
                          <Font className="text-app-d leading-5">
                            {businessDetails?.description}
                          </Font>
                        </View>
                      )}
                    </View>
                  </Card>
                  {/* ------------------ opening hours lg:hidden ------------------ */}
                  <Card title="Opening Hours" className="lg:hidden">
                    <View className="flex-1 w-full pb-4">
                      {Object.keys(businessDetails?.opening_hours || {}).map(
                        (e, i) => (
                          <View
                            className="flex-row w-full px-4 py-2"
                            key={`openingHours_${i}`}
                          >
                            <Font className="flex-1 text-app-d">{e}</Font>

                            <View className="items-end">
                              {businessDetails?.opening_hours[e].map((e, i) => (
                                <Font
                                  className="text-app-d mb-2"
                                  key={`openingHoursTime_${i}`}
                                >
                                  {calculateOpeningHours({
                                    startTime: e.start_in_min,
                                    closeTime: e.end_in_min,
                                  })}
                                </Font>
                              ))}
                            </View>
                          </View>
                        )
                      )}
                    </View>
                  </Card>
                  {/* -------------------------- features ----------------------- */}
                  {businessDetails?.listing_features?.length > 0 && (
                    <Card title="Features" className="lg:hidden">
                      <View className="w-full p-4 pt-0 flex-row flex-wrap">
                        {businessDetails?.listing_features?.map((e, i) => (
                          <View
                            className="w-1/2 flex-row items-center h-10"
                            key={`listing_features${e.id}`}
                          >
                            <Ionicons
                              name="list-circle-outline"
                              size={20}
                              color={colors.app.d1}
                            />
                            <View className="w-3/4 ml-2">
                              <Font className="w-full break-normal">
                                {e.title}
                              </Font>
                            </View>
                          </View>
                        ))}
                      </View>
                    </Card>
                  )}
                  {/* ------------------------- services ----------------------- */}

                  <Card title="Services">
                    {businessDetails?.bookable_services ? (
                      Object.keys(businessDetails?.bookable_services || {}).map(
                        (e, i) => (
                          <View
                            className="flex-row flex-wrap p-4 justify-between pt-0 w-full"
                            key={`service_category_${i}`}
                          >
                            {e !== "uncategorized" && (
                              <View className="w-full bg-gray-100  rounded mb-4">
                                <View className="min-h-12 items-center flex-row w-full py-4">
                                  <View className="h-1/2 absolute w-1 bg-app-e rounded-r"></View>
                                  <Font
                                    className="px-4 w-full leading-4  text-[15px]  flex-1"
                                    numberOfLines={2}
                                  >
                                    {e}
                                  </Font>
                                </View>
                              </View>
                            )}
                            {businessDetails?.bookable_services[e]?.map(
                              (e, i) => (
                                <Pressable
                                  className="w-full lg:w-[48.5%] rounded-lg mb-4 border border-gray-200"
                                  key={`bookable_services_${e.id}`}
                                  onPress={() => {
                                    setSelectedServiceId(e.id);
                                  }}
                                >
                                  <View className="flex-1 w-full">
                                    <View className="items-center flex-row justify-between w-full p-2 ">
                                      <Font
                                        className="leading-4"
                                        numberOfLines={2}
                                      >
                                        {e.title}
                                      </Font>
                                    </View>

                                    <View className="items-center flex-row px-2 mb-2">
                                      <Font className="w-full ">
                                        $ {Number(e.service_unit.price) / 100} /{" "}
                                        {e.service_unit.name}
                                      </Font>
                                    </View>

                                    {e.subtitle && (
                                      <View className="items-center flex-row w-full mb-2">
                                        <Font className="px-2 w-full text-app-d1">
                                          {e.subtitle}
                                        </Font>
                                      </View>
                                    )}
                                  </View>
                                  <View className="w-full">
                                    <View className="w-full items-center p-3">
                                      <View className="w-3/4">
                                        <Button
                                          type={`${
                                            user ? "primary" : "disabled"
                                          }`}
                                          label="Book now"
                                          height="h-7"
                                          onPress={
                                            user
                                              ? () =>
                                                  navigation.navigate(
                                                    "booking",
                                                    {
                                                      bookableServiceId: e.id,
                                                      listingId:
                                                        businessDetails.id,
                                                    }
                                                  )
                                              : () => {}
                                          }
                                        ></Button>
                                      </View>
                                    </View>
                                    {(!user || user === null) && (
                                      <View className="w-full mb-4 text-center">
                                        <Font className="px-2 text-app-d1">
                                          Please login to book a service.
                                        </Font>
                                      </View>
                                    )}
                                  </View>
                                </Pressable>
                              )
                            )}
                          </View>
                        )
                      )
                    ) : (
                      <View className="flex-1 w-full justify-center items-center">
                        <Font className="text-app-d1">
                          Currently no services available for booking.
                        </Font>
                      </View>
                    )}
                  </Card>
                  {/* ---------------------------- video ---------------------- */}
                  {businessDetails.video_link && (
                    <Card title="Video">
                      {businessDetails.video_link ? (
                        <View className="max-w-full max-h-full w-[327px] h-[184px] lg:w-[480px] lg:h-[270px] flex flex-col justify-center items-stretch">
                          <VideoPlayer videoURI={businessDetails.video_link} />
                        </View>
                      ) : (
                        <View className="mb-4 justify-center items-center">
                          <Font className="p-2 text-app-d2 text-center">
                            No Media URL
                          </Font>
                        </View>
                      )}
                    </Card>
                  )}
                  {/* ------------------------- location -------------------- */}
                  <Card title="Location">
                    <View className="flex-1 w-full h-96 px-4">
                      <Maps
                        className="rounded-md h-96"
                        lat={businessDetails.lat}
                        lang={businessDetails.lng}
                        disabled={true}
                        listingDetails={[
                          {
                            url: null,
                            image: `${DEV_API}/media/${businessDetails.cover_image_url}`,
                            title: businessDetails.title,
                            location: `${businessDetails.address_line_1}, ${businessDetails.city}, ${businessDetails.country}`,
                            latitude: businessDetails.lat,
                            longitude: businessDetails.lng,
                            rating: businessDetails.average_rating,
                            priceMin: businessDetails.min_price / 100,
                            priceMax: businessDetails.max_price / 100,
                          },
                        ]}
                      ></Maps>
                    </View>
                  </Card>
                  {/* ---------------------- review lg: flex ---------------------- */}
                  {
                    // businessDetails?.total_reviews > 0 &&
                    <Card
                      title={`Reviews (${businessDetails?.totalReview})`}
                      className="hidden lg:flex"
                      Widget={() => (
                        <Pressable
                          className="px-4 py-1 bg-app-c rounded-md"
                          onPress={() =>
                            navigation.navigate({
                              name: "review",
                              id: route.params.id,
                              params: {
                                // slug: businessDetails?.id,
                                slug: route.params.id,
                                type: "listing",
                              },
                            })
                          }
                        >
                          <Font className="text-xs ">
                            {businessDetails?.totalReview?.length == 0
                              ? "Add Review"
                              : "See all"}
                          </Font>
                        </Pressable>
                      )}
                    >
                      <ScrollView className="flex-1 w-full p-4 pt-0">
                        {businessDetails?.reviews.map((e, i) => (
                          <View
                            className="w-full py-4 flex-col lg:flex-row border-b border-b-gray-200"
                            key={`reviews_${i}`}
                          >
                            <View className="w-full lg:w-44 mb-4 mr-4">
                              <View className="items-center justify-center flex-row ">
                                <View className="w-10 h-10 rounded-full overflow-hidden">
                                  <Image
                                    source={
                                      e.user_profile_image_url
                                        ? {
                                            uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                          }
                                        : require("../../../assets/user1.jpg")
                                    }
                                    className="flex-1"
                                  ></Image>
                                </View>
                                <View className="flex-1 ml-2">
                                  <Font
                                    className="text-app-d"
                                    numberOfLines={1}
                                  >
                                    {e.user_name}
                                  </Font>
                                  <Font className="text-xs text-app-d1">
                                    {new Date(e.created_at).toDateString()}
                                  </Font>
                                </View>
                              </View>
                            </View>
                            <View className="flex-1">
                              <View className="items-center flex-row w-full pt-1 pb-2 ">
                                {getRatingStars({ rating: e.rating })}
                              </View>
                              <View>
                                <Font className="w-full text-app-d">
                                  {e.message}
                                </Font>
                              </View>
                              {e.replies?.length > 0 &&
                                e.replies.map((ee, i) => (
                                  <View
                                    className="p-4 bg-gray-100 mt-4 rounded-lg"
                                    key={`reviews_reply_${i}`}
                                  >
                                    <View className="items-center justify-center flex-row mb-2">
                                      <View className="w-8 h-8 rounded-full overflow-hidden">
                                        <Image
                                          source={require("../../../assets/bg.jpg")}
                                          className="flex-1 object-contain"
                                        ></Image>
                                      </View>
                                      <View className="flex-1 ml-2">
                                        <View className="flex-row items-center">
                                          <Font
                                            numberOfLines={1}
                                            className="text-app-d"
                                          >
                                            {ee.user_name}
                                          </Font>
                                          <View className="px-1 bg-app-g rounded ml-2">
                                            <Font className="text-app-a text-xs">
                                              {ee.user_role}
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="text-xs text-app-d1">
                                          {new Date(
                                            ee.created_at
                                          ).toDateString()}
                                        </Font>
                                      </View>
                                    </View>
                                    <Font className="w-full text-app-d">
                                      {ee.message}
                                    </Font>
                                  </View>
                                ))}
                            </View>
                          </View>
                        ))}
                      </ScrollView>
                      {/* {businessDetails?.reviews?.length > 5 && (
                          <View className="w-full p-4 pt-0 flex-row justify-end">
                            <View className="ml-auto flex-row">
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&lt;</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                                <Font className="text-app-g">1</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">2</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">3</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&gt;</Font>
                              </Pressable>
                            </View>
                          </View>
                        )} */}
                    </Card>
                  }
                  {
                    // businessDetails?.total_reviews > 0 &&
                    <Card
                      title={`Reviews from Google (${businessDetails?.totalGoogleReview})`}
                      className="hidden lg:flex"
                    >
                      <ScrollView className="flex-1 w-full p-4 pt-0">
                        {businessDetails?.googleReviews.map((e, i) => (
                          <View
                            className="w-full py-4 flex-col lg:flex-row border-b border-b-gray-200"
                            key={`reviews_${i}`}
                          >
                            <View className="w-full lg:w-44 mb-4 mr-4">
                              <View className="items-center justify-center flex-row ">
                                <View className="w-10 h-10 rounded-full overflow-hidden">
                                  <Image
                                    source={
                                      e.user_profile_image_url
                                        ? {
                                            uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                          }
                                        : require("../../../assets/user1.jpg")
                                    }
                                    className="flex-1"
                                  ></Image>
                                </View>
                                <View className="flex-1 ml-2">
                                  <Font
                                    className="text-app-d"
                                    numberOfLines={1}
                                  >
                                    {e.user_name}
                                  </Font>
                                  <Font className="text-xs text-app-d1">
                                    {new Date(e.created_at).toDateString()}
                                  </Font>
                                </View>
                              </View>
                              <View className="flex-row mt-2">
                                <Font
                                  className="text-app-d bg-app-c1 px-2 py-1 text-xs"
                                  numberOfLines={1}
                                >
                                  Google review
                                </Font>
                              </View>
                            </View>
                            <View className="flex-1">
                              <View className="items-center flex-row w-full pt-1 pb-2 ">
                                {getRatingStars({ rating: e.rating })}
                              </View>
                              <View>
                                <Font className="w-full text-app-d">
                                  {e.message}
                                </Font>
                              </View>
                              {e.replies?.length > 0 &&
                                e.replies.map((ee, i) => (
                                  <View
                                    className="p-4 bg-gray-100 mt-4 rounded-lg"
                                    key={`reviews_reply_${i}`}
                                  >
                                    <View className="items-center justify-center flex-row mb-2">
                                      <View className="w-8 h-8 rounded-full overflow-hidden">
                                        <Image
                                          source={require("../../../assets/bg.jpg")}
                                          className="flex-1 object-contain"
                                        ></Image>
                                      </View>
                                      <View className="flex-1 ml-2">
                                        <View className="flex-row items-center">
                                          <Font
                                            numberOfLines={1}
                                            className="text-app-d"
                                          >
                                            {ee.user_name}
                                          </Font>
                                          <View className="px-1 bg-app-g rounded ml-2">
                                            <Font className="text-app-a text-xs">
                                              {ee.user_role}
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="text-xs text-app-d1">
                                          {new Date(
                                            ee.created_at
                                          ).toDateString()}
                                        </Font>
                                      </View>
                                    </View>
                                    <Font className="w-full text-app-d">
                                      {ee.message}
                                    </Font>
                                  </View>
                                ))}
                            </View>
                          </View>
                        ))}
                      </ScrollView>
                      {/* {businessDetails?.reviews?.length > 5 && (
                          <View className="w-full p-4 pt-0 flex-row justify-end">
                            <View className="ml-auto flex-row">
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&lt;</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                                <Font className="text-app-g">1</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">2</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">3</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&gt;</Font>
                              </Pressable>
                            </View>
                          </View>
                        )} */}
                    </Card>
                  }
                </View>
                {/* ------------------------------ right col view ------------------------------ */}
                <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
                  {/* ------------------ save listing lg:flex ---------------------- */}
                  {/* <Pressable
                      className="w-full p-4 bg-app-a border border-gray-200 flex-row rounded-lg items-center mt-8 hidden lg:flex"
                      onPress={onBookmark}
                    >
                      <RemixIcon
                        name={bookmark ? "bookmark-fill" : "bookmark-line"}
                        color={bookmark ? colors.app.e : colors.app.d1}
                        size={24}
                        className="mr-2"
                      ></RemixIcon>

                      <Font className="text-md text-app-d ">
                        {bookmark ? "Listing is saved!" : "Save this listing!"}
                      </Font>
                    </Pressable> */}
                  {/* <View className="h-8 hidden lg:flex"></View> */}
                  {/* ------------------ opening hours lg:flex ----------------- */}
                  <Card title="Opening Hours" className="hidden lg:flex">
                    <View className="flex-1 w-full pb-4">
                      {Object.keys(businessDetails?.opening_hours || {}).map(
                        (e, i) => (
                          <View
                            className="flex-row w-full px-4 py-2"
                            key={`opening_hours_${i}`}
                          >
                            <Font className="flex-1 text-app-d capitalize">
                              {e}
                            </Font>

                            <View className="items-end">
                              {businessDetails?.opening_hours[e].map((e, i) => (
                                <Font
                                  className="text-app-d mb-2"
                                  key={`opening_hours_time_${i}`}
                                >
                                  {calculateOpeningHours({
                                    startTime: e.start_in_min,
                                    closeTime: e.end_in_min,
                                  })}
                                  {/* {e.start_in_min} AM - {e.end_in_min} PM */}
                                </Font>
                              ))}
                            </View>
                          </View>
                        )
                      )}
                    </View>
                  </Card>
                  {/* -------------------- features lg:flex----------------------- */}
                  {businessDetails?.listing_features?.length > 0 && (
                    <Card title="Features" className="hidden lg:flex">
                      <View className="w-full p-4 pt-0 flex-row flex-wrap">
                        {businessDetails?.listing_features?.map((e, i) => (
                          <View
                            className="w-full lg:w-1/2 flex-row items-center h-10"
                            key={`features_lg_${i}`}
                          >
                            <RemixIcon
                              name="checkbox-fill"
                              color={colors.app.e}
                              size={20}
                              className="mr-2"
                            ></RemixIcon>
                            <Font>{e.title}</Font>
                          </View>
                        ))}
                      </View>
                    </Card>
                  )}
                  {/* ------------------- review lg: hidden -------------------- */}
                  {
                    // businessDetails?.total_reviews > 0 &&
                    <Card
                      title={`Reviews (${businessDetails?.totalReview})`}
                      className="lg:hidden"
                      Widget={() => (
                        <TouchableOpacity
                          className="px-4 py-1 bg-app-c rounded-md"
                          onPress={() =>
                            navigation.navigate({
                              name: "review",
                              params: {
                                slug: businessDetails?.id,
                                type: "listing",
                              },
                            })
                          }
                        >
                          <Font className="text-xs">
                            {businessDetails?.totalReview?.length == 0
                              ? "Add Review"
                              : "See all"}
                          </Font>
                        </TouchableOpacity>
                      )}
                    >
                      <View className="w-full px-3">
                        <ScrollView horizontal>
                          <View className="gap-4 flex-row">
                            {businessDetails?.reviews.map((e, i) => (
                              <View
                                className="w-72 p-3 pl-0 flex-col lg:flex-row border border-gray-200 rounded-lg "
                                key={`review_${i}`}
                              >
                                <View className="flex-1 mb-4">
                                  <View className="flex-row items-center">
                                    <View className="bg-app-e w-1 h-5 rounded-sm"></View>
                                    <View className="items-center flex-1 flex-row pt-1 pb-2 ml-3">
                                      {getRatingStars({ rating: e.rating })}
                                    </View>
                                  </View>
                                  <View className="ml-4">
                                    <Font
                                      className="w-full text-app-d"
                                      numberOfLines={2}
                                    >
                                      {e.message}
                                    </Font>
                                  </View>
                                  {/* {e.is_reply?.length > 0 && (
                                      <View className="p-4 bg-gray-100 mt-4 rounded-lg">
                                        <View className="items-center justify-center flex-row mb-2">
                                          <View className="w-8 h-8 rounded-full overflow-hidden">
                                            <Image
                                              source={require("../../../assets/user1.jpg")}
                                              className="flex-1"
                                            ></Image>
                                          </View>
                                          <View className="flex-1 ml-2">
                                            <View className="flex-row items-center">
                                              <Font
                                                numberOfLines={1}
                                                className="text-app-d"
                                              >
                                                John Doe
                                              </Font>
                                              <View className="px-1 bg-app-g rounded ml-2">
                                                <Font className="text-app-a text-xs">
                                                  Vendor
                                                </Font>
                                              </View>
                                            </View>
                                            <Font className="text-xs text-app-d1">
                                              2 days ago
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="w-full text-app-d">
                                          Thank you very much for using our
                                          service, don't forget to share our
                                          link
                                          <Font className="text-app-f">
                                            {" "}
                                            https://this.link.com{" "}
                                          </Font>
                                          to your friends
                                        </Font>
                                      </View>
                                    )} */}
                                </View>
                                <View className="w-full lg:w-44 mb-2 mr-4 items-center justify-center flex-row ">
                                  <View className="w-10 h-10 rounded-full overflow-hidden ml-3">
                                    <Image
                                      source={require("../../../assets/user1.jpg")}
                                      className="w-full h-full object-contain"
                                    ></Image>
                                  </View>
                                  <View className="flex-1 ml-2">
                                    <Font
                                      className="text-app-d"
                                      numberOfLines={1}
                                    >
                                      {e.user_name === null
                                        ? "placeholder name"
                                        : e.user_name}
                                    </Font>
                                    <Font className="text-xs text-app-d1">
                                      {new Date(e.created_at).toDateString()}
                                    </Font>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                          {businessDetails?.total_reviews > 5 && (
                            <View className="flex-1 items-center justify-center pl-2">
                              <TouchableOpacity
                                className="flex-row bg-app-c rounded-full p-2"
                                onPress={() =>
                                  navigation.navigate({
                                    name: "review",
                                    params: {
                                      slug: businessDetails?.id,
                                      type: "listing",
                                    },
                                  })
                                }
                              >
                                <MaterialIcons
                                  name="keyboard-arrow-right"
                                  size={24}
                                  color={colors.app.d}
                                />
                              </TouchableOpacity>
                            </View>
                          )}
                        </ScrollView>
                      </View>

                      {/* {businessDetails?.reviews?.length > 5 && (
                          <View className="w-full p-4 pt-0 flex-row justify-end">
                            <View className="ml-auto flex-row">
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&lt;</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                                <Font className="text-app-g">1</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">2</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">3</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&gt;</Font>
                              </Pressable>
                            </View>
                          </View>
                        )} */}
                    </Card>
                  }
                  {
                    // businessDetails?.total_reviews > 0 &&
                    <Card
                      title={`Reviews from Google (${businessDetails?.totalGoogleReview})`}
                      className="lg:hidden"
                    >
                      <View className="w-full px-3">
                        <ScrollView horizontal>
                          <View className="gap-4 flex-row">
                            {businessDetails?.googleReviews.map((e, i) => (
                              <View
                                className="w-72 p-3 pl-0 flex-col lg:flex-row border border-gray-200 rounded-lg "
                                key={`review_${i}`}
                              >
                                <View className="flex-1 mb-4">
                                  <View className="flex-row items-center">
                                    <View className="bg-app-e w-1 h-5 rounded-sm"></View>
                                    <View className="items-center flex-1 flex-row pt-1 pb-2 ml-3">
                                      {getRatingStars({ rating: e.rating })}
                                    </View>

                                    <View className="flex-row">
                                      <Font
                                        className="text-app-d bg-app-c1 px-2 py-1 text-xs"
                                        numberOfLines={1}
                                      >
                                        Google review
                                      </Font>
                                    </View>
                                  </View>
                                  <View className="ml-4">
                                    <Font
                                      className="w-full text-app-d"
                                      numberOfLines={2}
                                    >
                                      {e.message}
                                    </Font>
                                  </View>
                                  {/* {e.is_reply?.length > 0 && (
                                      <View className="p-4 bg-gray-100 mt-4 rounded-lg">
                                        <View className="items-center justify-center flex-row mb-2">
                                          <View className="w-8 h-8 rounded-full overflow-hidden">
                                            <Image
                                              source={require("../../../assets/user1.jpg")}
                                              className="flex-1"
                                            ></Image>
                                          </View>
                                          <View className="flex-1 ml-2">
                                            <View className="flex-row items-center">
                                              <Font
                                                numberOfLines={1}
                                                className="text-app-d"
                                              >
                                                John Doe
                                              </Font>
                                              <View className="px-1 bg-app-g rounded ml-2">
                                                <Font className="text-app-a text-xs">
                                                  Vendor
                                                </Font>
                                              </View>
                                            </View>
                                            <Font className="text-xs text-app-d1">
                                              2 days ago
                                            </Font>
                                          </View>
                                        </View>
                                        <Font className="w-full text-app-d">
                                          Thank you very much for using our
                                          service, don't forget to share our
                                          link
                                          <Font className="text-app-f">
                                            {" "}
                                            https://this.link.com{" "}
                                          </Font>
                                          to your friends
                                        </Font>
                                      </View>
                                    )} */}
                                </View>
                                <View className="w-full lg:w-44 mb-2 mr-4 items-center justify-center flex-row ">
                                  <View className="w-10 h-10 rounded-full overflow-hidden ml-3">
                                    <Image
                                      source={require("../../../assets/user1.jpg")}
                                      className="w-full h-full object-contain"
                                    ></Image>
                                  </View>
                                  <View className="flex-1 ml-2">
                                    <Font
                                      className="text-app-d"
                                      numberOfLines={1}
                                    >
                                      {e.user_name === null
                                        ? "placeholder name"
                                        : e.user_name}
                                    </Font>
                                    <Font className="text-xs text-app-d1">
                                      {new Date(e.created_at).toDateString()}
                                    </Font>
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                          {businessDetails?.total_reviews > 5 && (
                            <View className="flex-1 items-center justify-center pl-2">
                              <TouchableOpacity
                                className="flex-row bg-app-c rounded-full p-2"
                                onPress={() =>
                                  navigation.navigate({
                                    name: "review",
                                    params: {
                                      slug: businessDetails?.id,
                                      type: "listing",
                                    },
                                  })
                                }
                              >
                                <MaterialIcons
                                  name="keyboard-arrow-right"
                                  size={24}
                                  color={colors.app.d}
                                />
                              </TouchableOpacity>
                            </View>
                          )}
                        </ScrollView>
                      </View>

                      {/* {businessDetails?.reviews?.length > 5 && (
                          <View className="w-full p-4 pt-0 flex-row justify-end">
                            <View className="ml-auto flex-row">
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&lt;</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2  border-b-2 border-b-app-g">
                                <Font className="text-app-g">1</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">2</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">3</Font>
                              </Pressable>
                              <Pressable className="py-1 px-2 mx-2 ">
                                <Font className="text-app-d">&gt;</Font>
                              </Pressable>
                            </View>
                          </View>
                        )} */}
                    </Card>
                  }
                  {/* -------------------- owner info ----------------- */}
                  <Card
                    title="Vendor"
                    className=""
                    Widget={() => (
                      <TouchableOpacity
                        className="px-4 py-1 bg-app-c rounded-md"
                        onPress={() =>
                          navigation.navigate("vendor", {
                            id: businessDetails?.vendor_profile.id,
                          })
                        }
                      >
                        <Font className="text-xs">View Details</Font>
                      </TouchableOpacity>
                    )}
                  >
                    <View className="flex-1 w-full pt-0">
                      <Pressable
                        className="h-12 items-center justify-center flex-row px-4"
                        onPress={() =>
                          navigation.navigate("vendor", {
                            id: businessDetails?.vendor_profile.id,
                          })
                        }
                      >
                        <View className="w-10 h-10 rounded-full overflow-hidden">
                          <Image
                            source={require("../../../assets/user1.jpg")}
                            className="w-full h-full object-contain"
                          ></Image>
                        </View>
                        <View className="flex-1 ml-2">
                          <Font>{businessDetails?.vendor_profile.email}</Font>
                        </View>
                      </Pressable>
                      <View className="items-center w-full p-4">
                        {businessDetails?.vendor_profile.about !== null && (
                          <View className="w-full flex-row">
                            <Font className="pr-2 text-app-d1 mb-4">
                              about :
                            </Font>
                            <Font className=" text-app-d1 mb-4">
                              {businessDetails?.vendor_profile.about}
                            </Font>
                          </View>
                        )}
                        <Button
                          leftIcon={{
                            name: "message-2-fill",
                            color: colors.app.d,
                          }}
                          label="Contact vendor"
                          onPress={() =>
                            onContactVendor({
                              vendor_id: businessDetails?.vendor_profile.id,
                            })
                          }
                        ></Button>
                      </View>
                    </View>
                  </Card>
                  {/* ------------------ social media info ---------------------- */}
                  {!!(
                    businessDetails?.instagram_link ||
                    businessDetails?.youtube_link ||
                    businessDetails?.facebook_link ||
                    businessDetails?.twitter_link ||
                    businessDetails?.whatsapp_link
                  ) && (
                    <Card title="Share" className="">
                      <View className="flex-row w-full pt-0 pb-3 justify-between items-center px-10">
                        {businessDetails?.instagram_link && (
                          <View>
                            <RemixIcon
                              name="instagram-fill"
                              size={22}
                              color={colors.app.e}
                              className="flex-shrink-0"
                            ></RemixIcon>
                          </View>
                        )}
                        {businessDetails?.youtube_link && (
                          <View>
                            <RemixIcon
                              name="youtube-line"
                              size={22}
                              color={colors.app.e}
                              className="flex-shrink-0"
                            ></RemixIcon>
                          </View>
                        )}
                        {businessDetails?.facebook_link && (
                          <View>
                            <RemixIcon
                              name="facebook-line"
                              size={22}
                              color={colors.app.f}
                              className="flex-shrink-0"
                            ></RemixIcon>
                          </View>
                        )}
                        {businessDetails?.twitter_link && (
                          <View>
                            <RemixIcon
                              name="twitter-line"
                              size={22}
                              color={colors.app.f}
                              className="flex-shrink-0"
                            ></RemixIcon>
                          </View>
                        )}
                        {businessDetails?.whatsapp_link && (
                          <View>
                            <RemixIcon
                              name="whatsapp-line"
                              size={22}
                              color={colors.app.g}
                              className="flex-shrink-0"
                            ></RemixIcon>
                          </View>
                        )}
                      </View>
                    </Card>
                  )}
                </View>
              </View>
            ) : businessDetails === null &&
              !isBusinessLoading &&
              !isUserLoading ? (
              <Card className="flex">
                <View className="w-full flex-row h-80 justify-center items-center">
                  <Font className="text-app-d1">No details found.</Font>
                </View>
              </Card>
            ) : (
              <Card className="w-full flex-1">
                <View className="flex-1 w-full justify-center h-40">
                  <ActivityIndicator
                    size={"small"}
                    color={colors.app.e}
                  ></ActivityIndicator>
                </View>
              </Card>
            )}
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  );
}
