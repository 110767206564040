import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, View } from "react-native";
import {
  deleteProductCartItems,
  DEV_API,
  postClearProductCartItems,
  postProductCartItemsDecrement,
  postProductCartItemsIncrement,
} from "../services/api";
import { Font } from "./font";
import { Input } from "./input";
import { AntDesign } from "@expo/vector-icons";
import colors from "../../colors";
import { AuthContext } from "../context/authContext";
import { ProductsContext } from "../context/productsContext";
import { ActivityIndicator } from "react-native";

export const CartItem = ({
  data,
  input,
  multipleSelection = false,
  showQty,
  showTotalPrice,
  note = false,
}) => {
  const { user, userToken } = useContext(AuthContext);
  const {
    cart,
    isProductsLoading,
    productsApiError,
    setProductsApiError,
    setIsProductsLoading,
    setCart,
    setSelectedCartItems,
    selectedCartItem,
  } = useContext(ProductsContext);

  const [addNote, setAddNote] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [isProductDeleting, setIsProductDeleting] = useState(false);
  const [itemCount, setItemCount] = useState(data?.quantity || 1);
  const onDeleteItem = async () => {
    try {
      setProductsApiError(null);
      setIsProductDeleting(true);
      const res = await deleteProductCartItems({
        item_id: data?.id,
        token: userToken,
      });

      if (res.status === 200) {
        const filterArray = cart.filter((e) => data?.id !== e.id);
        setCart(filterArray);
      } else {
        console.log("shop, onDeleteItem res error : ", res.data);
      }
    } catch (error) {
      console.log("shop, onDeleteItem error 2: ", error);
    } finally {
      setIsProductDeleting(false);
    }
  };
  const onUpdateCartItems = async (count, type) => {
    try {
      setProductsApiError(null);
      setProductLoading(true);
      let res;
      if (count >= 1 && type === "increment") {
        res = await postProductCartItemsIncrement({
          item_id: data?.id,
          token: userToken,
        });
      } else if (count >= 1 && type === "decrement") {
        res = await postProductCartItemsDecrement({
          item_id: data?.id,
          token: userToken,
        });
      }
      if (res.status === 200) {
        setItemCount(res.data.data.quantity);
        // const foundIndex = cart.findIndex((e) => e.id === res.data.data.id);
        // const updatedCart = [...cart];
        // updatedCart[foundIndex] = {
        //   ...updatedCart[foundIndex],
        //   quantity: res.data.data.quantity,
        // };
        // setCart(updatedCart);
        // console.log(updatedCart, "updatedCart");
        // setSelectedCartItems((prevItems) =>
        //   prevItems.map((item) =>
        //     item.id === updatedCart.id ?  updatedCart  : item
        //   )
        // );

        const updatedQuantity = res.data.data.quantity;
        const updatedItemId = res.data.data.id;
  
        // Update `cart` state
        const updatedCart = cart.map((item) =>
          item.id === updatedItemId ? { ...item, quantity: updatedQuantity } : item
        );
        setCart(updatedCart);
  
        // Update `selectedCartItems` state
        setSelectedCartItems((prevItems) =>
          prevItems.map((item) =>
            item.id === updatedItemId ? { ...item, quantity: updatedQuantity } : item
          )
        );
  
        console.log(updatedCart, "updatedCart");
        // setSelectedCartItems(updatedCart);
      } else {
        console.log("shop, onUpdateCartItems res error : ", res.data);
      }
    } catch (error) {
      console.log("shop, onUpdateCartItems error : ", error);
    } finally {
      setProductLoading(false);
    }
  };

  const handleCheckboxChange = (itemData) => {
    setSelectedCartItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (item) => item.id === itemData.id
      );

      if (isSelected) {
        return prevSelectedItems.filter((item) => item.id !== itemData.id);
      } else {
        return [...prevSelectedItems, itemData];
      }
    });
  };

  console.log(selectedCartItem, "selectedCartItem");

  return (
    <View className="flex-row justify-between w-full items-center border-b border-gray-200 ">
      <View className="flex-1 w-full">
        <View className="flex-row py-2 px-4">
          <View className="justify-center flex-1 flex-row">
            <View className="self-center justify-self-center relative">
              {multipleSelection && (
                <View className="absolute top-0 left-0 z-10 bg-gray-50 rounded-md m-1">
                  <Input
                    value={selectedCartItem.some(
                      (item) => item.id === data?.id
                    )}
                    setValue={() => handleCheckboxChange(data)}
                    type="checkbox"
                  />
                </View>
              )}
              <Image
                source={
                  data?.item.cover_image_url
                    ? {
                        uri: `${DEV_API}/media/${data?.item.cover_image_url}`,
                      }
                    : require("../../assets/bip3.jpg")
                }
                className="w-24 h-24 rounded"
              ></Image>
            </View>
            <View className="flex-1 pl-2">
              <Font className="text-app-d" weight={700} numberOfLines={2}>
                {data?.item?.title || "No Title"}
              </Font>
              <View
                className="flex-row items-center my-2"
                style={{
                  marginVertical: 2,
                }}
              >
                <Font className="text-app-d" numberOfLines={1} weight={700}>
                  {" "}
                  $ {data?.item.sale_price / 100 || data?.item.price / 100}{" "}
                </Font>
                <Font
                  className="text-app-e1 line-through ml-2"
                  numberOfLines={1}
                  weight={500}
                >
                  {" "}
                  $ {data?.item.price / 100 || data?.item.sale_price / 100}
                </Font>
              </View>
              {input && (
                <View className="flex-row items-center">
                  {productLoading ? (
                    <View className="w-full flex-1 lg:w-1/2 h-10 rounded-lg justify-center">
                      <ActivityIndicator size={"small"}></ActivityIndicator>
                    </View>
                  ) : (
                    <View className="w-1/2 mr-3">
                      <Input
                        type="smallcounter"
                        setValue={(e, type) => {
                          onUpdateCartItems(e, type);
                        }}
                        value={itemCount}
                        isDecrementDisabled={itemCount === 1}
                        // isIncrementDisabled={itemCount >= data?.data?.item.stock}
                      ></Input>
                    </View>
                  )}
                  {isProductDeleting ? (
                    <View className="w-full flex-1 items-start">
                      <ActivityIndicator size={"small"}></ActivityIndicator>
                    </View>
                  ) : (
                    <View className="flex-1 w-full items-start">
                      <AntDesign
                        name="delete"
                        size={22}
                        color={colors.app.e}
                        onPress={onDeleteItem}
                      />
                    </View>
                  )}
                </View>
              )}
              {showQty && (
                <View className="flex-row items-center">
                  <View className="mr-2">
                    <Font className="text-app-d1" weight={600}>
                      {" "}
                      Qty :
                    </Font>
                  </View>
                  <View className="flex-1 w-full items-start">
                    <Font className="text-app-d1" weight={600}>
                      {itemCount}
                    </Font>
                  </View>
                </View>
              )}
              {note && (
                <View className="flex-row items-center w-1/2 pt-2">
                  <Pressable
                    className="flex-1 w-full items-center"
                    onPress={() => setAddNote((e) => !e)}
                  >
                    <Font className="text-app-g">
                      {!addNote ? "Add note" : "Remove note"}
                    </Font>
                  </Pressable>
                </View>
              )}
            </View>
          </View>
        </View>
        {addNote && (
          <View className="px-1 pb-1">
            <Input type="text"></Input>
          </View>
        )}
      </View>
      {showTotalPrice && (
        <View className="pr-4">
          <Font>
            ${" "}
            {(
              itemCount *
              (+(data?.item.sale_price || 0) / 100 ||
                +(data?.item.price || 0) / 100)
            ).toFixed(2)}
          </Font>
        </View>
      )}
    </View>
  );
};
