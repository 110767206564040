import React, { useContext } from "react";
import { Image, Pressable, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../colors";
import { AuthContext } from "../context/authContext";
import { UserContext } from "../context/userContext";
import { navigate } from "../services/api";
import { Font } from "./font";
import { ProductsContext } from "../context/productsContext";
import CartItemBadge from "../components/cartitembadge";
import { mediaURI } from "../../constants";
import { useNotificationContext } from "../context/notificationContext";
import NumericBadge from "./NumericBadge";

export const Navbar = (props) => {
  const { cart } = useContext(ProductsContext);
  const { getUserLoading, userData } = useContext(UserContext);
  const { user } = useContext(AuthContext);
  const { unreadCount } = useNotificationContext();
  const menus = [
    { title: "Home", url: "home" },
    { title: "Wishlist", url: "wishlist" },
    { title: "Bookings", url: "bookings" },
    { title: "Orders", url: "orders" },
    { title: "Messages", url: "messages" },
  ];
  const menusGuest = [
    { title: "Home", url: "home" },
    { title: "Blog", url: "blog" },
    { title: "FAQ", url: "faq" },
    { title: "About Us", url: "about-us" },
    { title: "Contact Us", url: "contact-us" },
    // { title: "Terms and Conditions", url: "termscondition" },
    // { title: "Privacy Policy", url: "privacy-policy" },
  ];
  const menusAccount = [
    { title: "Update Profile", url: "update-profile" },
    { title: "Transactions", url: "transactions" },
    { title: "Manage Addresses", url: "manage-addresses" },
    { title: "Invite Contact", url: "invite-contact" },
  ];
  const menuSearch = [{ title: "Search", url: "search" }];
  // get user data api call

  return (
    <View
      className={`w-full h-16 bg-app-e lg:bg-app-a border-b border-b-transparent lg:border-b-gray-200 items-center flex-row px-1 lg:px-4 ${
        props?.route.name === "messages" ? "hidden lg:flex" : ""
      }`}
    >
      {props.back &&
      ![...[...menus, ...menusGuest].map((e) => e.url)].includes(
        props?.route.name
      ) ? (
        <Pressable
          className="p-3 rounded-full active:bg-app-c3 flex lg:hidden"
          onPress={
            [...[...menusAccount].map((e) => e.url)].includes(props?.route.name)
              ? () => props.navigation.navigate("account")
              : props?.route.name === "contacted"
              ? () => props.navigation.navigate("invite-contact")
              : [...[...menus, ...menusGuest].map((e) => e.url)].includes(
                  props?.route.name
                ) || menuSearch.map((e) => e.url).includes(props?.route.name)
              ? () => props.navigation.navigate("home")
              : () => props.navigation.goBack()
          }
        >
          <RemixIcon
            name="arrow-left-s-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
          <RemixIcon
            name="arrow-left-s-line"
            className="hidden lg:flex"
            color={colors.app.d1}
            size="22"
          ></RemixIcon>
        </Pressable>
      ) : null}
      <Image
        source={require("../../assets/smbex-lo.png")}
        className="w-20 h-10 -mt-1 hidden lg:flex"
      ></Image>
      {props?.route.name === "home" ? (
        user ? (
          <>
            <Pressable
              className="p-2 flex-row active:bg-app-c3 rounded-lg flex lg:hidden w-64"
              onPress={() => navigate("account")}
            >
              <Image
                source={
                  userData?.profile_image_url
                    ? { uri: mediaURI + userData?.profile_image_url }
                    : require("../../assets/user1.jpg")
                }
                className="w-10 h-10 rounded-full"
              ></Image>
              <View className="mx-2">
                <Font className="text-xs font-medium text-app-a">
                  {new Date().getHours() < 12
                    ? "Good Morning"
                    : new Date().getHours() < 18
                    ? "Good Afternoon"
                    : "Good Evening"}
                </Font>
                {!getUserLoading &&
                userData?.first_name !== null &&
                userData?.last_name !== null ? (
                  <Font className="font-semibold text-app-a">
                    {userData?.first_name + " " + userData?.last_name}
                  </Font>
                ) : (
                  <Font className="font-semibold text-app-a">Hello, User</Font>
                )}
              </View>
            </Pressable>
            <View className="flex-1 flex lg:hidden"></View>
          </>
        ) : (
          <>
            <Pressable
              className="p-2 flex-row active:bg-app-c3 rounded-lg flex lg:hidden w-40"
              // onPress={() => replace("signin")}
              onPress={() => navigate("signin")}
            >
              <Image
                source={require("../../assets/userImage.jpg")}
                className="w-10 h-10 rounded-full"
              ></Image>
              <View className="mx-2 justify-center">
                <Font className="font-semibold text-app-a">Sign In</Font>
              </View>
            </Pressable>

            <View className="flex-1 flex lg:hidden"></View>
          </>
        )
      ) : null}
      {props?.route.name === "search" ? (
        <Pressable
          className="bg-app-a rounded flex-1 h-10 mx-2 flex lg:hidden px-2 py-1 justify-center"
          onPress={() => navigate("search-modal")}
        >
          {/* <Font className="text-xs text-app-d1" weight={500}>
            Product
          </Font> */}
          <Font weight={600} className="text-app-d">
            {props?.route.params?.keyword !== ""
              ? props?.route.params?.keyword
              : props?.route.params?.category?.label ?? "All"}
          </Font>
        </Pressable>
      ) : null}
      {props?.route.name !== "home" && props?.route.name !== "search" ? (
        <>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px] flex lg:hidden px-2">
            {props?.options?.title}
          </Font>
          <View className="flex-1 flex lg:hidden"></View>
        </>
      ) : null}
      <View className="flex-1 hidden lg:flex flex-row pl-4">
        {(user ? menus : menusGuest).map((e, i) => (
          <View key={i}>
            <Pressable
              className="p-4 active:bg-app-c3 rounded-lg"
              onPress={() => navigate(e.url)}
            >
              <Font
                className={`${
                  props?.route.name === e.url ? "text-app-d" : "text-app-d1"
                }`}
                weight={props?.route.name === e.url ? 700 : 600}
              >
                {e.title}
              </Font>
            </Pressable>
            <View className="flex-1 flex lg:hidden"></View>
          </View>
        ))}
      </View>
      {props?.route.name !== "checkout" && (
        <Pressable
          className="p-3 rounded-full active:bg-app-c3 relative"
          onPress={() => navigate("cart")}
        >
          <RemixIcon
            name="shopping-cart-line"
            className="flex lg:hidden"
            color={colors.app.a}
            size="22"
          ></RemixIcon>
          <RemixIcon
            name="shopping-cart-line"
            className="hidden lg:flex"
            color={colors.app.d1}
            size="22"
          ></RemixIcon>
          {/* <View className="bg-app-a border border-app-a rounded-full h-[18px] w-[18px] justify-center items-center absolute top-1 right-0">
        <Font className="text-app-e text-xs tracking-wider" weight={600}>{cart.length}</Font>
        </View> */}
          <CartItemBadge />
        </Pressable>
      )}
      <Pressable
        className="p-3 rounded-full active:bg-app-c3 relative"
        onPress={() => navigate("notifications")}
      >
        <RemixIcon
          name="notification-4-line"
          className="flex lg:hidden"
          color={colors.app.a}
          size="22"
        ></RemixIcon>
        <RemixIcon
          name="notification-4-line"
          className="hidden lg:flex"
          color={colors.app.d1}
          size="22"
        ></RemixIcon>
        <NumericBadge count={unreadCount} />
      </Pressable>
      {user ? (
        <Pressable
          className="p-2 flex-row active:bg-app-c3 rounded-lg hidden lg:flex w-40 mr-6"
          onPress={() => navigate("account")}
        >
          <Image
            source={
              userData?.profile_image_url
                ? { uri: mediaURI + userData?.profile_image_url }
                : require("../../assets/user1.jpg")
            }
            className="w-10 h-10 rounded-full"
          ></Image>
          <View className="mx-2">
            <Font className="text-xs font-medium text-app-d1">
              {new Date().getHours() < 12
                ? "Good Morning"
                : new Date().getHours() < 18
                ? "Good Afternoon"
                : "Good Evening"}
            </Font>
            {!getUserLoading && userData?.first_name && userData?.last_name ? (
              <Font className="font-semibold text-app-d">
                {userData?.first_name + " " + userData?.last_name}
              </Font>
            ) : (
              <Font className="font-semibold text-app-d">Hello, User</Font>
            )}
          </View>
        </Pressable>
      ) : (
        <Pressable
          className="p-2 flex-row active:bg-app-c3 rounded-lg hidden lg:flex  w-40"
          onPress={() => navigate("signin")}
        >
          <Image
            source={require("../../assets/userImage.jpg")}
            className="w-8 h-8 rounded-full"
          ></Image>
          <View className="mx-2 justify-center">
            <Font className="font-semibold text-app-d">Sign In </Font>
          </View>
        </Pressable>
      )}
    </View>
  );
};
