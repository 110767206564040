import { View, Text, Pressable } from "react-native";
import React from "react";
import SVGLoader from "../../../utils/SVGLoader";
import search from "../../../assets/search.svg";
import { Font } from "../font";
import { Card } from "../card";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import RemixIcon from "react-native-remix-icon";

const GroupNavigations = ({
  memberList,
  adminList,
  setGroupTab = () => null,
  groupTab,
  groupTabs = ["For you", "Your groups", "Settings"],
  showTopTabs = false,
  selectedGroupDetails = null,
  checkRefreshList,
}) => {
  const navigation = useNavigation();

  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();

  return (
    <>
      {/** Mobile Tab Nav */}
      {showTopTabs ? (
        <View className="block lg:hidden mb-2 flex-row lg:flex-row w-full justify-center lg:justify-start">
          {groupTabs?.map((tab) => (
            <Pressable
              className={`ml-1 min-w-max shadow-sm  border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg px-2 py-2 ${
                groupTab === tab ? "bg-app-e" : "bg-none"
              }`}
              onPress={() => {
                setGroupTab(tab);
              }}
            >
              <Font
                className={`text font-medium self-center justify-self-center ${
                  groupTab === tab ? "text-app-a" : "text-app-d1"
                }`}
                weight={600}
              >
                {tab}
              </Font>
            </Pressable>
          ))}
        </View>
      ) : (
        <></>
      )}
      {/** Desktop Tab Nav */}
      <View className="mb-5 max-w-screen-lg hidden lg:flex-col lg:flex pl-4 pr-0">
        <Card className="w-full  " style={{ width: "100%" }}>
          <View className="w-full px-4 flex-col flex-1">
            {/* <View className="flex justify-center items-center">
              <View className="hidden lg:flex lg; w-full mb-4 px-4 lg:px-0  flex-row bg-[#d4d4d4] rounded-full shadow-sm">
                <View className="w-full flex-row justify-between items-center">
                  <TextInput
                    className="w-full ml-2 p-2"
                    placeholder="Search Groups"
                    placeholderTextColor="#94a3b8"
                    type="text"
                    // value={searchText}
                    // onChangeText={(e) => setSearchText(e)}
                    // onSubmitEditing={getCustomersToInvite}
                    returnKeyType="search"
                    style={{ outline: "none" }}
                  />
                  <View className="w-8 h-8 -ml-6 lg:mr-2 lg:ml-0 justify-center items-center">
                    <SVGLoader
                      src={search}
                      height={25}
                      width={25}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    />
                  </View>
                </View>
              </View>
            </View> */}
            {groupTabs?.map((tab, index) => (
              <View
                key={"tab-index-" + index}
                className="w-full flex  justify-start items-center"
              >
                <Pressable
                  className={`ml-1 mb-2 min-w-max w-full shadow-sm  border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg px-2 py-2 ${
                    groupTab === tab ? "bg-app-e" : "bg-none"
                  }`}
                  onPress={() => {
                    setGroupTab(tab);
                  }}
                >
                  <Font
                    className={`text font-medium self-start justify-start ${
                      groupTab === tab ? "text-app-a" : "text-app-d1"
                    }`}
                    weight={600}
                  >
                    {tab}
                  </Font>
                </Pressable>
              </View>
            ))}
          </View>
        </Card>
        <Card className="hidden lg:flex w-full">
          <View className="hidden lg:flex w-full px-4 flex-col ">
            <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
              <Font className="text-sm font font-bold self-center">
                Groups you are admin
              </Font>
              <Pressable
                className="flex-row"
                onPress={() => {
                  setGroupTab("Your groups");
                }}
              >
                <Font className="text-sm text-app-e self-center">See all</Font>
              </Pressable>
            </View>
            <View className="w-full flex justify-center items-center"></View>

            {!adminList?.length ? (
              <Font className="text-center text-app-d1 text-sm py-4 font-semibold">
                You have no groups as admin
              </Font>
            ) : (
              [
                ...(adminList || []),
                ...(selectedGroupDetails ? [selectedGroupDetails] : []),
              ]?.map((group, index) => {
                const isGroupSelected = selectedGroupDetails?.id === group.id;
                return (
                  <View
                    key={"side-group-admins" + index}
                    className="w-full flex justify-start items-center"
                  >
                    <Pressable
                      className={`p-2 px-3 w-full mb-4 flex-col items-center shadow-sm rounded-lg ${
                        isGroupSelected ? "bg-app-e" : "bg-gray-200"
                      }`}
                      onPress={() => {
                        // checkRefreshList();
                        navigation.navigate("group-details", {
                          id: group?.id,
                          name: group?.name || "",
                          description: group?.description || "",
                          isPrivate: group?.is_private,
                          checkRefreshList: checkRefreshList,
                          noOfPosts: group?.noOfPosts,
                          icon_cover_image_url: group?.icon_cover_image_url,
                          isAdmin: true,
                        });
                      }}
                    >
                      <View className="flex-col">
                        <Font
                          className={`text-sm font-bold lg:flex lg:flex-row ${
                            isGroupSelected ? "text-app-a" : "text-black"
                          }`}
                        >
                          {group.name}
                          <View className="items-center flex-row lg:flex">
                            <View>
                              <RemixIcon
                                name="ri-checkbox-blank-circle-fill"
                                size={4}
                                color="#000000"
                                className=" ml-2 mr-1  lg:mt-0  flex-shrink-0 justify-center items-center hidden lg:flex"
                              />
                            </View>
                            <View>
                              <RemixIcon
                                className="hidden lg:flex"
                                name={
                                  group?.is_private
                                    ? `ri-lock-fill`
                                    : `ri-earth-fill`
                                }
                                size={14}
                                color="#000000"
                              />
                            </View>
                          </View>
                        </Font>
                        <Font
                          className={`text-xs ${
                            isGroupSelected ? "text-app-a" : "text-gray-400"
                          }`}
                        >
                          Updated {timeAgo(group?.updated_at)}
                        </Font>
                      </View>
                    </Pressable>
                  </View>
                );
              })
            )}
          </View>
        </Card>
        <Card className="hidden lg:flex w-full">
          <View className="hidden lg:flex w-full px-4 flex-col ">
            <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
              <Font className="text-sm font font-bold self-center">
                Groups you've joined
              </Font>
              <Pressable
                className="flex-row"
                onPress={() => {
                  setGroupTab("Your groups");
                }}
              >
                <Font className="text-sm text-app-e self-center">See all</Font>
              </Pressable>
            </View>
            <View className="w-full flex justify-center items-center"></View>

            {!memberList?.length ? (
              <Font className="text-center text-sm text-app-d1 py-4 font-semibold">
                You have not joined any Group
              </Font>
            ) : (
              [
                ...(memberList || []),
                ...(selectedGroupDetails ? [selectedGroupDetails] : []),
              ]?.map((e, i) => {
                const isGroupSelected = selectedGroupDetails?.id === e.id;
                return (
                  <View
                    key={"side-group-members" + i}
                    className="w-full hidden lg:flex justify-start items-center"
                  >
                    <Pressable
                      className={`p-2 px-3 w-full mb-4 flex-row  items-center shadow-sm rounded-lg ${
                        isGroupSelected ? "bg-app-e" : "bg-gray-200"
                      }`}
                      onPress={() => {
                        // checkRefreshList();
                        navigation.navigate("group-details", {
                          id: e?.id,
                          name: e?.name || "",
                          description: e?.description || "",
                          isPrivate: e?.is_private,
                          checkRefreshList: checkRefreshList,
                          noOfPosts: e?.noOfPosts,
                          icon_cover_image_url: e?.icon_cover_image_url,
                          isAdmin: false,
                        });
                      }}
                    >
                      <View className="flex-col">
                        <Font
                          className={`text-sm font-bold hidden lg:flex lg:flex-row ${
                            isGroupSelected ? "text-app-a" : "text-black"
                          }`}
                        >
                          {e?.name}
                          <View className="items-center lg:flex-row hidden lg:flex">
                            <View>
                              <RemixIcon
                                name="ri-checkbox-blank-circle-fill"
                                size={4}
                                color="#000000"
                                className=" ml-2 mr-1  lg:mt-0  flex-shrink-0 justify-center items-center hidden lg:flex"
                              />
                            </View>
                            <View>
                              <RemixIcon
                                className="hidden lg:flex"
                                name={
                                  e?.is_private
                                    ? `ri-lock-fill`
                                    : `ri-earth-fill`
                                }
                                size={14}
                                color="#000000"
                              />
                            </View>
                          </View>
                        </Font>
                        <Font
                          className={`text-xs ${
                            isGroupSelected ? "text-app-a" : "text-gray-400"
                          }`}
                        >
                          Updated {timeAgo(e?.updated_at)}
                        </Font>
                      </View>
                    </Pressable>
                  </View>
                );
              })
            )}
          </View>
        </Card>
      </View>
    </>
  );
};

export default GroupNavigations;
