import React, { useContext, useState } from "react";
import { Image, View, Pressable } from "react-native";
import { Font } from "../../components/font";
import { Button, Input } from "../../components/input";
import { ScrollView } from "react-native-gesture-handler";
import { AuthContext } from "../../context/authContext";
import { postForgotPassword } from "../../services/api";
import { ResponseModal } from "../../components/response-modal";

export const ForgotPassword = ({ navigation }) => {
  const { setIsAuthLoading } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [isInputEmailValid, setIsInputEmailValid] = useState(false);
  const [modal, setModal] = useState({
    isVisible: false,
    message: "Test Modal",
  });
  const resetPassword = async () => {
    if (email !== "" && isInputEmailValid) {
      setIsAuthLoading(true);
      try {
        const res = await postForgotPassword({
          email,
        });
        if (res.status === 200) {
          navigation.navigate("email-sent-successful");
        } else {
          console.log("forgot-password, resetpass res error : ", res.data);
        }
      } catch (error) {
        console.log("forgot-password, resetpass  error : ", error);
      } finally {
        setIsAuthLoading(false);
      }
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-start" }}
          keyboardShouldPersistTaps="always"
        >
          <View className="w-80 items-center mt-16">
            <Pressable
              onPress={() => navigation.navigate("app", { screen: "home" })}
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Forgot password
              </Font>
            </View>
            <View className="w-full mb-4 flex-1">
              <Input
                type="email"
                value={email}
                setValue={setEmail}
                label="Email"
                placeholder="user@example.com"
                validation={true}
                isValid={(e) => {
                  setIsInputEmailValid(e);
                }}
              ></Input>
              {email !== "" && !isInputEmailValid && (
                <View className="flex-1">
                  <Font className="text-app-e">
                    Please enter a valid email.
                  </Font>
                </View>
              )}
            </View>
            <View className="w-full my-2 flex-1">
              <Button
                type={`${
                  email !== "" && isInputEmailValid ? "primary" : "disabled"
                }`}
                label="Send reset password email"
                onPress={resetPassword}
              ></Button>
            </View>
          </View>
        </ScrollView>

        {/* {modal.isVisible && (
          <View>
            <ResponseModal
              modalContent={modal.message}
              modalVisible={modal.isVisible}
              setVisible={(e) => setModal({ ...modal, isVisible: e })}
              primaryButtonLabel="Dismiss"
              onPrimaryButtonPress={() => {
                setModal({ isVisible: false, message: null });
                navigation.navigate("signin");
              }}
            ></ResponseModal>
          </View>
        )} */}
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d"></View>
    </View>
  );
};
