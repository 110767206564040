import React, { memo, useLayoutEffect, useRef, useState } from "react";
import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import Camera from "../components/expo-camera-custom";
import { ResponseModal } from "./response-modal";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import {
  CameraType,
  FlashMode,
  ImageType,
} from "expo-camera/build/Camera.types";

const CameraCustomView = ({
  onDismiss = () => null,
  onRequestClose = () => null,
  onImageCapture = () => null,
}) => {
  const cameraRef = useRef();
  const [cameraReady, setCameraReady] = useState(false);
  const [facing, setFacing] = useState(CameraType.back);
  const [flashStatus, setFlashStatus] = useState(FlashMode.auto);
  const [detectingFace, setDetectingFace] = useState(false);
  const [permission, setPermission] = useState(false);

  const [status, requestPermission] = Camera.useCameraPermissions();
  const [responseModal, setResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  useLayoutEffect(() => {
    setPermission(status);
  }, [status]);

  useLayoutEffect(() => {
    new Promise((resolve, reject) => {
      if (Platform.OS === "web")
        resolve(
          Camera.isAvailableAsync()
            .then(Camera.getAvailableCameraTypesAsync)
            .then((cameraFace) => {
              setFacing(cameraFace);
              return cameraFace;
            })
            .catch(reject)
        );
      else resolve();
    })
      .then(requestPermission)
      .then(Camera.getCameraPermissionsAsync)
      .then((permissions) => {
        if (permissions.granted) {
          setPermission(permissions);
          return "Starting Camera";
        } else if (permissions.canAskAgain)
          return Camera.requestCameraPermissionsAsync();
        else throw "Camera Permissions Blocked";
      })
      .then(console.log)
      .catch((error) => {
        setResponseModal({
          visible: true,
          bodyText: error?.message,
          onRequestClose: () => {
            setResponseModal({
              visible: false,
              bodyText: "",
              onRequestClose: () => null,
            });
          },
        });
      });
  }, []);

  if (!permission) {
    // Camera permissions are still loading.
    return <View />;
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet.
    return (
      <View style={styles.container}>
        <Text style={styles.message}>
          We need your permission to show the camera
        </Text>
      </View>
    );
  }

  function toggleCameraFacing() {
    setFacing((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  }
  function toggleFlashStatus() {
    setFlashStatus((current) =>
      current === FlashMode.auto
        ? FlashMode.on
        : current === FlashMode.on
        ? FlashMode.off
        : FlashMode.auto
    );
  }

  async function capturePicture() {
    if (!cameraReady || !cameraRef.current) return;
    const result = await cameraRef.current?.takePictureAsync({
      exif: true,
      // additionalExif: true,
      imageType: ImageType.jpg,
      isImageMirror: facing === CameraType.front,
      quality: 0.8,
    });
    if (result) {
      onImageCapture(result);
    }
  }

  return (
    <Modal
      visible={true}
      transparent={true}
      animationType="fade"
      onDismiss={onDismiss}
      onRequestClose={onRequestClose}
    >
      <View style={styles.container}>
        <Camera
          key={facing}
          ref={cameraRef}
          style={styles.camera}
          onCameraReady={() => {
            setCameraReady(true);
          }}
          type={facing}
          autoFocus={true}
          flashMode={flashStatus}
          // useCamera2Api={true}
          onFacesDetected={() => {
            setDetectingFace(true);
            setTimeout(() => {
              setDetectingFace(false), 1000;
            });
          }}
        >
          <View style={styles.cameraUI}>
            <View style={styles.centerContainer}></View>
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={toggleFlashStatus}
              >
                <MaterialIcons
                  name={
                    flashStatus === FlashMode.off
                      ? "flash-off"
                      : flashStatus === FlashMode.on
                      ? "flash-on"
                      : "flash-auto"
                  }
                  size={30}
                  color="white"
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.captureButton}
                onPress={capturePicture}
              >
                <MaterialIcons name="camera-alt" size={34} color="white" />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={toggleCameraFacing}
              >
                <MaterialIcons
                  name={
                    facing === CameraType.back ? "camera-front" : "camera-rear"
                  }
                  size={34}
                  color="white"
                />
              </TouchableOpacity>
            </View>
          </View>
        </Camera>
      </View>
      <ResponseModal
        modalVisible={responseModal?.visible}
        onPrimaryButtonPress={responseModal?.onRequestClose}
        modalContent={responseModal?.bodyText}
      />
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  message: {
    textAlign: "center",
    paddingBottom: 10,
  },
  camera: {
    flex: 1,
    flexDirection: "column",
    alignSelf: "center",
    width: Platform.OS === "web" ? "100%" : "auto",
    height: "auto",
  },
  cameraUI: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    // flex: 1,
    width: "auto",
    height: "100%",
    padding: 0,
    margin: 0,
    backgroundColor: "#00000000",
  },
  centerContainer: {
    flex: 1,
    height: "100%",
    width: "100%",
    flexDirection: "row",
    backgroundColor: "transparent",
    alignItems: "flex-start",
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "transparent",
    justifyContent: "space-around",
    alignItems: "center",
    alignSelf: "flex-end",
    marginTop: 40,
    marginBottom: 40,
  },
  captureButton: {
    // flex: 1,
    alignItems: "center",
    borderRadius: 100,
    borderColor: "#fff",
    borderWidth: 2,
    padding: 20,
    alignSelf: "center",
  },
  button: {
    alignSelf: "center",
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
});

export default memo(CameraCustomView);
