import { View, Image, Pressable } from "react-native";
import { Font } from "./font";
import { useNavigation } from "@react-navigation/native";

export function Footer() {
  const navigation = useNavigation();
  return (
    <View className="w-full lg:pb-0 mt-8 items-center  lg:flex-2">
      <View className="w-full max-w-screen-lg mx-auto p-4 lg:p-0 py-8 items-center  flex-col lg:flex-row border-t border-t-gray-200 ">
        <View className="w-full lg:w-1/2 px-2 lg:px-4">
          <Image
            source={require("../../assets/smbex-lo.png")}
            className="h-8 w-20 mb-4"
          ></Image>
          <Font className="my-2 text-app-d" weight={500}>
            Connect With Family
          </Font>
          <Font className="my-2 text-app-d1">
            SMBEx stands for Small & Medium Business Exchange— A Marketplace
            That Cares and Serves Your Local Community. A localized listing of
            businesses that commit to high ethic standards and excellent
            customer service. SMBEx serves as a one-stop marketplace for guests
            visiting a local neighborhood for leisure or business or to attend a
            specific event, including accommodations(like airbnb).
          </Font>
        </View>
        <View className="w-full lg:w-1/2 pt-0 lg:pt-4  px-2  lg:px-4">
          <View className="h-16 hidden lg:flex"></View>
          <Font className="my-2 text-app-d1">
            SMBEx's mission is to gird up a community that serves people before
            profits, empower local economy, bring value to society at large
            through a strong shared purpose, and improving lives with a
            long-term impact on future generations.
          </Font>
          <Font className="my-2 text-app-d" weight={500}>
            All are welcome to join our community!
          </Font>
        </View>
      </View>
      <View className="w-full   max-w-screen-lg mx-auto py-4 items-center  justify-center lg:justify-evenly border-t border-t-gray-200 flex-row flex-wrap">
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("about-us");
          }}
        >
          <Font className="text-app-d" weight={500}>
            About Us
          </Font>
        </Pressable>
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("contact-us");
          }}
        >
          <Font className="text-app-d" weight={500}>
            Contact Us
          </Font>
        </Pressable>
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("faq");
          }}
        >
          <Font className="text-app-d" weight={500}>
            FAQ
          </Font>
        </Pressable>
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("blog");
          }}
        >
          <Font className="text-app-d" weight={500}>
            Blog
          </Font>
        </Pressable>
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("privacy-policy");
          }}
        >
          <Font className="text-app-d" weight={500}>
            Privacy Policy
          </Font>
        </Pressable>
        <Pressable
          className="items-center py-2 px-4"
          onPress={() => {
            navigation.navigate("termscondition");
          }}
        >
          <Font className="text-app-d" weight={500}>
            Terms of Service
          </Font>
        </Pressable>
      </View>
      <View className="w-full max-w-screen-lg mx-auto pt-10 items-center justify-center border-t border-t-gray-200">
        <Font>© 2022 SMBEx.com. All Rights Reserved.</Font>
      </View>
    </View>
  );
}
