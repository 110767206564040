import React from "react";
import { ActivityIndicator, Text } from "react-native";
import WebView from "react-native-webview";
import colors from "../../colors";

const VideoPlayer = ({ videoURI = "" }) => {
  const [loading, setLoading] = React.useState(false);
  const videoURL = React.useMemo(() => {
    const youtubeVideoId = videoURI.match(
      /(?:youtube.com\/(?:[^\/]+\/|(?:v|e(?:mbed)?)|.*[?&]v=)|youtu.be\/)([^"&?\/ ]{11})/
    )?.[1];
    if (youtubeVideoId) {
      return (
        "https://www.youtube.com/embed/" +
        youtubeVideoId +
        "?rel=0&autoplay=0&showinfo=0&controls=1"
      );
    }
    if (videoURI.includes("youtu")) {
      return "https://www.youtube.com/embed";
    }
    return videoURI;
  }, [videoURI]);

  return (
    <>
      <WebView
        style={{ flex: 1, resizeMode: "contain" }}
        javaScriptEnabled={true}
        allowsFullscreenVideo={true}
        allowsInlineMediaPlayback={true}
        automaticallyAdjustContentInsets
        automaticallyAdjustsScrollIndicatorInsets
        incognito
        useWebView2
        containerStyle={{
          borderRadius: 8,
        }}
        className="rounded-lg"
        source={
          /^(?:(?:https?|ftp):\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
            videoURL
          )
            ? {
                uri: videoURL,
              }
            : {
                html: `<body style="width: 100vw; height: 100vh; display: flex; align-items: center; justify-content: center;"><h1 style="display: inline-block; margin: auto;">Invalid URL</h1></body>`,
              }
        }
        onLoadStart={setLoading.bind(undefined, true)}
        onLoadEnd={setLoading.bind(undefined, false)}
      />
      {loading ? (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          color={colors.app.e}
          style={{ position: "absolute", alignSelf: "center" }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VideoPlayer;
