import React, { createContext, useEffect, useState } from "react";
import { isUSerLoggedin } from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [authApiError, setAuthApiError] = useState(null);

  const isLoggedIn = async () => {
    try {
      setIsAuthLoading(true);
      const data = await isUSerLoggedin();
      if (data) {
        setUser(true);
        setUserToken(data.token);
      } else {
        console.log("is logged in err token set to null");
        setUser(null);
        setUserToken(null);
      }
    } catch (error) {
      console.log("authContext, is logged in error : ", error);
    } finally {
      setIsAuthLoading(false);
    }
  };
  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading,
        user,
        authApiError,
        userToken,
        setIsAuthLoading,
        setUser,
        setAuthApiError,
        setUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
