import React, { useContext, useEffect, useState } from "react";
import { Pressable, ScrollView, View } from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Unautorized } from "./unautorized";
import { AuthContext } from "../../context/authContext";
import { Switcher } from "../../components/input";
import { UserContext } from "../../context/userContext";
import { getBookings } from "../../services/api";
import { ActivityIndicator } from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { Card } from "../../components/card";
import Toast from "react-native-toast-message";
export const Bookings = ({ navigation }) => {
  const { user, userToken, setToken, setUser, isAuthLoading } =
    useContext(AuthContext);
  const { bookings, setBookings, isUserLoading, setIsUserLoading } =
    useContext(UserContext);

  const [filter, setFilter] = useState("all");
  const [refreshing, setRefreshing] = useState(false);

  const [pagination, setPagination] = useState({ offset: 0, total: 0 });

  const onChangeFilter = (e) => {
    setFilter(e);
  };

  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const limitPerPage = 3;
  const getUserBookings = async ({
    push = false,
    pagination: localPagination,
  } = {}) => {
    try {
      setRefreshing(true);
      setIsUserLoading(true);
      const res = await getBookings({
        token: userToken,
        filter,
        offset: localPagination?.offset ?? pagination.offset,
        limit: limitPerPage,
      });

      if (res.status === 200) {
        setPagination({
          offset: push
            ? bookings?.length - 1
            : localPagination?.offset ?? pagination.offset,
          total: res.data["total_count"],
        });
        if (push) {
          setBookings([...bookings, ...res.data["data"]]);
        } else {
          setBookings(res.data["data"]);
        }
      } else {
        setBookings([]);
        if (res.status === 502) {
          showToast(
            "error",
            "Bookings error",
            "Something went wrong on our side."
          );
        } else if (res.status === 404) {
          showToast("error", "Error", "Not Found");
        }
        console.log("bookings, getuserbookings res error : ", res.data);
      }
    } catch (error) {
      setBookings([]);
      console.log("bookings, getuserbookings error : ", error);
    } finally {
      setRefreshing(false);
      setIsUserLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getUserBookings();
    }
  }, [user, userToken, filter]);

  const getServiceName = (index, serviceId) => {
    if (bookings[index]?.listing) {
      const services = Object.keys(bookings?.[index]?.listing?.services || {});
      let name;
      services.forEach((e) => {
        const a = bookings[index]?.listing?.services[e].filter(
          (ee) => serviceId === ee.service_unit.id
        );
        name = a[0]?.service_unit?.name;
        return;
      });
      return `${name}s`;
    } else {
      return "units";
    }
  };

  // if (isUserLoading) {
  //   return (
  //     <View className="flex-1 justify-center items-center">
  //       <Font> Loading Data...</Font>
  //     </View>
  //   );
  // }

  return user ? (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          className="p-4 rounded-t-2xl "
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                getUserBookings({ pagination });
              }}
            />
          }
        >
          <View className="flex-1 w-full justify-center items-center">
            <View className="flex-col w-full lg:w-11/12 lg:px-4">
              <View className="my-5 hidden lg:flex">
                <Font className="text-xl font-semibold text-app-d">
                  Bookings
                </Font>
              </View>
              <View className="w-full flex-col lg:flex-row">
                <View className="w-full flex-1 ">
                  <View className="mb-5">
                    <Switcher
                      options={[
                        { label: "All", value: "all" },
                        // { label: "Pending", value: "created" },
                        { label: "Completed", value: "completed" },
                        { label: "Cancelled", value: "cancelled" },
                      ]}
                      value={filter}
                      setValue={(e) => onChangeFilter(e)}
                    ></Switcher>
                  </View>
                  {/* <View className="pb-5">
                  <Select
                    options={[
                      { label: "All", value: "all" },
                      { label: "Created", value: "created" },
                      { label: "Completed", value: "completed" },
                      { label: "Cancelled", value: "cancelled" },
                      { label: "Rejected", value: "rejected" },
                      {
                        label: "Confirmation pending",
                        value: "pending_confirmation",
                      },
                    ]}
                    className="w-full mb-4"
                    mode="simple"
                    value={filterNew}
                    setValue={(e) => {
                      console.log("e : ", e);
                      onChangeFilterNew(e);
                    }}
                  ></Select>
                </View> */}
                  <Card>
                    {bookings?.length > 0 && !isUserLoading ? (
                      <View className="px-4 w-full">
                        {bookings?.map((e, i) => (
                          <View
                            className="flex w-full p-0 border-b border-gray-200 flex-row"
                            key={`bookings_${i}`}
                          >
                            {/* <Pressable
                        className="overflow-hidden rounded-none rounded-l-lg w-28 lg:w-44 h-32"
                        onPress={() => navigation.navigate("listing")}
                      >
                        <Image
                          source={require("../../../assets/bg.jpg")}
                          className="h-full w-full"
                        ></Image>
                      </Pressable> */}
                            <Pressable
                              className="justify-center p-4 flex-1 w-full bg-app-a active:bg-app-c1"
                              onPress={() => {
                                navigation.navigate("booking-details", {
                                  booking_id: e.id,
                                  // listing_id: e.listing_id,
                                  // calendar_slot_id: e.sessions[0].calendar_slot_id,
                                  // unit: getServiceName(
                                  //   i,
                                  //   e.sessions[0].service_unit_id
                                  // ),
                                });
                              }}
                            >
                              {/* <View className="items-start">

                                                </View> */}
                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <View
                                  className={`flex-row items-center px-2 py-1 rounded ${
                                    e.status === "completed" ||
                                    e.status === "confirmed" ||
                                    e.status === "verified"
                                      ? "bg-app-g3"
                                      : e.status === "created" ||
                                        e.status === "pending_confirmation"
                                      ? "bg-app-h3"
                                      : (e.status === "rejected" ||
                                          e.status ===
                                            "cancelled_by_customer" ||
                                          e.status === "cancelled_by_vendor") &&
                                        "bg-app-e3"
                                  }`}
                                  style={{
                                    marginVertical: 2,
                                  }}
                                >
                                  <Font
                                    className={`text-xs ${
                                      e.status === "completed" ||
                                      e.status === "confirmed" ||
                                      e.status === "verified"
                                        ? "text-app-g"
                                        : e.status === "created" ||
                                          e.status === "pending_confirmation"
                                        ? "text-app-h"
                                        : (e.status === "rejected" ||
                                            e.status ===
                                              "cancelled_by_customer" ||
                                            e.status ===
                                              "cancelled_by_vendor") &&
                                          "text-app-e"
                                    }`}
                                    weight={600}
                                    numberOfLines={1}
                                  >
                                    {e.status === "cancelled_by_customer"
                                      ? "cancelled"
                                      : e.status === "cancelled_by_vendor"
                                      ? "cancelled by vendor"
                                      : e.status === "pending_confirmation"
                                      ? "confirmation pending"
                                      : e.status}
                                  </Font>
                                </View>
                                <Font className="text-app-d1"> | </Font>
                                <Font
                                  className=" text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {new Date(
                                    e.sessions[0].created_at
                                  ).toDateString()}
                                </Font>
                              </View>
                              <Font
                                className="text-md lg:text-lg text-app-d leading-4 lg:leading-6 my-2"
                                weight={600}
                                numberOfLines={21}
                              >
                                {e.listing
                                  ? e.listing.title
                                  : "This listing no longer exists."}
                              </Font>
                              <View
                                className="flex-row w-full items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <RemixIcon
                                  name="calendar-fill"
                                  size={16}
                                  color={colors.app.f1}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                <Font
                                  className=" text-app-d1 flex-1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  Booking Id : # {e.id}
                                </Font>
                              </View>
                              <View
                                className="flex-row w-full items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <RemixIcon
                                  name="calendar-fill"
                                  size={16}
                                  color={colors.app.f1}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                <Font
                                  className=" text-app-d1 flex-1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {`Booked for : ${new Date(
                                    e.sessions[0].date
                                  ).toDateString()}`}
                                </Font>
                              </View>
                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <RemixIcon
                                  name="group-fill"
                                  size={16}
                                  color={colors.app.f1}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                <Font
                                  className=" text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {e.sessions[0].service_unit_quantity}{" "}
                                  {getServiceName(
                                    i,
                                    e.sessions[0].service_unit_id
                                  )}
                                </Font>
                                <View className="w-4"></View>
                                <RemixIcon
                                  name="price-tag-fill"
                                  size={16}
                                  color={colors.app.f1}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                {/* <Font
                              className=" text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              Total amount:
                            </Font> */}
                                <Font
                                  className=" text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  $ {e.total_amount / 100}
                                </Font>
                              </View>
                            </Pressable>
                          </View>
                        ))}
                        {limitPerPage < pagination.total ? (
                          <>
                            <View className="pt-4 w-full border-b h-0 border-app-c" />
                            <View className="flex-row w-full px-4">
                              {pagination.offset > 0 ? (
                                <Pressable
                                  className="p-2"
                                  onPress={() => {
                                    if (pagination.offset > 0) {
                                      const from =
                                        pagination.offset === 0
                                          ? 0
                                          : pagination.offset - limitPerPage;
                                      const pagObj = {
                                        offset: from,
                                        limit: limitPerPage,
                                        total: pagination.total,
                                      };
                                      getUserBookings({ pagination: pagObj });
                                    }
                                  }}
                                >
                                  <Font className="text-app-e" weight={600}>
                                    &lt; Newer
                                  </Font>
                                </Pressable>
                              ) : (
                                <></>
                              )}
                              <View className="flex-1"></View>
                              {pagination.offset + limitPerPage <
                              pagination.total ? (
                                <Pressable
                                  className="p-2"
                                  onPress={() => {
                                    if (
                                      pagination.offset + limitPerPage <
                                      pagination.total
                                    ) {
                                      const remList =
                                        pagination.total -
                                        (pagination.offset + limitPerPage);
                                      const from =
                                        pagination.offset +
                                        (remList > 0 ? limitPerPage : 0);
                                      const pagObj = {
                                        offset: from,
                                        limit: limitPerPage,
                                        total: pagination.total,
                                      };
                                      getUserBookings({ pagination: pagObj });
                                    }
                                  }}
                                >
                                  <Font className="text-app-e" weight={600}>
                                    Older &gt;
                                  </Font>
                                </Pressable>
                              ) : (
                                <></>
                              )}
                            </View>
                          </>
                        ) : (
                          <></>
                        )}
                      </View>
                    ) : bookings?.length === 0 && !isUserLoading ? (
                      <View className="w-full flex-row h-80 justify-center items-center">
                        <Font className="text-app-d1">No bookings found</Font>
                      </View>
                    ) : (
                      <View className="flex-1 w-full justify-center h-40">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </Card>
                </View>
                <View className="hidden lg:flex lg:items-end w-full lg:w-2/6 p-0 lg:pl-4 pt-5 lg:pt-0">
                  <View className="w-4/5">
                    <Widget></Widget>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  ) : (
    !user && !isAuthLoading && <Unautorized />
  );
};
