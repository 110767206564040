import React, { useState } from "react";
import { Image, View, Pressable, TouchableOpacity } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Font } from "./font";
import { DEV_API } from "../services/api";

export default function UploadPicture({
  isLocalImageAdded,
  setIsLocalImageAdded,
  onImageUpdate,
  profileImage,
  prevImage,
}) {
  const [image, setImage] = useState(null);
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // base64: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      setIsLocalImageAdded(true);
      setImage(result.assets[0].uri);
      onImageUpdate(result.assets[0]);
    }
  };

  return (
    <>
      <View className="w-40 h-44 rounded-lg border border-gray-200 overflow-hidden">
        <Image
          source={
            profileImage === null
              ? require("../../assets/user1.jpg")
              : {
                  uri: isLocalImageAdded
                    ? image
                    : `${DEV_API}/media/${profileImage}`,
                }
          }
          className="w-full h-36 object-cover flex-1"
        />
        <TouchableOpacity
          onPress={pickImage}
          className="justify-center items-center p-2"
        >
          <Font weight={500}>Upload Picture</Font>
        </TouchableOpacity>
      </View>
      {/* {isLocalImageAdded && (
        <TouchableOpacity
          onPress={() => {
            setImage(null);
            setIsLocalImageAdded(false);
            onImageUpdate(prevImage);
          }}
          className="justify-center items-center p-2"
        >
          <Font weight={500} className="text-app-e">
            Remove
          </Font>
        </TouchableOpacity>
      )} */}
    </>
  );
}
