import { Pressable, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import moment from "moment";
import { DEV_API } from "../../services/api";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { useMessagesContext } from "../../context/messagesContext";
import { useNotificationContext } from "../../context/notificationContext";

export const CustomerNotifications = ({ navigation }) => {
  const { userToken } = useContext(AuthContext);
  const { setRedirectChatUserID } = useMessagesContext();

  const { notifications, setNotifications, unreadCount, setUnreadCount } =
    useNotificationContext();
  const [isMarkedAsRead, setIsMarkedAsRead] = useState(false);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 100;

  const back = navigation.canGoBack();
  const dismiss = () => {
    if (back) {
      navigation.goBack();
    } else {
      navigation.navigate("home");
    }
  };

  const markNotificationsAsRead = async (nots) => {
    const markAsRead = async ({ token, id }) => {
      return axios
        .post(
          DEV_API + `/notifications/${id}/mark-read`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return response.status;
        })
        .catch((err) => {
          if (err?.response) return err.response.status;
          console.log("ark as read error:", err);
        });
    };
    const unreadNots = [];
    nots.forEach((n) =>
      unreadNots.push(markAsRead({ token: userToken, id: n.id }))
    );
    Promise.all(unreadNots)
      .then((responses) => {
        setUnreadCount(
          unreadCount -
            (responses?.filter((status) => status == 200)?.length || 0)
        );
      })
      .catch((err) => {
        console.log("Promise.all notifications error: ", err);
      });
  };

  const navigateToScreen = (data) => {
    if (data.screen === "chat") {
      if (data?.channel_details?.customer_id)
        setRedirectChatUserID({
          uid: parseInt(data.channel_details.customer_id),
          callback: () => navigation.navigate("messages"),
        });
      else navigation.navigate("messages");
    } else if (data.screen === "booking_details") {
      if (data?.booking_id)
        navigation.navigate("booking-details", {
          booking_id: parseInt(data.booking_id),
        });
      else navigation.navigate("booking-details");
    } else if (data.screen === "order_details") {
      if (data?.product_order_id)
        navigation.navigate("order-details", {
          id: parseInt(data.product_order_id),
        });
      else navigation.navigate("order-details");
    }
  };

  const getNotifications = ({ limit, offset }) => {
    axios
      .get(DEV_API + "/notifications", {
        params: {
          limit,
          offset,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        const nots = response.data?.["notifications"];
        let unread = 0;
        nots?.forEach((n) => {
          if (!n?.is_read) unread += 1;
        });
        if (unreadCount !== unread) {
          setIsMarkedAsRead(false);
        }
        setUnreadCount(unread);
        setNotifications({
          data: offset ? [...notifications, ...nots] : nots,
        });
        setCurrentPage(currentPage + 1);
        setTotalLength(response.data["total_count"]);
      })
      .catch((err) => {
        console.log("notifications error: ", err);
      });
  };

  useEffect(() => {
    getNotifications({
      limit: pageSize,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    if (notifications?.length && !isMarkedAsRead) {
      setIsMarkedAsRead(true);
      markNotificationsAsRead(
        notifications?.filter((n) => {
          if (!n.is_read) {
            return true;
          } else return false;
        })
      );
    }
  }, [notifications, isMarkedAsRead]);

  const loadMoreHandler = () => {
    try {
      getNotifications({
        limit: pageSize,
        offset: currentPage * pageSize,
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <View className="flex-1 bg-transparent">
      <Pressable
        className="w-full h-full absolute top-0 left-0 bg-transparent"
        onPress={dismiss}
      ></Pressable>
      <View className="absolute w-full h-full lg:w-96 lg:h-1/2 top-0 right-0 lg:top-16 lg:right-40 bg-app-e lg:bg-white rounded-none lg:rounded-lg lg:border lg:border-gray-200 rounded-none overflow-hidden lg:rounded-lg">
        <View className="w-full h-16 bg-app-e lg:bg-app-a border-b border-b-transparent lg:border-b-gray-200 items-center flex-row px-1 lg:px-4">
          <Pressable
            className="p-3 rounded-full active:bg-app-c2 active:bg-opacity-10 flex lg:hidden"
            onPress={dismiss}
          >
            <RemixIcon
              name="arrow-left-line"
              className="flex lg:hidden"
              color={colors.app.a}
              size="22"
            ></RemixIcon>
            <RemixIcon
              name="arrow-left-line"
              className="hidden lg:flex"
              color={colors.app.d1}
              size="22"
            ></RemixIcon>
          </Pressable>
          <Font className="text-app-a lg:text-app-d font-semibold text-[15px]  px-2">
            Notifications
          </Font>
          <View className="flex-1"></View>
          {/* <Pressable className=" px-4">
            <Font className="text-app-a lg:text-app-d font-semibold text-xs">
              Read all
            </Font>
          </Pressable> */}
        </View>
        <View className="rounded-t-lg lg:rounded-none overflow-hidden flex-1">
          <ScrollView
            className="rounded-t-lg lg:rounded-none bg-gray-100 lg:bg-white w-full min-h-full p-4 lg:p-0 mb-6"
            contentContainerStyle={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View className="flex-1 w-full bg-white rounded-2xl">
              {notifications?.map((notification) => (
                <Pressable
                  className="flex-row border-b border-gray-200 p-4"
                  key={notification.id}
                  onPress={() => navigateToScreen(notification.data)}
                >
                  <View className="justify-center flex-1">
                    <View className="flex-row">
                      <RemixIcon
                        name="calendar-line"
                        color={colors.app.d1}
                        size="18"
                      ></RemixIcon>
                      <Font
                        className="text-app-d1 flex-1 ml-2 uppercase"
                        weight={700}
                        numberOfLines={2}
                      >
                        {notification.title}
                      </Font>
                      <Font className="text-xs text-app-d1" weight={600}>
                        {moment(notification.created_at).fromNow()}
                      </Font>
                    </View>
                    <View>
                      <Font
                        className="text-app-d mt-2 leading-4"
                        weight={500}
                        numberOfLines={4}
                      >
                        {notification.subtitle}
                      </Font>
                    </View>
                  </View>
                </Pressable>
              ))}
              {notifications?.length < totalLength && (
                <Pressable
                  onPress={loadMoreHandler}
                  className="w-full p-4 items-center justify-center mb-6"
                >
                  <Font className="text-app-e" weight={600}>
                    Load more
                  </Font>
                </Pressable>
              )}
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};
