import React from "react";
import {
  Platform,
  Pressable,
  View,
  RefreshControl,
  ScrollView,
  ActivityIndicator,
  Button,
  Image,
  TextInput,
} from "react-native";
import { Font } from "../../components/font";
import { ResponseModal } from "../../components/response-modal";
import { Card } from "../../components/card";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { getFullDateTime } from "../../../utils/date-time-util";
import { useUserFeedContext } from "../../context/user-feed-context";
import moment from "moment";
import { cloneDeep } from "lodash";
import { UserRoles, catchAPIErrorText } from "../../../utils";
import {
  DEV_API,
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../services/api";
import { UserContext } from "../../context/userContext";
import { AuthContext } from "../../context/authContext";
import { SharedContext } from "../../context/sharedContext";

const UserReviewDetails = ({ navigation, route, setTabStatus, tabStatus }) => {
  const vrid = +route.params?.slug;
  React.useEffect(() => {
    setTabStatus({ curr: "My Reviews", prev: tabStatus.curr });
  }, []);

  const { userToken } = React.useContext(AuthContext);
  const { userData } = React.useContext(UserContext);
  const user_id = +userData.id;
  const { screen: { width } = {} } = React.useContext(SharedContext);
  const {
    reviewDetails: vendorReviewDetails,
    setReviewDetails: setVendorReviewDetails,
  } = useUserFeedContext();

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });

  const [vendorReviewDetailsData, setVendorReviewDetailsData] =
    React.useState();
  const [operationQueue, setOperationQueue] = React.useState([]);
  const [isInOperation, setIsInOperation] = React.useState();
  const [replyText, setReplyText] = React.useState("");
  const [isEditingReply, setIsEditingReply] = React.useState();

  const [vRDIsLoading, setVRDIsLoading] = React.useState();
  const [isReviewDataFailed, setIsReviewDataFailed] = React.useState();
  const getVendorReviewDetails = async () => {
    setVRDIsLoading(true);
    let data;
    try {
      const res = await axiosGet({
        Url: "/users/me/reviews/" + vrid,
        token: userToken,
      });
      console.log("res.status: ", res.status, res.data);
      if (res.status === 200) {
        data = res.data.data;
      }
    } catch (e) {
      console.log("e.res", e?.response?.data, e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Vendor Review"
        ),
      });
    } finally {
      console.log("data:", data);
      setVendorReviewDetailsData(data || {});
      setVendorReviewDetails({ data, id: vrid });
      setVRDIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isInOperation && operationQueue?.length) {
      let operation;
      setOperationQueue((data) => {
        data = cloneDeep(data);
        [operation] = data.splice(0, 1);
        operation();
        return data;
      });
    }
  }, [isInOperation, operationQueue]);

  if (!userData?.id || !vrid) {
    return navigation.replace("vendor-reviews");
  }

  if (
    !vendorReviewDetailsData &&
    !vendorReviewDetails?.[vrid] &&
    !vRDIsLoading
  ) {
    getVendorReviewDetails();
  } else if (!vendorReviewDetailsData && vendorReviewDetails?.[vrid]) {
    setVendorReviewDetailsData(vendorReviewDetails[vrid] || {});
  }

  if (isReviewDataFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            modalVisible={showResponseModal.visible}
            primaryButtonLabel={"Okay"}
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
          />
        )}
        <View className="p-4 justify-center">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">
            Unable to Get Data from the Server
          </Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!vRDIsLoading) {
                getVendorReviewDetails();
                setIsReviewDataFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!vendorReviewDetails || !vendorReviewDetailsData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            modalVisible={showResponseModal.visible}
            primaryButtonLabel={"Okay"}
            onPrimaryButtonPress={showResponseModal.onRequestClose}
            modalContent={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!vendorReviewDetails && !vendorReviewDetailsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  const deleteVendorReviewReplies = async (vrrid) => {
    try {
      setIsInOperation(true);
      const res = await axiosDelete({
        Url: "/users/me/reviews/" + vrid + "/replies/" + vrrid,
        token: userToken,
      });
      console.log("res.status: ", res.status, res.data);
      if (res.status === 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Successfully deleted reply",
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Deleting Review Reply"
        ),
      });
    } finally {
      setIsInOperation(false);
    }
  };

  const updateVendorReviewReplies = async (dataObj) => {
    try {
      setIsInOperation(true);
      const res = await axiosPatch({
        Url: "/users/me/reviews/" + vrid + "/replies/" + dataObj.id,
        data: { message: dataObj.message },
        token: userToken,
      });
      console.log("res.status: ", res.status, res.data);
      if (res.status === 201) {
        delete dataObj.processText;
        let reviewData = vendorReviewDetails[vrid];
        reviewData.replies = reviewData.replies.filter(
          (r) => r.id !== dataObj.id
        );
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), dataObj],
        };
        setVendorReviewDetails({ data: reviewData, id: vrid });
        setVendorReviewDetailsData((data) => {
          data = cloneDeep(data);
          if (data.replies) {
            const i = data.replies.findIndex((v) => v.id === dataObj.id);
            data.replies[i] = dataObj;
          } else {
            data.replies = [dataObj];
          }
          return data;
        });
        if (res.data?.message) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: res.data.message,
          });
        }
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Updating Review Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const addVendorReviewReply = async (dataObj) => {
    let data;
    try {
      setIsInOperation(true);
      const res = await axiosPost({
        Url: "/users/me/reviews/" + vrid + "/replies",
        data: { message: dataObj.message },
        token: userToken,
      });
      console.log("res.status: ", res.status, res.data);
      if (res.status === 201) {
        data = res.data.data;
        delete dataObj.processText;
        let reviewData = vendorReviewDetails[vrid];
        reviewData = {
          ...reviewData,
          replies: [...(reviewData.replies || []), data],
        };
        setVendorReviewDetails({ data: reviewData, id: vrid });
        setVendorReviewDetailsData((replyData) => {
          replyData = cloneDeep(replyData);
          replyData.replies[
            replyData?.replies?.length ? replyData.replies.length - 1 : 0
          ] = data;
          return replyData;
        });
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data.message,
        });
      }
    } catch (e) {
      console.log("e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Adding Review Reply"
        ),
      });
      return e?.response;
    } finally {
      setIsInOperation(false);
    }
  };

  const onSendReplyHandler = (replyInput, isEditingReply) => {
    setReplyText("");
    let replyObj;
    if (!isEditingReply) {
      const dateNow = moment.now();
      replyObj = {
        id: "reply-local" + Date.now(),
        user_id: user_id,
        user_role: userData.role,
        user_name: userData.first_name + " " + userData.last_name,
        user_profile_image_url: userData.profile_image_url,
        rating: null,
        message: replyInput,
        is_reply: true,
        is_edited: false,
        created_at: dateNow,
        updated_at: dateNow,
        processText: "sending...",
        userData,
      };
    }
    setVendorReviewDetailsData((data) => {
      data = cloneDeep(data);
      if (isEditingReply) {
        setIsEditingReply();
        isEditingReply = {
          ...isEditingReply,
          message: replyInput,
          is_edited: true,
          processText: "updating...",
        };
        const i = data.replies?.findIndex((v) => v.id === isEditingReply.id);
        if (i > -1) {
          data.replies[i] = isEditingReply;
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => updateVendorReviewReplies(isEditingReply));
          return q;
        });
      } else {
        if (!data?.replies) {
          data.replies = [replyObj];
        } else {
          data.replies.push(replyObj);
        }
        setOperationQueue((q) => {
          q = cloneDeep(q);
          q.push(() => addVendorReviewReply(replyObj));
          return q;
        });
      }
      return data;
    });
  };

  const onDeleteReplyHandler = (replyID, replyIndex) => {
    console.log("replyID>>", replyID);
    if (replyIndex > -1) {
      setVendorReviewDetailsData((data) => {
        data = cloneDeep(data);
        data.replies.splice(replyIndex, 1);
        return data;
      });
      setOperationQueue((q) => {
        q = cloneDeep(q);
        q.push(() => deleteVendorReviewReplies(replyID));
        return q;
      });
    }
  };

  return (
    <>
      {showResponseModal.visible && (
        <ResponseModal
          modalVisible={showResponseModal.visible}
          primaryButtonLabel={"Okay"}
          onPrimaryButtonPress={showResponseModal.onRequestClose}
          modalContent={showResponseModal.bodyText}
        />
      )}
      <ScrollView
        className="px-4 pt-2 pb-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={vRDIsLoading}
              onRefresh={() => {
                getVendorReviewDetails();
              }}
            />
          )
        }
        keyboardShouldPersistTaps="always"
      >
        <View className=" max-w-screen-lg w-full mx-auto h-full pt-2">
          <View className="p-0 lg:px-4">
            <Card title="Review Details">
              {vendorReviewDetailsData ? (
                Object.keys(vendorReviewDetailsData || {})?.length ? (
                  <View className="w-full">
                    <View
                      className="mx-4 pb-4 border-b border-gray-200 flex-row"
                      key={vendorReviewDetailsData.id}
                    >
                      <View className="justify-start pt-1 items-center w-24 lg:w-32 ">
                        <Image
                          source={
                            vendorReviewDetailsData.user_profile_image_url
                              ? {
                                  uri:
                                    `${DEV_API}/media/` +
                                    vendorReviewDetailsData.user_profile_image_url,
                                }
                              : require("../../../assets/userImage.jpg")
                          }
                          className="w-12 h-12 lg:w-18 lg:h-18 rounded-full "
                        ></Image>
                      </View>
                      <View className="justify-start ml-4 flex-1 ">
                        <View className="flex-row items-center flex-wrap pb-1 ">
                          <Font
                            className="text-app-d text-base mr-2"
                            weight={600}
                          >
                            {vendorReviewDetailsData?.user_name}
                          </Font>
                          <Font
                            className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4  ${
                              vendorReviewDetailsData?.user_role ===
                              UserRoles.CUSTOMER
                                ? "bg-app-d3 text-app-d"
                                : "bg-app-e3 text-app-e"
                            }`}
                          >
                            {vendorReviewDetailsData?.user_role}
                          </Font>
                        </View>
                        <View className="flex-col lg:flex-row items-start lg:items-center lg:pb-2">
                          <View className="bg-app-d flex-row items-center rounded-full px-1 py-0.5 lg:mr-1">
                            <Font className="text-app-a text-sm mx-1 leading-4">
                              {parseFloat(vendorReviewDetailsData.rating)}/5
                            </Font>
                            <RemixIcon
                              name="star-fill"
                              size={13}
                              color={colors.app.i}
                              className="flex-shrink-0"
                            />
                          </View>
                          <View className="lg:ml-1 flex-row items-center">
                            <RemixIcon
                              name="ri-time-line"
                              size={16}
                              color={colors.app.d}
                              className="mr-1"
                            />
                            <Font className="text-app-d1 leading-4">
                              - on{" "}
                              {getFullDateTime(
                                vendorReviewDetailsData.updated_at
                              )}
                            </Font>
                          </View>
                        </View>
                        <View className="flex-row items-center justify-start py-2">
                          <RemixIcon
                            className="mr-2 self-start h-full"
                            name="ri-discuss-fill"
                            size={16}
                            color={colors.app.d}
                          />
                          <Font
                            numberOfLines={4}
                            className="text-app-d flex-row items-center leading-4"
                          >
                            {vendorReviewDetailsData.is_edited && (
                              <>
                                <RemixIcon
                                  className="pr-2 -mb-px self-start"
                                  name="ri-pencil-fill"
                                  size={12}
                                  color={colors.app.d}
                                />
                                &nbsp;
                              </>
                            )}
                            {vendorReviewDetailsData.message}
                          </Font>
                        </View>
                      </View>
                    </View>
                    {vendorReviewDetailsData?.replies?.length ? (
                      <View className="mx-4 flex-1 items-start justify-start ">
                        <View className="border-b border-gray-200 p-4 w-full flex-row ">
                          <Font className="text-app-d w-max py-1 px-2 border border-app-d2 rounded ">
                            {vendorReviewDetailsData?.replies?.length || "-"}{" "}
                            {vendorReviewDetailsData?.replies?.length === 1
                              ? "Reply"
                              : "Replies"}
                          </Font>
                        </View>
                        {vendorReviewDetailsData.replies.map((reply, i) => (
                          <View
                            className={`pt-4 ${
                              i ===
                              (vendorReviewDetailsData?.replies?.length || 0) -
                                1
                                ? "pb-8"
                                : "pb-4"
                            } border-b border-gray-200 flex-row w-full `}
                            key={`vendorReviewDetailsData${reply.id}_${i}`}
                          >
                            {console.log("reply", user_id)}

                            <View className="justify-start pt-1 items-center w-24 lg:w-32 ">
                              <Image
                                source={
                                  reply.user_profile_image_url
                                    ? {
                                        uri:
                                          `${DEV_API}/media/` +
                                          reply.user_profile_image_url,
                                      }
                                    : require("../../../assets/userImage.jpg")
                                }
                                className="w-12 h-12 lg:w-18 lg:h-18 rounded-full"
                              ></Image>
                            </View>
                            <View className="justify-start ml-4 flex-1 ">
                              {/* <View className="flex-1 flex-row flex-wrap justify-between  bg-orange-600"> */}
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <View className="flex-row flex-wrap items-center pb-1">
                                  <Font
                                    className="text-app-d text-base mr-2"
                                    weight={600}
                                  >
                                    {reply?.user_name}
                                  </Font>
                                  <Font
                                    className={`px-1.5 pt-[2px] pb-[2px] text-app-b capitalize rounded-full leading-4 ${
                                      reply?.user_role === UserRoles.CUSTOMER
                                        ? "bg-app-d3 text-app-d"
                                        : "bg-app-e3 text-app-e"
                                    }`}
                                  >
                                    {reply.user_role}
                                  </Font>
                                </View>
                                {reply.user_id == userData.id ? (
                                  <View className="flex-row items-center">
                                    <Pressable
                                      className="p-0.5 mr-2"
                                      onPress={() => {
                                        setIsEditingReply(reply);
                                        setReplyText(reply.message);
                                      }}
                                    >
                                      <RemixIcon
                                        name="ri-pencil-fill"
                                        className="self-start"
                                        size={20}
                                        color={colors.app.d2}
                                      />
                                    </Pressable>
                                    <Pressable
                                      className="p-0.5"
                                      onPress={() => {
                                        setShowConfirmModal((d) => ({
                                          ...d,
                                          visible: true,
                                          BodyComponent: () => (
                                            <Font>
                                              Do you want to delete the
                                              Specified Review?
                                            </Font>
                                          ),
                                          onConfirm: () => {
                                            setShowConfirmModal((d1) => {
                                              d1.visible = false;
                                              d1.onConfirm = () => null;
                                              d1.onCancel = () => null;
                                              d1.cancelText = "Cancel";
                                              d1.confirmText = "Confirm";
                                              d1.bodyText = "";
                                              return { ...d1 };
                                            });
                                            onDeleteReplyHandler(reply.id, i);
                                          },
                                          onCancel: () =>
                                            setShowConfirmModal((d1) => {
                                              d1.visible = false;
                                              d1.onConfirm = () => null;
                                              d1.onCancel = () => null;
                                              d1.cancelText = "Cancel";
                                              d1.confirmText = "Confirm";
                                              d1.bodyText = "";
                                              return { ...d1 };
                                            }),
                                        }));
                                      }}
                                    >
                                      <RemixIcon
                                        name="ri-delete-bin-5-fill"
                                        className="self-start"
                                        size={20}
                                        color={colors.app.d2}
                                      />
                                    </Pressable>
                                  </View>
                                ) : (
                                  <></>
                                )}
                              </View>
                              <View className="flex-row items-center pb-2">
                                <Font className="text-app-d1 leading-4">
                                  {reply.processText
                                    ? reply.processText
                                    : getFullDateTime(reply.updated_at)}
                                </Font>
                              </View>
                              <View className="flex-row items-center">
                                <RemixIcon
                                  name="ri-reply-fill"
                                  size={16}
                                  color={colors.app.d}
                                  className="mr-2"
                                />
                                <Font
                                  numberOfLines={4}
                                  className="text-app-d flex flex-row items-center leading-4"
                                >
                                  {reply.is_edited && (
                                    <>
                                      <RemixIcon
                                        className="-mb-px self-start"
                                        name="ri-pencil-fill"
                                        size={14}
                                        color={colors.app.d}
                                      />
                                      &nbsp;
                                    </>
                                  )}
                                  {reply.message}
                                </Font>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    ) : (
                      <View
                        className={`px-4 pt-4 pb-8 border-b border-gray-200 w-full`}
                      >
                        <Font className="text-app-d1 self-center">
                          No Replies Found
                        </Font>
                      </View>
                    )}
                    <View className="h-18 w-full py-4 pl-4 pr-1 flex-row items-center border-t rounded-b-lg lg:bg-app-d/95 border-gray-200">
                      <View className="flex-1 mr-1">
                        {/* <Input type="text" placeholder="Type your message"></Input> */}
                        <View
                          className={`rounded-lg border border-gray-200 overflow-hidden ${
                            replyText?.length > 100 ? "h-20" : "h-10"
                          } bg-app-a`}
                        >
                          <TextInput
                            placeholder="Type your reply..."
                            className={`w-full ${
                              Platform.OS === "web" ? "flex-1 py-2.5" : "h-full"
                            } px-3 min-w-0 lg:bg-app-d/95 lg:text-app-a`}
                            style={
                              Platform.OS === "web" && {
                                outlineColor: "transparent",
                              }
                            }
                            multiline={true}
                            textAlignVertical="center"
                            value={replyText}
                            onChangeText={(e) => setReplyText(e)}
                            onKeyPress={(e) => {
                              if (!e.shiftKey && ["Enter"].includes(e.code)) {
                                e.preventDefault();
                                onSendReplyHandler(replyText, isEditingReply);
                              }
                            }}
                          ></TextInput>
                        </View>
                      </View>
                      {isEditingReply && (
                        <Pressable
                          className={`px-4 py-2 mr-1 self-end items-center justify-center rounded-lg ${
                            Platform.OS === "web"
                              ? "active:bg-app-c2"
                              : "active:bg-app-d3"
                          }`}
                          onPress={() => {
                            setReplyText("");
                            setIsEditingReply();
                          }}
                        >
                          <RemixIcon
                            name="ri-close-circle-fill"
                            color={width > 1023 ? colors.app.a : colors.app.d}
                          ></RemixIcon>
                        </Pressable>
                      )}
                      <Pressable
                        className={`pl-4 pr-4 py-2 self-end items-center justify-center rounded-lg ${
                          Platform.OS === "web"
                            ? "active:bg-app-c2"
                            : "active:bg-app-d3"
                        }`}
                        onPress={() => {
                          if (replyText)
                            onSendReplyHandler(replyText, isEditingReply);
                        }}
                      >
                        <RemixIcon
                          name="send-plane-2-line"
                          color={width > 1023 ? colors.app.a : colors.app.d}
                        ></RemixIcon>
                      </Pressable>
                    </View>
                  </View>
                ) : (
                  <View className="flex-1 justify-center items-center pb-8">
                    <Font className="text-app-d1">
                      Vendor Review Details Not Found
                    </Font>
                  </View>
                )
              ) : (
                <View className="flex-1 justify-center items-center pb-8">
                  <Font className="text-app-d">Loading...</Font>
                </View>
              )}
            </Card>
          </View>
        </View>
      </ScrollView>
      {showConfirmModal.visible && (
        <ResponseModal
          modalVisible={showConfirmModal.visible}
          primaryButtonLabel={"Confirm"}
          onPrimaryButtonPress={showConfirmModal.onConfirm}
          showSecondaryButton={true}
          secondaryButtonLabel={"Cancel"}
          onSecondaryButtonPress={showConfirmModal.onCancel}
          modalContent={showConfirmModal.bodyText}
        />
      )}
    </>
  );
};

export default UserReviewDetails;
