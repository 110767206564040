import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { createNavigationContainerRef } from "@react-navigation/native";
import { Platform } from "react-native";
import Toast from "react-native-toast-message";
import { catchAPIErrorText } from "../../utils";
import { createCrossPlatformFormData } from "../../utils/encDec";
// ---------------- old navigation -----------------
export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}

export const DEV_API = "https://smbexapi.wisdomtooth.tech/api/v2";
// export const DEV_API = "https://f36d-120-138-125-150.ngrok-free.app/api/v2";
let token = null;
// ----------------------- axios get ----------------------
export const axiosGet = async ({ Url, token, params = {} }) => {
  // console.log("params ------------------ : ", params);
  try {
    const res = await axios.get(DEV_API + Url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      params,
    });
    return res;
  } catch (error) {
    console.log("error:", error, error?.response?.status);
    // Handle error
    throw error;
  }
};
// ----------------------- axios post ----------------------
export const axiosPost = async ({
  Url,
  token,
  data = {},
  params = {},
  isFormData = false,
}) => {
  try {
    const res = await axios.post(DEV_API + Url, data, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...(isFormData
          ? {
              "Content-Type": "multipart/form-data",
            }
          : {
              "Content-Type": "application/json",
            }),
      },
      params,
    });
    return res;
  } catch (error) {
    // Handle error
    console.log("error:", error);
    throw error;
  }
};
// ----------------------- axios patch ----------------------
export const axiosPatch = async ({
  Url,
  token,
  data = {},
  params = {},
  isFormData = false,
}) => {
  return await axios
    .patch(DEV_API + Url, data, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        ...(isFormData
          ? {
              "Content-Type": "multipart/form-data",
            }
          : {
              "Content-Type": "application/json",
            }),
      },
      params,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      // Handle error
      console.log("error:", error);
      throw error;
    });
};
// ----------------------- axios delete ----------------------
export const axiosDelete = async ({ Url, token }) => {
  try {
    const res = await axios.delete(DEV_API + Url, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });
    return res;
  } catch (error) {
    // Handle error
    console.log("error:", error);
    throw error;
  }
};
// --------------- user ------------------------
export const postUserLogin = async ({
  email,
  password,
  isNonProfit,
  role,
  remember,
}) => {
  try {
    const res = await axios.post(DEV_API + "/auth/login", {
      email,
      password,
      role,
      isNonProfit,
      remember,
    });
    if (res.status === 200) {
      await AsyncStorage.clear();
      await AsyncStorage.setItem(
        "userToken",
        JSON.stringify(res.data.token || "")
      );
      return getUserData(res.data.token);
    }
    throw new Error("Unknown Login Response from server");
  } catch (error) {
    Toast.show({
      type: "error",
      text1: "Login Error",
      text2: catchAPIErrorText(error),
    });
    if (error?.response) return error.response;
    throw error;
  }
};
export const postUserSignUp = async ({
  email,
  password,
  isNonProfit,
  role,
  phoneNumber,
  referralCode,
}) => {
  try {
    const res = await axios.post(DEV_API + "/auth/register", {
      email,
      password,
      is_non_profit: isNonProfit,
      role,
      phone: phoneNumber,
      referral_code: referralCode,
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const googleLogin = async ({ access_token, id_token, isNonProfit }) => {
  try {
    const reg = await axios.get(
      DEV_API +
        "/auth/google/callback?id_token=" +
        id_token +
        "&access_token=" +
        access_token
    );
    if (response?.data?.user?.isNonProfit !== isNonProfit) {
      throw "invalid login";
    }
    if (reg !== null) {
      token = reg?.data?.jwt;
      await AsyncStorage.clear();
      await AsyncStorage.setItem("userToken", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return reg;
  } catch (error) {
    Toast.show({
      type: "error",
      text1: "Google Login Error",
      text2: catchAPIErrorText(error),
    });
    return false;
  }
};
export const googleRegister = async ({
  access_token,
  id_token,
  isNonProfit,
}) => {
  try {
    const reg = await axios.get(
      DEV_API +
        "/auth/google/callback?id_token=" +
        id_token +
        "&access_token=" +
        access_token
    );

    if (reg !== null) {
      token = reg?.data?.jwt;
      const upd = await axios.put(
        DEV_API + "/users/" + reg?.data?.user?.id,
        {
          isNonProfit,
        },
        {
          headers: {
            Authorization: `Bearer ${reg?.data?.jwt}`,
          },
        }
      );
      await AsyncStorage.clear();
      await AsyncStorage.setItem("userToken", JSON.stringify(reg?.data?.jwt));
      const me = await axios.get(DEV_API + "/user/me?populate=picture", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return me?.data;
    }
    return reg;
  } catch (error) {
    Toast.show({
      type: "error",
      text1: "Google Callback Error",
      text2: catchAPIErrorText(error),
    });
    return error;
  }
};
export const getUserData = async (token) => {
  try {
    const res = await axios.get(DEV_API + "/users/me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    res.data.token = token;
    return res;
  } catch (error) {
    console.log("getUserData error:", error);
    if (error?.response) return error.response;
    throw error;
  }
};
export const patchUserData = async ({ token, data, id }) => {
  console.log("profile data -------------- : ", data);
  const formData = createCrossPlatformFormData({
    blobDataKeys: ["profile_image"],
    data: data,
  });
  try {
    const res = await axios.patch(DEV_API + `/users/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    Toast.show({
      type: "error",
      text1: "Update Profile Error",
      text2: catchAPIErrorText(error),
    });
    if (error?.response) {
      return error.response;
    }
    throw error;
  }
};
export const isUSerLoggedin = async () => {
  try {
    const value = await AsyncStorage.getItem("userToken");
    if (value !== null) {
      token = JSON.parse(value);
      return { token };
      // if (me.status === 200) return { token };
      //   const me = await axios.get(DEV_API + "/user/me?populate=picture", {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   });

      //   return me?.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const postForgotPassword = async ({ email }) => {
  try {
    const res = await axios.post(DEV_API + "/auth/forgot-password", {
      email,
    });
    if (res.status === 200) {
    }
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postUpdatePassword = async ({ hash, new_password }) => {
  try {
    const res = await axios.post(DEV_API + "/auth/reset-password", {
      hash,
      new_password,
    });
    if (res.status === 200) {
    }
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postLogout = async (token) => {
  try {
    const res = await axios.post(DEV_API + "/auth/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 204) {
      await AsyncStorage.removeItem("userToken");
      await AsyncStorage.clear();
    }
    return res;
  } catch (error) {
    Toast.show({
      type: "error",
      text1: "Logout Error",
      text2: catchAPIErrorText(error),
    });
    if (error?.response) return error.response;
    throw error;
  }
};
export const postVerifyEmail = async ({ hash }) => {
  try {
    const res = await axios.post(DEV_API + "/auth/verify-email", {
      hash,
    });
    res.config;
    if (res.status === 200) {
    }
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
//  -------------- user addresses -------------
export const getUserShippingAddresses = async (token) => {
  try {
    const res = await axios.get(DEV_API + "/users/me/addresses/shipping", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getUserDefaultShippingAddresses = async (token) => {
  try {
    const res = await axios.get(
      DEV_API + "/users/me/addresses/shipping/default",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getUserBillingAddresses = async (token) => {
  try {
    const res = await axios.get(DEV_API + "/users/me/addresses/billing", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postUserShippingAddresses = async ({ token, address }) => {
  try {
    const res = await axios.post(
      DEV_API + "/users/me/addresses/shipping",
      address,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 201) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postUserShippingAddressesAsDefault = async (token, addressId) => {
  try {
    const res = await axios.post(
      DEV_API + `/users/me/addresses/shipping/${addressId}/make-default`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postUserBillingAddress = async ({ token, address }) => {
  try {
    const res = await axios.post(
      DEV_API + "/users/me/addresses/billing",
      address,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 201) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const patchUserShippingAddresses = async ({
  token,
  user_id,
  address,
}) => {
  try {
    const res = await axios.patch(
      DEV_API + `/users/me/addresses/shipping/${address.id}`,
      address,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const patchUserBillingAddresses = async ({
  token,
  user_id,
  address,
}) => {
  try {
    const res = await axios.patch(
      DEV_API + `/users/me/addresses/billing/${address.id}`,
      address,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const deleteUserShippingAddresses = async (token, addressId) => {
  try {
    const res = await axios.delete(
      DEV_API + `/users/me/addresses/shipping/${addressId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

export const deleteUserBillingAddresses = async (token, addressId) => {
  try {
    const res = await axios.delete(
      DEV_API + `/users/me/addresses/billing/${addressId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 204) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// ------------------ listing --------------------
export const getListingCategories = async ({ limit, offset, searchText }) => {
  try {
    const res = await axios.get(DEV_API + "/listing-categories", {
      params: {
        limit,
        offset,
        search_text: searchText,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getServiceCategories = async ({
  limit,
  offset,
  searchText,
  listingCategoryId,
}) => {
  try {
    const res = await axios.get(DEV_API + "/service-categories", {
      params: {
        limit,
        offset,
        search_text: searchText,
        listing_category_id: listingCategoryId,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getListing = async ({
  // itemType = "",
  limit,
  offset,
  searchText,
  listingCategoryId,
  minPrice,
  maxPrice,
  sortBy,
}) => {
  try {
    const res = await axios.get(DEV_API + "/listings", {
      params: {
        limit,
        offset,
        search_text: searchText,
        listing_category_id: listingCategoryId,
        min_price: minPrice,
        max_price: maxPrice,
        sort_by: sortBy,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getListingById = async ({ id, for_booking, calendar_slot_id }) => {
  try {
    const res = await axios.get(DEV_API + `/listings/${id}`, {
      params: { for_booking, calendar_slot_id },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getReviewsById = async ({ id }) => {
  try {
    const res = await axios.get(DEV_API + `/listings/${id}/reviews`);
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getListingFeatures = async ({
  limit,
  offset,
  searchText,
  listingFeatureId,
}) => {
  try {
    const res = await axios.get(DEV_API + "/listing-features", {
      params: {
        limit,
        offset,
        search_text: searchText,
        listing_feature_id: listingFeatureId,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// ------------------ products ------------------
export const getProducts = async ({
  limit = 10,
  offset,
  searchText,
  productCategoryId,
  minPrice,
  maxPrice,
  sortBy,
}) => {
  try {
    const res = await axios.get(DEV_API + "/products/search", {
      params: {
        limit,
        offset,
        search_text: searchText,
        category_id: productCategoryId,
        min_price: minPrice,
        max_price: maxPrice,
        sort_by: sortBy,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getProductById = async ({ id }) => {
  try {
    const res = await axios.get(DEV_API + `/products/${id}`);
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getProductCategories = async ({ limit, offset, searchText }) => {
  try {
    const res = await axios.get(DEV_API + "/product-categories", {
      params: {
        limit,
        offset,
        search_text: searchText,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
// ------------------ product bookmark --------------------
export const getProductsBookmark = async (token) => {
  try {
    const res = await axios.get(DEV_API + "/product/bookmarks", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const makeShippingAddressesToBilling = async (token, addressId) => {
  try {
    const res = await axios.post(
      DEV_API + `/users/me/addresses/shipping/${addressId}/set-as-billing`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postProductBookmark = async ({ id, token }) => {
  try {
    const res = await axios.post(
      DEV_API + "/product/bookmarks",
      {
        product_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const deleteProductBookmark = async ({ bookmark_id, token }) => {
  try {
    const res = await axios.delete(
      DEV_API + `/product/bookmarks/${bookmark_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
// ------------------ product cart --------------------
export const getProductCartItems = async (token) => {
  try {
    const res = await axios.get(DEV_API + "/product-cart/items", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postProductCartItems = async ({
  productId,
  varientId,
  token,
  quantity,
}) => {
  try {
    const res = await axios.post(
      DEV_API + "/product-cart/items",
      {
        product_id: productId,
        variant_id: varientId,
        quantity: quantity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postClearProductCartItems = async ({ token }) => {
  try {
    const res = await axios.post(
      DEV_API + "/product-cart/clear",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postProductCartItemsIncrement = async ({
  item_id,
  token,
  quantity,
}) => {
  try {
    const res = await axios.post(
      DEV_API + `/product-cart/items/${item_id}/quantity/increment`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          increment_by: quantity,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postProductCartItemsDecrement = async ({ item_id, token }) => {
  try {
    const res = await axios.post(
      DEV_API + `/product-cart/items/${item_id}/quantity/decrement`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const deleteProductCartItems = async ({ item_id, token }) => {
  try {
    const res = await axios.delete(DEV_API + `/product-cart/items/${item_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// ------------------ listing bookmark --------------------
export const getBookmark = async ({ token, offset, limit }) => {
  try {
    const res = await axios.get(DEV_API + "/listing/bookmarks", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        offset,
        limit,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postBookmark = async ({ id, token }) => {
  try {
    const res = await axios.post(
      DEV_API + "/listing/bookmarks",
      {
        listing_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const deleteBookmark = async ({ bookmark_id, token }) => {
  try {
    const res = await axios.delete(
      DEV_API + `/listing/bookmarks/${bookmark_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// ------------------ media --------------------
export const getMedia = async (url, token) => {
  try {
    const res = await axios.get(DEV_API + `/media/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// ------------------ booking --------------------
export const getServiceCalendar = async (listing_id, service_id) => {
  try {
    const res = await axios.get(
      DEV_API + `/listings/${listing_id}/services/${service_id}/calendar`
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getBookings = async ({ token, offset, limit, filter }) => {
  let params = {
    offset,
    limit,
  };
  if (filter !== "all") {
    params.status = filter;
  }
  try {
    const res = await axios.get(DEV_API + "/bookings/service", {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });

    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postBooking = async ({ token, data }) => {
  try {
    const res = await axios.post(DEV_API + "/bookings/service/create", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postCancelBooking = async ({ token, booking_id }) => {
  try {
    const res = await axios.post(
      DEV_API + `/bookings/service/${booking_id}/cancel`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postRescheduleBooking = async ({
  token,
  booking_id,
  session_id,
  calendar_slot_id,
}) => {
  try {
    const res = await axios.post(
      DEV_API +
        `/bookings/service/${booking_id}/sessions/${session_id}/reschedule`,
      { calendar_slot_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const getBookingById = async ({ token, booking_id }) => {
  try {
    const res = await axios.get(DEV_API + `/bookings/service/${booking_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

export const getGroupDetails = async (group_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + `/groups/${group_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getAPost = async (group_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + `/groups/${group_id}/posts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getStats = async (token) => {
  try {
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + "/stats", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get stats error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getRecentActivites = async (token) => {
  try {
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + "/activities", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log("get activities error : ", error);
    if (error?.response) {
      console.log("error.response.status:", error.response.status);
      if (error.response.status === 401) await AsyncStorage.removeItem("ut");
      return error.response;
    } else {
      throw error;
    }
  }
};

// get group members

export const getGroupMembers = async (group_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + `/groups/${group_id}/members`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// get non group members to add

export const getGroupNonMembers = async (group_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(
      DEV_API + `/groups/${group_id}/non-member-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          group_joining_preference: "no_restriction",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getGroupNonMembersInvites = async (
  group_id,
  token_data,
  search_text
) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(
      DEV_API + `/groups/${group_id}/non-member-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { search_text },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// send invite via email

export const sendInviteToEmail = async (email, token_data) => {
  try {
    console.log(email, "email");
    const token = token_data;
    console.log(token, "token");

    if (!token) {
      return null;
    }
    const res = await axios.post(
      DEV_API + `/invites`,
      {
        contacts: [
          {
            email: email,
          },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    console.log(res, "failed res check");
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// get join requests

export const GetGroupJoinRequest = async (group_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(DEV_API + `/groups/${group_id}/join/requests`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// accept reject api

export const RejectRequest = async (group_id, request_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(
      DEV_API + `/groups/${group_id}/join/requests/${request_id}/reject`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
export const AcceptRequest = async (group_id, request_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.get(
      DEV_API + `/groups/${group_id}/join/requests/${request_id}/accept`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// send invite

export const sendInvite = async (group_id, arrId, token_data) => {
  try {
    console.log(arrId, "sendInvite");
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.post(
      DEV_API + `/groups/${group_id}/invites/send-invitation`, //for invitation
      // DEV_API + `/groups/${group_id}/members`, // for direct add
      // apiURIV2 + `/groups/${group_id}/members`,
      { user_ids: arrId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const addGroupMember = async (group_id, arrId, token_data) => {
  try {
    console.log(arrId, "sendInvite");
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.post(
      DEV_API + `/groups/${group_id}/members`,
      { user_ids: arrId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// Remove member api

export const RemoveMemberApi = async (group_id, member_id, token) => {
  try {
    const res = await axios.delete(
      DEV_API + `/groups/${group_id}/members/${member_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// share post api

export const ShareAPost = async (group_id, post_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.post(
      DEV_API + `/groups/${group_id}/posts/${post_id}/share`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      console.log("error:", error);
      throw error;
    }
  }
};

// like dislike post

export const LikePostApi = async (group_id, post_id, isLiked, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.post(
      DEV_API +
        `/groups/${group_id}/posts/${post_id}` +
        (isLiked ? `/unlike` : `/like`),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const editPostApi = async (group_id, post_id, formData, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axiosPatch({
      Url: `/groups/${group_id}/posts/${post_id}`,
      data: formData,
      token,
      isFormData: true,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

//  delete post

export const deleteAPost = async (group_id, post_id, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    const res = await axios.delete(
      DEV_API + `/groups/${group_id}/posts/${post_id}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (res.status === 200) {
      return res;
    }
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const deletePostAssetsWhileEditing = async ({
  group_id,
  post_id,
  token,
  deletionAssetEndPoint,
}) => {
  try {
    if (!token) {
      return null;
    }
    const res = await axiosDelete({
      Url: `/groups/${group_id}/posts/${post_id}/${deletionAssetEndPoint}`,
      token,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// ---------------- other ----------------
export const getPublicData = async (url) => {
  try {
    const data = await axios.get(DEV_API + url);
    return data;
  } catch (error) {
    return false;
  }
};

// ------------------ orders --------------------

export const getOrders = async ({ token, filter, offset, limit }) => {
  let params = {
    offset,
    limit,
  };
  if (filter !== "all") {
    params.status = filter;
  }
  try {
    const res = await axios.get(DEV_API + "/orders", {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });

    return res;
  } catch (error) {
    return error.response;
  }
  // } else {
  //   try {
  //     const res = await axios.get(DEV_API + "/orders", {
  //       headers: { Authorization: `Bearer ${token}` },
  //       params: {
  //         status: filter,
  //       },
  //     });

  //     return res;
  //   } catch (error) {
  //     return error.response;
  //   }
  // }
};
export const getOrderById = async (token, orderId) => {
  try {
    const res = await axios.get(DEV_API + `/orders/${orderId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

export const postOrder = async ({ token, data }) => {
  try {
    const res = await axios.post(DEV_API + "/orders/create", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postCancelOrder = async ({ token, productOrderId }) => {
  try {
    const res = await axios.post(
      DEV_API + `/orders/product/${productOrderId}/cancel`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// leave group

export const LeaveGroupApi = async (token, group_id) => {
  try {
    const res = await axios.get(DEV_API + `/groups/${group_id}/leave`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
//  group invitations

export const GroupInvitationApi = async (token, user_id) => {
  try {
    const res = await axios.get(
      DEV_API + `/users/${user_id}/groups/invitations`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
//  group invitations

export const AcceptOrDeclineInvitationApi = async (
  token,
  invite_id,
  group_id,
  AcceptOrDecline
) => {
  try {
    const res = await axios.get(
      DEV_API + `/groups/${group_id}/invites/${invite_id}/${AcceptOrDecline}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
// Edit Group

export const EditGroup = async ({ token, formData, group_id }) => {
  try {
    const res = await axios.patch(DEV_API + `/groups/${group_id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    console.log(error, "error in edit group");
    Toast.show({
      type: "error",
      text1: "Update Group Error",
      text2: catchAPIErrorText(error),
    });
    if (error?.response) {
      return error.response;
    }
    throw error;
  }
};
// Get Groups

export const GetGroupsApi = async ({ token, id }) => {
  try {
    const res = await axios.get(DEV_API + `/users/${id}/groups`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      return res;
    }
  } catch (error) {
    console.log(error, "error for get groups");
    Toast.show({
      type: "error",
      text1: "for get groups Error",
      text2: catchAPIErrorText(error),
    });
    if (error?.response) {
      return error.response;
    }
    throw error;
  }
};

// create group post

export const createAPost = async (group_id, formData, token_data) => {
  try {
    const token = token_data;
    if (!token) {
      return null;
    }
    return axios.post(DEV_API + `/groups/${group_id}/posts`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // return res;
  } catch (error) {
    console.log(error, "error in auth for crete post");
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};

// ------------------- Notifications -----------------------
export const postNotificationsMarkRead = async ({ notification_id }) => {
  try {
    const res = await axios.post(
      DEV_API + `/v2/notifications/${notification_id}/mark-read`
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};
export const postNotificationsMarkUnread = async ({ notification_id }) => {
  try {
    const res = await axios.post(
      DEV_API + `/v2/notifications/${notification_id}/mark-unread`
    );
    return res;
  } catch (error) {
    if (error?.response) return error.response;
    throw error;
  }
};

// --------------------- Stripe -----------------------
export const validateStripeStatus = async ({ token }) => {
  try {
    if (!token) {
      return;
    }
    const res = await axios.post(
      DEV_API + "/stripe/onboard/check",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res && res.status === 200 && res.data) {
      return res;
    } else throw res;
  } catch (error) {
    console.log("Error in Initiate stripe", error.response);
    if (error?.response) {
      return error?.response;
    }
  }
};

export const initiateStripe = async ({ token }) => {
  try {
    return axiosPost({
      Url: "/stripe/onboard/initiate",
      data: {
        platform: Platform.OS, // ios or android or web
      },
      token: token,
    });
  } catch (error) {
    console.log("initiateStripe   =>>>", error);
    if (error?.response) {
      return error.response;
    }
  }
};

// --------------------- Groups ----------------------
export const getRecommendedGroups = async ({
  token,
  user_id,
  searchText,
  offset,
  limit,
}) => {
  try {
    const res = await axiosGet({
      Url: `/users/${user_id}/groups/recommendations`,
      params: { search_text: searchText, offset, limit },
      token,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    }
    console.log("get recommended groups error:", error);
    throw error;
  }
};

export const previewGroupPosts = async ({ token, group_id, offset, limit }) => {
  try {
    const res = await axiosGet({
      Url: `/groups/${group_id}/posts`,
      params: { offset, limit },
      token,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    }
    console.log("preview group posts error:", error);
    throw error;
  }
};

export const joinOrRequestGroup = async ({ token, group_id }) => {
  try {
    const res = await axiosGet({
      Url: `/groups/${group_id}/join`,
      token,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    }
    console.log("join or request group error:", error);
    throw error;
  }
};

export const likeGroupPost = async ({ group_id, post_id, is_liked, token }) => {
  try {
    if (!token) {
      return null;
    }
    const res = await axiosPost({
      Url:
        `/groups/${group_id}/posts/${post_id}` +
        (is_liked ? `/unlike` : `/like`),
      data: {},
      token: token,
    });
    return res;
  } catch (error) {
    if (error?.response) {
      return error.response;
    } else {
      throw error;
    }
  }
};
