import React, { useEffect, useState } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  Text,
  View,
  ActivityIndicator,
  Share,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Widget } from "../../components/widget";
import { Button, Input, Select, Switcher } from "../../components/input";
import { Unautorized } from "./unautorized";
import { DEV_API, axiosDelete, axiosGet, axiosPost } from "../../services/api";
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { BusinessesContext } from "../../context/businessesContext";
import Toast from "react-native-toast-message";
import { getFullDate, getFullDateTime } from "../../../utils/date-time-util";
import { UserContext } from "../../context/userContext";
import { TouchableOpacity } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { mediaURI } from "../../../constants";

export const Vendor = ({ navigation, route }) => {
  const { user, userToken } = useContext(AuthContext);
  const { userData } = useContext(UserContext);
  const {
    businessDetails,
    isBusinessLoading,
    setIsBusinessLoading,
    setBusinessDetails,
    setBusinessApiError,
  } = useContext(BusinessesContext);
  const showToast = (type, line1, line2) => {
    Toast.show({
      type: type,
      text1: line1,
      text2: line2,
    });
  };
  const options = [
    { label: "Wall", value: "Wall" },
    { label: "Reviews", value: "Reviews" },
    { label: "Listings", value: "Listings" },
    { label: "Products", value: "Products" },
  ];
  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Achievement", value: "achievement" },
    { label: "Activity", value: "activity" },
    { label: "Announcement", value: "announcement" },
    { label: "Feature", value: "feature" },
  ];

  // ---------------------------------- vendor wall data  ------------------------------------
  const [wallData, setWallData] = useState({
    posts: [],
    postsPagination: {
      from: null,
      to: null,
      total_posts: null,
    },
  });
  const [tab, setTab] = useState(options[0].value);
  const [filter, setFilter] = useState(filterOptions[0]);
  const [isWallDataLoading, setIsWallDataLoading] = useState(false);

  // ---------------------------------- posts ------------------------------------
  const postsPerPage = 5;
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [isPostFilterLoading, setIsPostFilterLoading] = useState(false);

  // ---------------------------------- reviews ------------------------------------

  const reviewsPerPage = 5;
  const [vendorReviews, setVendorReviews] = useState({
    reviews: [],
    userReview: null,
    pagination: {
      from: null,
      to: null,
      total_records: null,
    },
  });
  const [review, setReview] = useState({
    message: "",
    rating: 0,
  });
  const [replyToReviewInput, setReplyToReviewInput] = useState("");
  const [showReviewId, setShowReviewId] = useState({
    id: null,
    showReplies: false,
    showAddReplyToReview: false,
  });
  const [isSendReviewInProgress, setIsSendReviewInProgress] = useState({
    addReview: false,
    replyToReview: false,
  });
  const [isDeleteReviewInProgress, setIsDeleteReviewInProgress] =
    useState(false);

  // ---------------------------------- services ------------------------------------
  const vendorListingsPerPage = 10;
  const [vendorListings, setVendorListings] = useState({
    listings: [],
    pagination: { to: null, total: 10 },
  });
  const [isVendorListingsLoading, setIsVendorListingsLoading] = useState(false);

  // ---------------------------------- products ------------------------------------
  const vendorProductsPerPage = 10;
  const [vendorProducts, setVendorProducts] = useState({
    products: [],
    pagination: { to: null, total: 10 },
  });
  const [isVendorProductsLoading, setIsVendorProductsLoading] = useState(false);

  // ----------------------------------- functions ----------------------------
  const [prevFilterValue, setPrevFilterValue] = useState("");
  const getVendorPosts = async ({
    Url,
    isVendorWallPostsApi = false,
    type = "",
  }) => {
    if (isVendorWallPostsApi) {
      setIsWallDataLoading(false);
      setIsPostsLoading(true);
    } else {
      setIsWallDataLoading(true);
      setIsPostsLoading(false);
    }
    let params = {};
    if (isVendorWallPostsApi) {
      params = {
        offset:
          prevFilterValue !== type
            ? 0
            : wallData.postsPagination.to === null
            ? 0
            : wallData.postsPagination.to + 1,
        limit:
          prevFilterValue !== type
            ? postsPerPage
            : !wallData.postsPagination.total_posts
            ? postsPerPage
            : wallData.postsPagination.to + postsPerPage <=
              wallData.postsPagination.total_posts
            ? postsPerPage
            : wallData.postsPagination.total_posts,
      };
    }
    if (type !== "") {
      params.type = type;
    }
    setPrevFilterValue(type);
    await axiosGet({
      Url,
      token: userToken,
      params,
      // isQueryParams: type === "" ? false : true,
      // queryParams: { type: type },
    })
      .then((data) => {
        if (isVendorWallPostsApi) {
          if (prevFilterValue !== type) {
            setWallData((e) => ({
              ...e,
              posts: [...data.data.posts],
              postsPagination: {
                total_posts: data.data.total_count,
                // from: 0,
                to: data.data.posts.length,
              },
            }));
          } else {
            setWallData((e) => ({
              ...e,
              posts: [...e.posts, ...data.data.posts],
              postsPagination: {
                total_posts: data.data.total_count,
                // from: 0,
                to: e.posts.length + data.data.posts.length,
              },
            }));
          }
        } else {
          setWallData((e) => ({
            ...data.data,
            postsPagination: {
              total_posts: data.data.total_posts_count,
              // from: 0,
              to: data.data.posts.length,
            },
          }));
          setVendorReviews({
            reviews: data.data.reviews,
            userReview: data.data.user_review ?? null,
            pagination: {
              from: 0,
              to: data.data.reviews?.length,
              total_records: data.data.total_reviews_count,
            },
          });
        }
      })
      .catch((error) => {
        setIsWallDataLoading(false);
        setIsPostFilterLoading(false);
        console.log("vendor post error : ", error);
        showToast("error", "Error :", error);
      })
      .finally(() => {
        setIsWallDataLoading(false);
        setIsPostsLoading(false);
        setIsPostFilterLoading(false);
      });
  };

  const getVendorListings = async () => {
    setIsVendorListingsLoading(true);
    const Url = `/listings?vendor_id=${route?.params?.id}`;
    const params = {
      offset:
        vendorListings.pagination.to === null
          ? 0
          : vendorListings.pagination.to + 1,
      limit:
        vendorListings.pagination.to + vendorListingsPerPage <=
        vendorListings.pagination.total
          ? vendorListingsPerPage
          : vendorListings.pagination.total,
    };

    await axiosGet({
      Url,
      token: userToken,
      params,
    })
      .then((data) => {
        setVendorListings({
          listings: data.data.data,
          pagination: {
            to: data.data.data.length,
            total: data.data.total_count,
          },
        });
      })
      .catch((error) => {
        setIsVendorListingsLoading(false);
        console.log("vendor, getVendorListings error: ", error.status);
        showToast(
          "error",
          "Error :",
          error.status === 502 ? "Something went wrong on our side." : "Error"
        );
      })
      .finally(() => {
        setIsVendorListingsLoading(false);
      });
  };
  const getVendorProducts = async () => {
    setIsVendorProductsLoading(true);
    const Url = `/products/search?vendor_id=${route?.params?.id}`;
    const params = {
      offset:
        vendorProducts.pagination.to === null
          ? 0
          : vendorProducts.pagination.to + 1,
      limit:
        vendorProducts.pagination.to + vendorProductsPerPage <=
        vendorProducts.pagination.total
          ? vendorProductsPerPage
          : vendorProducts.pagination.total,
    };

    await axiosGet({
      Url,
      token: userToken,
      params,
    })
      .then((data) => {
        setVendorProducts({
          products: data.data.data,
          pagination: {
            to: data.data.data.length,
            total: data.data.total_count,
          },
        });
      })
      .catch((error) => {
        setIsVendorProductsLoading(false);
        console.log("vendor, getVendorProducts error: ", error);
        showToast("error", "Error :", error);
      })
      .finally(() => {
        setIsVendorProductsLoading(false);
      });
  };
  // useEffect(() => {
  //   getVendorPosts({ Url: `/users/${route?.params?.id}/wall` });
  // }, []);
  useEffect(() => {
    setVendorListings({
      listings: [],
      pagination: { to: null, total: 10 },
    });
    setVendorProducts({
      products: [],
      pagination: { to: null, total: 10 },
    });
    if (tab === "Products") {
      getVendorProducts();
    } else if (tab === "Listings") {
      getVendorListings();
    }
  }, [tab]);
  useEffect(() => {
    if (tab === "Wall") {
      if (filter.value === "all") {
        getVendorPosts({ Url: `/users/${route?.params?.id}/wall` });
      } else {
        setIsPostFilterLoading(true);
        setWallData((e) => ({
          ...e,
          posts: [],
          postsPagination: {
            from: null,
            to: null,
            total_posts: null,
          },
        }));
        getVendorPosts({
          Url: `/users/${route?.params?.id}/posts`,
          isVendorWallPostsApi: true,
          type: filter.value === "all" ? "" : filter.value,
        });
      }
    }
  }, [filter]);

  const onLikeAPost = async ({ id, type }) => {
    await axiosPost({
      Url: `/users/${route?.params?.id}/posts/${id}/${type}`,
      token: userToken,
      data: {},
    })
      .then((data) => {
        const index = wallData?.posts?.findIndex((e) => e.id === id);
        if (index > -1) {
          let post = [...wallData?.posts];
          post[index].is_liked = type === "like" ? true : false;
          post[index].likes =
            type === "like" ? post[index].likes + 1 : post[index].likes - 1;
          setWallData((e) => ({ ...e, posts: post }));
        }
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("vendor, onLikeAPost error: ", err);
      })
      .finally(() => {});
  };
  const url = `https://smbexcustomer.wisdomtooth.tech/vendor?id=${route?.params?.id}`;
  const onShare = async (image_url) => {
    const imageUrl = `${DEV_API}/media/${image_url}`;
    try {
      const result = await Share.share({
        message:
          `SMBEx Vendor - ${`${wallData?.user?.first_name} ${wallData?.user?.last_name}`} | A marketplace application's vendor profile` +
          "\n" +
          url,
        // url: imageUrl,
      });
      if (result?.action === Share.sharedAction) {
        if (result?.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const onLoadMorePosts = async ({ type }) => {
    getVendorPosts({
      Url: `/users/${route?.params?.id}/posts`,
      isVendorWallPostsApi: true,
      type,
    });
  };
  const getRatingStars = ({ rating }) => {
    const rating1 = [...Array(Math.floor(rating)).keys()];
    const isHalfStar = rating % 1 === 0.5;
    return (
      <>
        {rating1.map((e, i) => (
          <RemixIcon
            name="star-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
            key={`icon_${i}`}
          ></RemixIcon>
        ))}
        {isHalfStar && (
          <RemixIcon
            name="star-half-fill"
            size={16}
            color={colors.app.i}
            className="flex-shrink-0"
          ></RemixIcon>
        )}
      </>
    );
  };
  const onLoadMoreReviews = async () => {
    // getVendorPosts({
    //   Url: `/users/${route?.params?.id}/posts`,
    //   isVendorWallPostsApi: true,
    // });
  };
  const submitReviewHandler = async () => {
    setIsSendReviewInProgress((e) => ({ ...e, addReview: true }));
    if (review.message !== "") {
      await axiosPost({
        Url: `/users/${route?.params?.id}/reviews/`,
        token: userToken,
        data: review,
      })
        .then((data) => {
          setReview({
            message: "",
            rating: 0,
          });
          const array = [...vendorReviews?.reviews];
          const reviewData = {
            ...data.data.review,
          };
          array.unshift(reviewData);
          setVendorReviews((e) => ({
            ...e,
            reviews: array,
            userReview: reviewData,
          }));
          showToast("success", "Success", "Successful.");
        })
        .catch((error) => {
          console.log("vendor, submitReviewHandler error : ", error);
        })
        .finally(() => {
          setIsSendReviewInProgress((e) => ({ ...e, addReview: false }));
        });
    }
    setIsSendReviewInProgress((e) => ({ ...e, addReview: false }));
  };

  const onSendReplyToAReview = async (reviewId) => {
    if (replyToReviewInput !== "") {
      await axiosPost({
        Url: `/users/${route?.params?.id}/reviews/${reviewId}/replies`,
        token: userToken,
        data: { message: replyToReviewInput },
      })
        //console.log("route?.params?.id" ,route?.params?.id)
        .then((data) => {
          setReplyToReviewInput("");
          const array = [...vendorReviews?.reviews];
          const index = array.findIndex((e) => e.id === reviewId);
          if (index > -1) {
            array[index].replies.unshift(data.data.data);
          }
          setVendorReviews((e) => ({ ...e, reviews: array }));
          showToast("success", "Success", "Successful.");
        })
        .catch((error) => {
          console.log("vendor, onSendReplyToAReview error : ", error);
        })
        .finally(() => {});
    }
  };
  const onDeleteReview = async (review_id) => {
    setIsDeleteReviewInProgress(true);
    await axiosDelete({
      Url: `/users/${route?.params?.id}/reviews/${review_id}`,
      token: userToken,
    })
      .then((data) => {
        const updatedData = { ...vendorReviews };
        delete updatedData.userReview;
        setVendorReviews({
          ...updatedData,
          reviews: [
            ...updatedData.reviews.filter((e, i) => e.id !== review_id),
          ],
        });
        showToast("success", "Success", "Successful.");
      })
      .catch((err) => {
        showToast("error", "Error", "Something went wrong.");
        console.log("vendor, onDeleteReview error: ", err);
      })
      .finally(() => {
        setIsDeleteReviewInProgress(false);
      });
  };
  // const onLikeAReview = async ({ id, type }) => {
  //   await axiosPost({
  //     Url: `/users/${route?.params?.vendor_id}/reviews/${id}/${type}`,
  //     token: userToken,
  //     data: {},
  //   })
  //     .then((data) => {
  //       const index = comments?.findIndex((e) => e.id === id);
  //       if (index > -1) {
  //         let post = [...comments];
  //         post[index].is_liked = type === "like" ? true : false;
  //         post[index].likes =
  //           type === "like" ? post[index].likes + 1 : post[index].likes - 1;
  //         setComments(post);
  //         showToast("success", "Success", "Success.");
  //       }
  //     })
  //     .catch((err) => {
  //       showToast("error", "Error", "Something went wrong.");
  //       console.log("comments, onLikeAComment error: ", err);
  //     })
  //     .finally(() => {});
  // };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="rounded-t-2xl lg:rounded-none overflow-hidden flex-1 bg-gray-50">
        <ScrollView
          contentContainerStyle={{
            // flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View className="flex-col lg:flex-row flex-wrap w-full max-w-screen-xl flex items-center lg:justify-center lg:items-start p-4">
            <View className="flex-col flex flex-wrap w-full lg:w-9/12 pr-2">
              <View className="w-full p-0 flex-row justify-center">
                <Card className="flex-1 w-full">
                  <View className="flex-col w-full px-2">
                    {!isWallDataLoading && wallData?.user ? (
                      <>
                        <View className="w-full flex-row pb-2 items-center">
                          <View className="rounded-full w-12 h-12 overflow-hidden lg:ml-4 bg-app-c1">
                            <Image
                              source={
                                wallData?.user?.profile_image_url
                                  ? {
                                      uri:
                                        mediaURI +
                                        wallData?.user?.profile_image_url,
                                    }
                                  : require("../../../assets/user1.jpg")
                              }
                              className="w-full h-full"
                            ></Image>
                          </View>
                          <View className="justify-center ml-3">
                            <Font className="font-bold text-[16px] mb-1">
                              {wallData?.user?.first_name ||
                              wallData?.user?.last_name
                                ? wallData?.user?.first_name +
                                  " " +
                                  wallData?.user?.last_name
                                : "Unknown Name"}
                            </Font>
                            {wallData?.user?.email ? (
                              <Font className="text-[12px] text-app-d1">
                                {wallData?.user?.email}
                              </Font>
                            ) : (
                              <></>
                            )}
                            {wallData?.user?.phone ? (
                              <Font className="text-[12px] text-app-d1">
                                {wallData?.user?.phone}
                              </Font>
                            ) : (
                              <></>
                            )}
                            {wallData?.user?.address ? (
                              <Font className="text-[12px] text-app-d1">
                                {wallData?.user?.address}
                              </Font>
                            ) : (
                              <></>
                            )}
                          </View>
                        </View>
                        {wallData?.user?.about ? (
                          <View className="w-full justify-center px-2 pt-2 flex-col border-t border-app-c">
                            <View className="flex-row items-center">
                              <Font
                                className=" text-app-d"
                                numberOfLines={1}
                                weight={700}
                              >
                                About Vendor :
                              </Font>
                            </View>
                            <View className="flex-row items-center">
                              <Font
                                className="text-app-d"
                                numberOfLines={5}
                                weight={500}
                              >
                                {wallData?.user?.about}
                              </Font>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : !isWallDataLoading && !wallData?.user ? (
                      <View className="w-full flex-row justify-center items-center">
                        <Font className="text-app-d1">No details found.</Font>
                      </View>
                    ) : (
                      <View className="w-full justify-center flex items-center">
                        <ActivityIndicator
                          size={"small"}
                          color={colors.app.e}
                        ></ActivityIndicator>
                      </View>
                    )}
                  </View>
                </Card>
              </View>
              <View className="mb-4 w-full flex-col lg:flex-row lg:justify-between items-center">
                <View className="w-full flex flex-row lg:w-2/3">
                  <Switcher
                    options={options}
                    mode="simple"
                    value={tab}
                    setValue={(e) => {
                      {
                        setTab(e);
                      }
                    }}
                  ></Switcher>
                </View>
                {tab === "Wall" && (
                  <View className="flex w-auto lg:w-40 mt-2 lg:m-0 flex-row">
                    <Select
                      options={filterOptions}
                      className="w-full"
                      placeholder="filter"
                      mode="simple"
                      height="h-10"
                      value={filter}
                      setValue={(e) => {
                        setFilter(e);
                      }}
                    ></Select>
                  </View>
                )}
              </View>
              <View className="w-full flex-col">
                {tab === "Wall" ? (
                  <View className="flex-col justify-start items-center w-full">
                    {wallData?.posts?.length > 0 && !isWallDataLoading ? (
                      wallData?.posts?.map((e, i) => (
                        <View
                          className="flex-1 w-full lg:max-w-xl xl:max-w-lg"
                          key={`posts_${i}`}
                        >
                          <Card
                            className="w-full flex-1 "
                            // title="Company Page"
                            // Widget={() => (
                            //   <View className="w-1/3">
                            //     <Select
                            //       options={filterOptions}
                            //       placeholder="filter"
                            //       height="h-6 lg:h-8"
                            //       mode="simple"
                            //       value={filter?.value}
                            //       setValue={(e) => {
                            //         setFilter(e);
                            //       }}
                            //     ></Select>
                            //   </View>
                            // )}
                          >
                            <View className={`w-full flex-1 px-3 py-0`}>
                              <View className="w-full flex-1 pb-2">
                                {e?.cover_image_url ? (
                                  <View className="flex-row  justify-center overflow-hidden rounded-lg pb-4">
                                    <Image
                                      resizeMode="contain"
                                      source={
                                        e.cover_image_url &&
                                        e.cover_image_url !== ""
                                          ? {
                                              uri: `${DEV_API}/media/${e.cover_image_url}`,
                                            }
                                          : require("../../../assets/bg.jpg")
                                      }
                                      className="w-full h-60 lg:w-72 lg:h-72 rounded-lg"
                                    ></Image>
                                  </View>
                                ) : (
                                  <></>
                                )}
                                <Pressable className="justify-center p-2 flex-1">
                                  <View className="flex-row items-center pb-1">
                                    {/* <Font
                                        className=" text-app-d"
                                        weight={600}
                                        numberOfLines={1}
                                      >
                                        Title : 
                                      </Font> */}
                                    {e?.title ? (
                                      <Font
                                        className="text-app-d text-base"
                                        numberOfLines={5}
                                        weight={600}
                                      >
                                        {e.title}
                                      </Font>
                                    ) : (
                                      <></>
                                    )}
                                    <Font
                                      className="text-app-d1 pl-4"
                                      numberOfLines={5}
                                      weight={500}
                                    >
                                      {getFullDate(e.updated_at)}
                                    </Font>
                                  </View>
                                  {e?.description ? (
                                    <View className="flex-row items-center border-t border-app-c pt-2">
                                      <Font
                                        className="text-app-d leading-4 "
                                        numberOfLines={5}
                                        weight={600}
                                      >
                                        {e.description}
                                      </Font>
                                    </View>
                                  ) : (
                                    <></>
                                  )}
                                </Pressable>
                              </View>
                              <View className="justify-between items-center flex-row pt-3 border-t border-app-c">
                                <View className=" w-1/5 justify-center items-center">
                                  <TouchableOpacity
                                    onPress={() =>
                                      onLikeAPost({
                                        id: e.id,
                                        type: e.is_liked ? "unlike" : "like",
                                      })
                                    }
                                  >
                                    <RemixIcon
                                      name={
                                        e.is_liked
                                          ? "ri-thumb-up-fill"
                                          : "ri-thumb-up-line"
                                      }
                                      size={18}
                                      color={
                                        e.is_liked
                                          ? colors.app.f
                                          : colors.app.d1
                                      }
                                    />
                                  </TouchableOpacity>
                                  <Font className="text-xs pt-1 text-app-d1">
                                    {e.likes}
                                  </Font>
                                </View>
                                <View className=" w-1/5 justify-center items-center">
                                  <TouchableOpacity
                                    onPress={() => {
                                      navigation.navigate("comments", {
                                        vendor_id: route?.params?.id,
                                        post_id: e.id,
                                      });
                                    }}
                                  >
                                    <RemixIcon
                                      name="ri-message-2-line"
                                      size={18}
                                      color={colors.app.d1}
                                    />
                                  </TouchableOpacity>
                                  <Font className="text-xs pt-1 text-app-d1">
                                    {e.comments ?? 50}
                                  </Font>
                                </View>
                                <View className=" w-1/5 justify-center items-center">
                                  <TouchableOpacity
                                    onPress={() => onShare(e.cover_image_url)}
                                  >
                                    <RemixIcon
                                      name="ri-share-forward-line"
                                      size={18}
                                      color={colors.app.d1}
                                    />
                                  </TouchableOpacity>
                                  <Font className="text-xs pt-1 text-app-d1">
                                    {e.shares ?? 50}
                                  </Font>
                                </View>
                              </View>
                            </View>
                          </Card>
                        </View>
                      ))
                    ) : wallData?.posts?.length === 0 &&
                      !isWallDataLoading &&
                      !isPostFilterLoading ? (
                      <View className="flex-1 w-full">
                        <Card className="w-full flex-1 ">
                          <View className="w-full h-60 flex-row justify-center items-center">
                            <Font className="text-app-d1">No posts found.</Font>
                          </View>
                        </Card>
                      </View>
                    ) : (
                      (isWallDataLoading || isPostFilterLoading) && (
                        <View className="flex-1 w-full">
                          <Card className="w-full flex-1 ">
                            <View className="w-full justify-center h-60">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.e}
                              ></ActivityIndicator>
                            </View>
                          </Card>
                        </View>
                      )
                    )}
                    {wallData?.postsPagination?.total_posts > postsPerPage &&
                      (wallData?.postsPagination?.to > 5
                        ? wallData?.postsPagination?.to <=
                          wallData?.postsPagination?.total_posts - postsPerPage
                        : true) && (
                        <View className="w-full flex-row justify-center">
                          {isPostsLoading ? (
                            <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.a}
                              ></ActivityIndicator>
                            </Pressable>
                          ) : (
                            <Pressable
                              onPress={() => {
                                if (
                                  filter.value !== "" &&
                                  filter.value !== "all"
                                ) {
                                  onLoadMorePosts({
                                    type: filter.value,
                                  });
                                } else {
                                  onLoadMorePosts({
                                    type: "",
                                  });
                                }
                              }}
                              className="bg-app-e w-32 py-2 rounded-lg mb-4"
                            >
                              <Font className="text-app-a text-center">
                                Load More
                              </Font>
                            </Pressable>
                          )}
                        </View>
                      )}
                  </View>
                ) : tab === "Reviews" ? (
                  <>
                    {vendorReviews?.userReview && (
                      <Card
                        title={`Your review`}
                        Widget={() => (
                          <Pressable
                            className="px-2 py-1 bg-app-e3 rounded-md"
                            onPress={() => {
                              onDeleteReview(vendorReviews?.userReview?.id);
                            }}
                          >
                            {/* <Font className="text-xs">Delete review</Font> */}
                            {isDeleteReviewInProgress ? (
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.e}
                              ></ActivityIndicator>
                            ) : (
                              <AntDesign
                                name="delete"
                                size={20}
                                color={colors.app.e}
                              />
                            )}
                          </Pressable>
                        )}
                        className="flex-1 w-full"
                      >
                        <ScrollView className="flex-1 w-full p-4 py-0">
                          <View className="w-full flex-row justify-between items-center bg-app-b rounded-lg">
                            <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                              <Image
                                // resizeMode="contain"
                                source={
                                  vendorReviews.userReview
                                    .user_profile_image_url &&
                                  vendorReviews.userReview
                                    .user_profile_image_url !== ""
                                    ? {
                                        uri: `${DEV_API}/media/${vendorReviews.userReview.user_profile_image_url}`,
                                      }
                                    : require("../../../assets/user1.jpg")
                                }
                                className="w-10 h-10 rounded-full"
                              ></Image>
                            </View>
                            <View className="flex-1 flex-col rounded-md bg-app-c2">
                              <View className="w-full flex-row px-3 pt-1">
                                <Font className="py-1 font-bold">
                                  {vendorReviews.userReview.user_name}
                                </Font>
                              </View>
                              <View className="w-full pt-1 px-3">
                                <Font className="text-xs text-app-d1">
                                  {getFullDateTime(
                                    vendorReviews.userReview.created_at
                                  )}
                                </Font>
                              </View>

                              <View className="w-full flex-row py-2 px-3">
                                {getRatingStars({
                                  rating: vendorReviews.userReview.rating,
                                })}
                              </View>
                              <View className="w-full px-3 pb-3">
                                <Font>{vendorReviews.userReview.message}</Font>
                              </View>
                              <View className="w-full flex-row items-center px-3 py-1">
                                <View className="w-full flex-row">
                                  <Font className="text-xs pr-2 text-app-d1">
                                    {vendorReviews?.userReview?.replies
                                      ?.length ?? 0}
                                  </Font>
                                  <Pressable
                                    onPress={() =>
                                      setShowReviewId((prev) => ({
                                        ...prev,
                                        id:
                                          prev.id ===
                                            vendorReviews?.userReview?.id &&
                                          prev.showReplies
                                            ? null
                                            : prev.id ===
                                                vendorReviews?.userReview?.id &&
                                              prev.showAddReplyToReview
                                            ? vendorReviews?.userReview?.id
                                            : vendorReviews?.userReview?.id,
                                        showReplies:
                                          prev.id ===
                                            vendorReviews?.userReview?.id &&
                                          prev.showReplies
                                            ? false
                                            : prev.id ===
                                                vendorReviews?.userReview?.id &&
                                              !prev.showReplies
                                            ? true
                                            : true,
                                        showAddReplyToReview: false,
                                      }))
                                    }
                                  >
                                    <Font className="text-xs pr-2 text-app-d">
                                      Replies
                                    </Font>
                                  </Pressable>
                                </View>
                              </View>
                              <View className="w-full flex-col ">
                                {/* ---------------------- add reply to a review ------------------------ */}
                                {showReviewId?.id ===
                                  vendorReviews?.userReview?.id &&
                                  showReviewId?.showAddReplyToReview && (
                                    <View className="w-full flex-col py-1 px-3 my-3">
                                      <View className="w-full pb-1">
                                        <Font>Reply to this review</Font>
                                      </View>
                                      <View className="sm:w-full flex-col sm:flex-row justify-between">
                                        <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                          <Input
                                            type="text"
                                            value={replyToReviewInput}
                                            setValue={(ee) => {
                                              setReplyToReviewInput(ee);
                                            }}
                                            placeholder="reply to this comment..."
                                          ></Input>
                                          <Pressable className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md">
                                            <RemixIcon
                                              name="ri-send-plane-2-line"
                                              size={15}
                                            />
                                          </Pressable>
                                        </View>
                                      </View>
                                    </View>
                                  )}
                                {/* ---------------------- show replies to a review ------------------------ */}
                                {showReviewId?.id ===
                                  vendorReviews?.userReview?.id &&
                                  showReviewId?.showReplies &&
                                  vendorReviews?.userReview?.replies?.map(
                                    (eee, i) => (
                                      <View
                                        className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                        key={`comment_replies${i}`}
                                      >
                                        <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                          <Image
                                            // resizeMode="contain"
                                            source={require("../../../assets/user1.jpg")}
                                            className="w-10 h-10 rounded-full"
                                          ></Image>
                                        </View>
                                        <View className="w-full flex-1">
                                          <View className="w-full flex-row px-3 pt-1">
                                            <Font className="w-full py-1 font-bold">
                                              user_name
                                            </Font>
                                          </View>
                                          <View className="w-full pt-1 px-3">
                                            <Font className="text-xs text-app-d1">
                                              1-2-23
                                            </Font>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                              <Font>
                                                this is reply to other comment
                                              </Font>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    )
                                  )}
                              </View>
                            </View>
                          </View>
                        </ScrollView>
                      </Card>
                    )}
                    {!vendorReviews?.userReview && (
                      <Card title="Add a review">
                        <View className="w-full flex-col sm:flex-row justify-between items-center p-2 ">
                          <View className="w-full">
                            <View className="w-full flex-row items-center justify-start py-3">
                              {[...Array(5).keys()].map((e, i) => (
                                <Pressable
                                  onPress={() =>
                                    setReview((prev) => ({
                                      ...prev,
                                      rating: i + 1,
                                    }))
                                  }
                                  key={`starts_${i}`}
                                >
                                  <RemixIcon
                                    name={
                                      review.rating === 0
                                        ? "ri-star-line"
                                        : i + 1 <= review.rating
                                        ? "ri-star-fill"
                                        : "ri-star-line"
                                    }
                                    size={20}
                                    color={colors.app.i}
                                    className="flex-shrink-0"
                                    key={`icon_${i}`}
                                  ></RemixIcon>
                                </Pressable>
                              ))}
                            </View>
                            <View className="flex-row items-start justify-center pb-3">
                              <Input
                                type="longtext"
                                value={review.message}
                                setValue={(e) => {
                                  setReview((prev) => ({
                                    ...prev,
                                    message: e,
                                  }));
                                }}
                                // label="Email"
                                placeholder="Review"
                                // validation={true}
                                // isValid={(e) => setIsInputValid({ e, type: "email" })}
                              ></Input>
                            </View>
                            {isSendReviewInProgress.addReview ? (
                              <View className="flex-1 w-full items-center justify-center h-10 bg-app-e rounded-lg py-2">
                                <ActivityIndicator
                                  size={"small"}
                                  color={colors.app.a}
                                ></ActivityIndicator>
                              </View>
                            ) : (
                              <View className="w-full flex-row items-center justify-center">
                                <Button
                                  type="primary"
                                  label="Add review"
                                  onPress={submitReviewHandler}
                                ></Button>
                              </View>
                            )}
                          </View>
                        </View>
                      </Card>
                    )}
                    <Card title={`All reviews`} className="flex-1 w-full">
                      <ScrollView className="flex-1 w-full p-4 pt-0">
                        {vendorReviews?.reviews?.length > 0 &&
                        !isWallDataLoading ? (
                          <View className="flex-1 rounded-2xl">
                            {vendorReviews?.reviews?.map((e, i) => (
                              <View
                                className="w-full flex-row justify-between items-center bg-app-b rounded-lg p-2"
                                key={`comment_${i}`}
                              >
                                <View className="flex-row self-stretch justify-start items-start rounded-full mr-3">
                                  <Image
                                    source={
                                      e.user_profile_image_url &&
                                      e.user_profile_image_url !== ""
                                        ? {
                                            uri: `${DEV_API}/media/${e.user_profile_image_url}`,
                                          }
                                        : require("../../../assets/user1.jpg")
                                    }
                                    className="w-10 h-10 rounded-full"
                                  ></Image>
                                </View>
                                <View className="flex-1 flex-col rounded-md bg-app-c2">
                                  <View className="w-full flex-row px-3 pt-1">
                                    <Font className="py-1 font-bold">
                                      {e.user_name}
                                    </Font>
                                    {e.user_role === "vendor" && (
                                      <Font className="ml-2 my-1 px-1 bg-app-e3 rounded-md text-app-e text-xs flex-row items-center">
                                        {e.user_role}
                                      </Font>
                                    )}
                                  </View>
                                  <View className="w-full pt-1 px-3">
                                    <Font className="text-xs text-app-d1">
                                      {getFullDateTime(e.created_at) ?? ""}
                                    </Font>
                                  </View>

                                  <View className="w-full flex-row py-2 px-3">
                                    {getRatingStars({
                                      rating: e.rating ?? 0,
                                    })}
                                  </View>
                                  <View className="w-full px-3 pb-3">
                                    <Font>{e.message}</Font>
                                  </View>
                                  <View className="w-full flex-row items-center px-3 py-1">
                                    {/* ---------------- like to review button ---------------- */}
                                    {/* <View className="w-1/3 flex-row">
                                        <Font className="text-xs pr-2 text-app-d1">
                                          {e.likes?? 0}
                                        </Font>
                                        <Pressable
                                          onPress={() =>
                                            onLikeAReview({
                                              id: e.id,
                                              type: e.is_liked
                                                ? "unlike"
                                                : "like",
                                            })
                                          }
                                          className="flex-row items-center"
                                        >
                                          <RemixIcon
                                            name={
                                              e.is_liked
                                                ? "ri-thumb-up-fill"
                                                : "ri-thumb-up-line"
                                            }
                                            size={11}
                                            color={
                                              e.is_liked
                                                ? colors.app.f
                                                : colors.app.d1
                                            }
                                          />
                                        </Pressable>
                                      </View> */}
                                    {/* ---------------- reply to review button ---------------- */}
                                    {userData?.id !== e?.user_id && (
                                      <View className="w-1/3 flex-row">
                                        <Pressable
                                          onPress={() => {
                                            setReplyToReviewInput("");
                                            setShowReviewId((prev) => ({
                                              ...prev,
                                              id:
                                                prev.id === e.id &&
                                                prev.showAddReplyToReview
                                                  ? null
                                                  : prev.id === e.id &&
                                                    prev.showReplies
                                                  ? e.id
                                                  : e.id,
                                              showAddReplyToReview:
                                                prev.id === e.id &&
                                                prev.showAddReplyToReview
                                                  ? false
                                                  : true,
                                              showReplies: false,
                                            }));
                                          }}
                                        >
                                          <Font className="text-xs pr-2 text-app-d">
                                            Reply
                                          </Font>
                                        </Pressable>
                                      </View>
                                    )}
                                    <View className="w-1/3 flex-row">
                                      <Font className="text-xs pr-2 text-app-d1">
                                        {e.replies?.length}
                                      </Font>
                                      <Pressable
                                        onPress={() => {
                                          setShowReviewId((prev) => ({
                                            ...prev,
                                            id:
                                              prev.id === e.id &&
                                              prev.showReplies
                                                ? null
                                                : prev.id === e.id &&
                                                  prev.showAddReplyToReview
                                                ? e.id
                                                : e.id,
                                            showReplies:
                                              prev.id === e.id &&
                                              prev.showReplies
                                                ? false
                                                : prev.id === e.id &&
                                                  !prev.showReplies
                                                ? true
                                                : true,
                                            showAddReplyToReview: false,
                                          }));
                                        }}
                                      >
                                        <Font className="text-xs pr-2 text-app-d">
                                          Replies pppp
                                        </Font>
                                      </Pressable>
                                    </View>
                                  </View>
                                  <View className="w-full flex-col ">
                                    {/* ------------------- reply to a review ------------------- */}
                                    {showReviewId?.id === e?.id &&
                                      showReviewId?.showAddReplyToReview && (
                                        <View className="w-full flex-col py-1 px-3 my-3">
                                          <View className="w-full pb-1">
                                            <Font>Reply to this review</Font>
                                          </View>
                                          <View className="sm:w-full flex-col sm:flex-row justify-between">
                                            <View className="w-full pb-3 sm:pb-0 sm:w-3/4 flex-row items-center justify-center rounded-l-md">
                                              <Input
                                                type="text"
                                                value={replyToReviewInput}
                                                setValue={(ee) => {
                                                  setReplyToReviewInput(ee);
                                                }}
                                                placeholder="reply to this comment..."
                                              ></Input>
                                              <Pressable
                                                className="bg-app-c h-10 px-3 flex justify-center items-center border border-app-c rounded-r-md"
                                                onPress={() =>
                                                  onSendReplyToAReview(e?.id)
                                                }
                                              >
                                                {!isSendReviewInProgress.replyToReview ? (
                                                  <RemixIcon
                                                    name="ri-send-plane-2-line"
                                                    size={15}
                                                  />
                                                ) : (
                                                  <ActivityIndicator
                                                    size={"small"}
                                                    color={colors.app.e}
                                                  ></ActivityIndicator>
                                                )}
                                              </Pressable>
                                            </View>
                                          </View>
                                        </View>
                                      )}
                                    {/* ---------------------- replies to a review ------------------------ */}
                                    {showReviewId?.id === e?.id &&
                                      showReviewId?.showReplies &&
                                      e?.replies?.map((eee, i) => (
                                        <View
                                          className="w-full flex-row justify-between items-center p-2 border-b border-app-c"
                                          key={`comment_replies${i}`}
                                        >
                                          <View className="flex-row justify-start overflow-hidden rounded-full pb-4 mr-3">
                                            <Image
                                              // resizeMode="contain"
                                              source={require("../../../assets/user1.jpg")}
                                              className="w-10 h-10 rounded-full"
                                            ></Image>
                                          </View>
                                          <View className="w-full flex-1">
                                            <View className="w-full flex-row px-3 pt-1">
                                              <Font className="w-full py-1 font-bold">
                                                {eee.user_name}
                                              </Font>
                                            </View>
                                            <View className="w-full pt-1 px-3">
                                              <Font className="text-xs text-app-d1">
                                                {getFullDateTime(
                                                  eee.created_at
                                                ) ?? ""}
                                              </Font>
                                            </View>
                                            <View className="w-full flex-1">
                                              <View className="w-full flex-row bg-app-c2 p-3 rounded-md">
                                                <Font>{eee.message}</Font>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      ))}
                                  </View>
                                </View>
                              </View>
                            ))}
                          </View>
                        ) : vendorReviews?.reviews?.length === 0 &&
                          !isWallDataLoading ? (
                          <Card
                            // title={`Reviews`}
                            className="flex"
                          >
                            <View className="w-full flex-row h-80 justify-center items-center">
                              <Font className="text-app-d1">
                                No reviews found.
                              </Font>
                            </View>
                          </Card>
                        ) : (
                          <View className="flex-1 w-full justify-center h-40">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.e}
                            ></ActivityIndicator>
                          </View>
                        )}
                      </ScrollView>

                      {vendorReviews?.pagination?.total_records > 5 &&
                        (vendorReviews?.pagination?.to > 5
                          ? vendorReviews?.pagination?.to <=
                            vendorReviews?.pagination?.total_records -
                              reviewsPerPage
                          : true) && (
                          <View className="w-full flex-row justify-center">
                            {isPostsLoading ? (
                              <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                                <ActivityIndicator
                                  size={"small"}
                                  color={colors.app.a}
                                ></ActivityIndicator>
                              </Pressable>
                            ) : (
                              <Pressable
                                onPress={() => onLoadMoreReviews()}
                                className="bg-app-e w-32 py-2 rounded-lg"
                              >
                                <Font className="text-app-a text-center">
                                  Load More
                                </Font>
                              </Pressable>
                            )}
                          </View>
                        )}
                    </Card>
                  </>
                ) : tab === "Listings" ? (
                  <View className="flex-1 rounded-2xl">
                    {vendorListings?.listings?.length > 0 &&
                    !isVendorListingsLoading ? (
                      vendorListings?.listings?.map((e, i) => (
                        <Pressable
                          className="w-full bg-app-a  active:bg-app-c1 rounded-lg p-0 border border-gray-200 flex-col lg:flex-row mb-4"
                          key={`services_${i}`}
                          onPress={() =>
                            navigation.navigate("listing", { id: e.id })
                          }
                        >
                          <View className="overflow-hidden rounded-none  rounded-t-lg lg:rounded-l-lg">
                            <Image
                              source={
                                e.cover_image_url && e.cover_image_url !== ""
                                  ? {
                                      uri: `${DEV_API}/media/${e.cover_image_url}`,
                                    }
                                  : require("../../../assets/bg.jpg")
                              }
                              className="w-full h-40 lg:w-40 lg:h-40 "
                            ></Image>

                            {/* <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                                <Pressable className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden">
                                  <RemixIcon
                                    name="bookmark-fill"
                                    color={colors.app.e}
                                    size="18"
                                  ></RemixIcon>
                                </Pressable>
                                <View className="flex-1"></View>
                              </View> */}
                          </View>
                          <View className="justify-center p-4 flex-1 ">
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <Font
                                className="text-xs lg:text-md text-app-f"
                                numberOfLines={1}
                                weight={500}
                              >
                                {e.listing_category.title}
                              </Font>
                            </View>
                            <Font
                              className="text-[15px] text-app-d leading-4"
                              numberOfLines={2}
                              weight={600}
                            >
                              {e.title}
                            </Font>
                            {!!(e.city || e.state || e.country) && (
                              <View
                                className="flex-row items-center mb-1 lg:mb-1"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <Font
                                  className=" text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {e.city ? `${e.city},` : ""}
                                  {e.state ? `${e.state},` : ""}
                                  {e.country ? `${e.country}` : ""}
                                </Font>
                              </View>
                            )}
                            {e.max_price && e.min_price && (
                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <Font
                                  className="text-[15px] text-app-d"
                                  numberOfLines={1}
                                  weight={600}
                                >
                                  {" "}
                                  $ {e.max_price}
                                  {" -  "}$ {e.min_price}{" "}
                                </Font>
                              </View>
                            )}
                            <View
                              className="flex-row items-center"
                              style={{
                                marginVertical: 2,
                              }}
                            >
                              <RemixIcon
                                name="star-fill"
                                size={16}
                                color={colors.app.i}
                                className="mr-1 flex-shrink-0"
                              ></RemixIcon>
                              <Font
                                className="text-app-d1"
                                weight={500}
                                numberOfLines={1}
                              >
                                {" "}
                                {e.average_rating} | {e.total_reviews} reviews
                              </Font>
                            </View>
                          </View>
                          <View className="hidden lg:flex p-4">
                            <Pressable>
                              <RemixIcon
                                name="bookmark-fill"
                                color={colors.app.e}
                                size="24"
                              ></RemixIcon>
                            </Pressable>
                          </View>
                        </Pressable>
                      ))
                    ) : vendorListings?.listings?.length === 0 &&
                      !isVendorListingsLoading ? (
                      <View className="flex-1 w-full">
                        <Card className="w-full flex-1 ">
                          <View className="w-full h-60 flex-row justify-center items-center">
                            <Font className="text-app-d1">
                              No listings found.
                            </Font>
                          </View>
                        </Card>
                      </View>
                    ) : (
                      <View className="flex-1 w-full">
                        <Card className="w-full flex-1 ">
                          <View className="w-full justify-center h-60">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.e}
                            ></ActivityIndicator>
                          </View>
                        </Card>
                      </View>
                    )}
                    {vendorListings?.pagination?.total >
                      vendorListingsPerPage &&
                      vendorListings?.pagination?.to <=
                        vendorListings?.pagination?.total -
                          vendorListingsPerPage && (
                        <View className="w-full flex-row justify-center">
                          {isVendorListingsLoading ? (
                            <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.a}
                              ></ActivityIndicator>
                            </Pressable>
                          ) : (
                            <Pressable
                              onPress={() => getVendorListings()}
                              className="bg-app-e w-32 py-2 rounded-lg"
                            >
                              <Font className="text-app-a text-center">
                                Load More
                              </Font>
                            </Pressable>
                          )}
                        </View>
                      )}
                  </View>
                ) : tab === "Products" ? (
                  <View className="w-full flex-row flex-wrap grid-flow-row grid justify-center">
                    {vendorProducts?.products?.length > 0 &&
                    !isVendorProductsLoading ? (
                      vendorProducts?.products?.map((e, i) => (
                        <View
                          className="w-[46%] lg:w-[32%] xl:w-[24%] m-1 place-self-start"
                          key={`products_${i}`}
                        >
                          <Pressable
                            className="w-full rounded-lg overflow-hidden border border-gray-200 bg-app-a active:bg-app-c1"
                            onPress={() =>
                              navigation.navigate("product", { id: e.id })
                            }
                          >
                            <View className="h-44 lg:h-64 w-full">
                              <Image
                                source={
                                  e.cover_image_url && e.cover_image_url !== ""
                                    ? {
                                        uri: `${DEV_API}/media/${e.cover_image_url}`,
                                      }
                                    : require("../../../assets/bg.jpg")
                                }
                                className="w-full h-full"
                              ></Image>
                              {/* <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                                  <Pressable className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden border border-gray-200">
                                    <RemixIcon
                                      name="bookmark-fill"
                                      color={colors.app.e}
                                      size="18"
                                    ></RemixIcon>
                                  </Pressable>
                                  <View className="flex-1"></View>
                                </View> */}
                            </View>
                            <View className="p-2">
                              <Font
                                className="text-md text-app-d mb-1 leading-5"
                                weight={600}
                                numberOfLines={2}
                              >
                                {e.title}
                              </Font>

                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <Font
                                  className="text-[15px] text-app-d"
                                  numberOfLines={1}
                                  weight={700}
                                >
                                  {e.currency_symbol +
                                    " " +
                                    +e.sale_price / 100}
                                </Font>
                                <Font
                                  className="text-md text-app-e1 line-through ml-2"
                                  weight={600}
                                  numberOfLines={1}
                                >
                                  {" "}
                                  {e.currency_symbol + " " + +e.price / 100}
                                </Font>
                              </View>
                              <View
                                className="flex-row items-center"
                                style={{
                                  marginVertical: 2,
                                }}
                              >
                                <RemixIcon
                                  name="star-fill"
                                  size={16}
                                  color={colors.app.i}
                                  className="mr-1 flex-shrink-0"
                                ></RemixIcon>
                                <Font
                                  className="text-md text-app-d1"
                                  weight={500}
                                  numberOfLines={1}
                                >
                                  {" "}
                                  {e.product_details.average_rating} |{" "}
                                  {e.product_details.total_reviews} reviews
                                </Font>
                              </View>
                            </View>
                          </Pressable>
                        </View>
                      ))
                    ) : vendorProducts?.products?.length === 0 &&
                      !isVendorProductsLoading ? (
                      <View className="flex-1 w-full">
                        <Card className="w-full flex-1 ">
                          <View className="w-full h-60 flex-row justify-center items-center">
                            <Font className="text-app-d1">
                              No products found.
                            </Font>
                          </View>
                        </Card>
                      </View>
                    ) : (
                      <View className="flex-1 w-full">
                        <Card className="w-full flex-1 ">
                          <View className="w-full justify-center h-60">
                            <ActivityIndicator
                              size={"small"}
                              color={colors.app.e}
                            ></ActivityIndicator>
                          </View>
                        </Card>
                      </View>
                    )}
                    {vendorProducts?.pagination?.total >
                      vendorProductsPerPage &&
                      vendorProducts?.pagination?.to <=
                        vendorProducts?.pagination?.total -
                          vendorProductsPerPage && (
                        <View className="w-full flex-row justify-center">
                          {isVendorProductsLoading ? (
                            <Pressable className="bg-app-e w-32 py-2 rounded-lg">
                              <ActivityIndicator
                                size={"small"}
                                color={colors.app.a}
                              ></ActivityIndicator>
                            </Pressable>
                          ) : (
                            <Pressable
                              onPress={() => getVendorProducts()}
                              className="bg-app-e w-32 py-2 rounded-lg"
                            >
                              <Font className="text-app-a text-center">
                                Load More
                              </Font>
                            </Pressable>
                          )}
                        </View>
                      )}
                  </View>
                ) : null}
              </View>
            </View>
            <View className="flex w-full lg:w-3/12 pl-2 flex-1">
              <Widget></Widget>
            </View>
          </View>
          <Footer></Footer>
        </ScrollView>
      </View>
    </View>
  );
};
