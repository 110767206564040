import React, { useContext, useEffect, useState } from "react";
import { Pressable, View, TouchableOpacity, Modal } from "react-native";
import {
  getStats,
  getRecentActivites,
  RemoveMemberApi,
  getGroupMembers,
  GetGroupJoinRequest,
} from "../../services/api";
import { Font } from "../../components/font";

import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import rightArrow from "../../../assets/right-arrow.svg";
import { TouchableWithoutFeedback } from "react-native";
import { PleaseWaitModal } from "../../components/modals";
import { ResponseModal } from "../../components/response-modal";
import SVGLoader from "../../../utils/SVGLoader";
import { Card } from "../../components/card";
import colors from "../../../colors";
import ConfirmModal from "../../components/ConfirmModal";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";

export default function GroupMembers({
  MembersLists,
  navigation,
  route,
  id,
  groupId,
  userToken,
}) {
  const [deleteModal, setIsDeleteModal] = useState(false);
  const { userData } = useContext(UserContext);
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [IsMember, setIsMember] = useState();
  const [IsMemberName, setIsMemberName] = useState();
  const [isPleaseWaitModal, setIsPleaseWaitModal] = useState(false);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [groupJoinRequests, setGroupJoinRequests] = useState([]);
  // const GetMembers = async () => {
  //   try {
  //     let res = await getGroupMembers(id, userToken);
  //     if (res.status === 200) {
  //       // console.log(res.data.data, "res for get members 200");
  //       setMembersList(res.data.data);
  //       // console.log(res.data.data[0].user, "res for get members 200");
  //     } else console.log(res.data.data.user, "res for get members");
  //   } catch (error) {
  //     console.log("Error Getting Stats : ", error.response);
  //   }
  // };
  const GetRequests = async () => {
    try {
      const res = await GetGroupJoinRequest(groupId, userToken);
      if (res.status == 200) {
        setGroupJoinRequests(res.data.data);
      } else {
        console.log(
          "group join requests error response:",
          res.data,
          res.status
        );
      }
    } catch (error) {
      console.log("group join requests error:", error);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const [MembersList, setMembersList] = useState(MembersLists);
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);

  useEffect(() => {
    GetRequests();
  }, []);

  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };
  function groupHandler() {
    setIsVisible(!isVisible);
  }

  const GetMembers = async () => {
    try {
      let res = await getGroupMembers(groupId, userToken);
      if (res.status === 200) {
        // console.log(res.data.data, "res for get members 200");
        setMembersList(res.data.data);
        // console.log(res.data.data[0].user, "res for get members 200");
      } else console.log(res.data.data.user, "res for get members");
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  // const MakeAdmin = async () => {
  //   try {
  //     setIsPleaseWaitModal(true);
  //     let res = await MakeAdminApi(groupId, IsMember);
  //     console.log(res, "make admin check");
  //     if (res.status === 200) {
  //       setIsPleaseWaitModal(false);
  //       setResponseModal({
  //         visible: true,
  //         message: IsMemberName + " is now Admin !!!",
  //         DismissText: "Dismiss",
  //       });
  //       GetMembers();
  //       console.log(res.data, "make admin success");
  //     } else {
  //       setIsPleaseWaitModal(false);
  //       console.log(res, "make admin error");
  //     }
  //   } catch (error) {
  //     setIsPleaseWaitModal(false);
  //     console.log("Error Getting Stats : ", error.response, error);
  //   }
  // };
  const RemoveMember = async () => {
    try {
      setIsDeleteModal(false);
      setIsPleaseWaitModal(true);
      let res = await RemoveMemberApi(groupId, IsMember, userToken);
      console.log(res, "remove member check");
      if (res.status === 200) {
        setIsPleaseWaitModal(false);

        GetMembers();
        console.log(res.data, "remove member success");
      } else {
        setIsPleaseWaitModal(false);
        console.log(res, "remove member error");
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
      console.log("Error Getting Stats : ", error.response, error);
    }
  };

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={RemoveMember}
        confirmText={"Remove"}
        bodyText={"Are you sure you want to Remove the Member ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className="max-w-screen-lg w-full h-full">
        <View className="max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Group Members Tab - section----------------------------------- */}
          <>
            <View className=" w-full flex-col item-center">
              <View className="lg:p-4 flex-col justify-center item-center lg:bg-app-a lg:rounded-lg">
                <View className="flex-col lg:flex-row">
                  <TouchableOpacity
                    className="px-4 flex-row justify-between flex-1 bg-white border border-gray-200  rounded-2xl py-2 mb-2 shadow-lg shadow-gray-300"
                    onPress={() =>
                      navigation.navigate("group-join-requests", {
                        id: userData.id,
                        groupId: groupId,
                        // groupMembersLists: groupJoinRequests,
                      })
                    }
                  >
                    <View className="flex-row justify-start items-center">
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-user-fill"
                          size={16}
                          color={colors.app.g4}
                          className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                      </View>

                      <Font className="lg:text-lg text-base font-medium">
                        Join Requests
                      </Font>
                    </View>
                    <Pressable
                      className="w-15 h-8 flex justify-center items-center"
                      onPress={() => navigation.navigate("group-join-requests")}
                    >
                      <View className="flex-row justify-center items-center">
                        <Font className="lg:text-lg text-base font-medium px-2 leading-5">
                          {groupJoinRequests?.length || 0} new
                        </Font>
                        <RemixIcon
                          name="ri-checkbox-blank-circle-fill"
                          size={16}
                          color={"#75f94c"}
                          className="mr-1 lg:mr-4 flex-shrink-0"
                        />
                      </View>
                    </Pressable>
                  </TouchableOpacity>
                  <View className="hidden lg:flex lg:w-4" />
                  <TouchableOpacity
                    className="px-4 flex-row justify-between flex-1 bg-white border border-gray-200  mb-2 rounded-2xl py-2 shadow-lg shadow-gray-300"
                    onPress={() =>
                      navigation.navigate("add-member", {
                        id: userData.id,
                        groupId,
                      })
                    }
                  >
                    <View className="flex-row justify-start items-center">
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-group-fill"
                          size={16}
                          color={colors.app.g4}
                          className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                      </View>
                      <Font className="lg:text-lg text-base font-medium">
                        Add / Invite
                      </Font>
                    </View>
                    <View>
                      <View className="flex-row justify-center items-center">
                        <Pressable
                          className="w-8 h-8"
                          onPress={() => navigation.navigate("add-member")}
                        >
                          <SVGLoader
                            src={rightArrow}
                            height={30}
                            width={30}
                            webHeight={"40em"}
                            webWidth={"auto"}
                          />
                        </Pressable>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
                <View className="w-full px-4 flex-col  bg-white border border-gray-200  mb-2 rounded-2xl py-2 shadow-lg shadow-gray-300">
                  <View className="w-full mb-3 pb-2 flex-row item-center border-b-2 border-b-gray-200 ">
                    <View className="flex justify-center items-center">
                      <RemixIcon
                        name="ri-mail-fill"
                        size={16}
                        color={colors.app.g4}
                        className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:text-lg text-base font font-bold self-center">
                      Invite via email
                    </Font>
                  </View>
                  <View className="">
                    <View className="p-1 flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                      <TextInput
                        className="w-full ml-2"
                        placeholder="Enter mail Id here"
                        placeholderTextColor="#94a3b8"
                        type="text"
                      />
                    </View>
                    <View className="mt-2 flex-row justify-end item-center w-full">
                      <Font className="lg:text-lg text-sm text-app-e font-semibold">
                        Invite
                      </Font>
                    </View>
                  </View>
                </View>
                {/* </Card> */}
              </View>
              <View className="w-full mt-0 p-2 pb-0 lg:mt-6 flex-row justify-center item-center">
                <View className="px-10 py-2 flex-row justify-center item-center bg-app-a rounded-tl-lg rounded-tr-lg">
                  <Font className="text-sm">{MembersList.length} members</Font>
                  {/* <View className="flex justify-center items-center">
                    <RemixIcon
                      name="ri-checkbox-blank-circle-fill"
                      size={4}
                      color={colors.app.i4}
                      className="mr-2 ml-2 mt-1.5 lg:mt-0 w-2 h-2 flex-shrink-0 justify-center items-center"
                    />
                  </View> */}
                  {/* <Font className="text-sm">members</Font> */}
                </View>
              </View>
              <View className="p-2 w-full flex-col justify-center items-center bg-app-a rounded-lg border-t border-t-gray-300">
                {MembersList?.map((member) => (
                  <Pressable
                    onPress={() => {
                      if (member?.group_role == "owner") {
                        return;
                      } else if (member?.group_role == "admin") {
                        return;
                      }
                      setIsMemberName(
                        member.user.first_name + " " + member.user.last_name
                      );
                      setIsMenuVisible(true);
                      setIsMember(member?.id);
                    }}
                    className="p-2 my-2 mb-3 w-full flex-row justify-between items-center lg:border-b lg:border-b-gray-300"
                    key={member?.id}
                  >
                    <View>
                      <Font className="font-bold text-base">
                        {member.user.first_name + " " + member.user.last_name}
                      </Font>
                      <Font>{member.user.email}</Font>
                      <Font>{member.user.phone}</Font>
                    </View>

                    {(member.group_role == "owner" ||
                      member.group_role === "admin") && (
                      <View className="flex-row justify-center items-center">
                        <View
                          className={`px-2 py-1  flex justify-center items-center rounded-lg ${
                            member.group_role === "owner"
                              ? "bg-red-200 border-2 border-app-e mr-4"
                              : "bg-green-200 border-2 border-app-g mr-4"
                          }`}
                        >
                          {member.group_role == "owner" && (
                            <Font className="text-xs text-app-e">Owner</Font>
                          )}
                          {member.group_role === "admin" && (
                            <Font className="text-xs text-app-g">Admin</Font>
                          )}
                        </View>

                        {/* <View className="ml-2 flex justify-center items-center">
                          {member.group_role === "admin" && (
                            <RemixIcon
                              name="ri-arrow-down-s-line"
                              size={16}
                              color={colors.app.d4}
                              className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                            />
                          )}
                        </View> */}
                      </View>
                    )}
                    {/* {isMenuVisible && IsMember == member?.id ? (
                      <Modal
                        visible={isMenuVisible}
                        transparent={true}
                        onRequestClose={closeMenu}
                      >
                        <TouchableWithoutFeedback onPress={closeMenu}>
                          <View
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="w-full h-full justify-center items-center bg-black/50"
                          >
                            <TouchableWithoutFeedback>
                              <View
                                style={{
                                  // position: "absolute",
                                  // left: buttonPosition.left,
                                  // top: buttonPosition.top,
                                  backgroundColor: "white",
                                  padding: 10,
                                  borderRadius: 10,
                                  width: 120,
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.25,
                                  shadowRadius: 3.84,
                                  elevation: 5,
                                }}
                              >
                                <ScrollView>
                                  <Pressable
                                    onPress={() => {
                                      // setIsEditPostModal(true);
                                      if (member.group_role === "owner") {
                                        return;
                                      } else if (
                                        member.group_role === "admin"
                                      ) {
                                        return;
                                      } else {
                                        setIsDeleteModal(true);
                                        // RemoveMember();
                                        // MakeAdmin();
                                      }
                                      closeMenu();
                                    }}
                                    className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                                  >
                                    <Font className="">Remove</Font>
                                  </Pressable>
                                  <View
                                    style={{
                                      borderWidth: 0.4,
                                      marginVertical: 4,
                                    }}
                                  ></View>
                                  <Pressable
                                    // onPress={() => {
                                    //   setIsDeleteModal(true);
                                    //   closeMenu();
                                    // }}
                                    className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
                                  >
                                    <Font className="text-app-e">Example</Font>
                                  </Pressable>
                                </ScrollView>
                              </View>
                            </TouchableWithoutFeedback>
                          </View>
                        </TouchableWithoutFeedback>
                      </Modal>
                    ) : (
                      <></>
                    )} */}
                  </Pressable>
                ))}
              </View>
            </View>
          </>
        </View>
      </View>
    </ScrollView>
  );
}
