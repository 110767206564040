import { View, Text, ScrollView, Pressable, Image } from "react-native";
import React from "react";
import { AntDesign } from "@expo/vector-icons";
import { A, Font } from "../../components/font";
import colors from "../../../colors";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "../../components/input";
import OTPTextInput from "react-native-otp-textinput";
import { CountdownCircleTimer } from "react-native-countdown-circle-timer";
import axios from "axios";
import { DEV_API } from "../../services/api";
import { PleaseWaitModal } from "../../components/modals";
import { useSharedContext } from "../../context/sharedContext";

const VerifyOtp = ({ navigation, route }) => {
  const [userNumber, setUserNumber] = React.useState("");
  const [startTimer, setStartTimer] = React.useState(0);
  const [otp, setOtp] = React.useState("");
  const { setTemporaryData, temporaryData } = useSharedContext();

  let otp_hash = route.params.otp_hash;

  const [modal, setModal] = React.useState({
    showSuccess: false,
    isVisible: false,
    message: "Test Modal",
    button1: true,
    showLoader: true,
  });

  React.useEffect(() => {
    if (temporaryData?.mobileSMSOTP) {
      alert(`SMS OTP: ${temporaryData.mobileSMSOTP}`);
    }
  }, [temporaryData?.mobileSMSOTP]);

  React.useEffect(() => {
    (async () => {
      const data = await AsyncStorage.getItem("userNumber");
      setUserNumber(data || "xx xx xxxx xxxx");
    })();
  }, []);

  const resendOtp = async () => {
    try {
      setModal({
        showSuccess: false,
        isVisible: true,
        message: "Please Wait !!!",
        button1: false,
        showLoader: true,
      });
      const res = await axios.post(DEV_API + "/auth/resend-sms-otp", {
        phone: userNumber,
        hash: otp_hash,
      });
      if (res.status == 200) {
        setStartTimer((prevKey) => prevKey + 1);
        setModal({
          showSuccess: true,
          isVisible: true,
          message: "OTP Resend successfully !!!",
          button1: true,
          showLoader: true,
        });
        otp_hash = res.data.otp_hash;
        navigation.setParams({ otp_hash: res.data.otp_hash });
        if (res.data?.otp) setTemporaryData({ mobileSMSOTP: res.data.otp });
      } else {
        setModal({
          showSuccess: false,
          isVisible: true,
          message: "Something went wrong !!!",
          button1: true,
          showLoader: false,
        });
        // console.log(res, "err res Resend Otp");
      }
    } catch (error) {
      setModal({
        showSuccess: false,
        isVisible: true,
        message: "Something went wrong !!!",
        button1: true,
        showLoader: false,
      });
      // console.log(error, "Error in Resend Otp");
    }
  };

  const VerifyOTP = async () => {
    try {
      setModal({
        showSuccess: false,
        isVisible: true,
        message: "Please Wait !!!",
        button1: false,
        showLoader: true,
      });
      const res = await axios.post(DEV_API + "/auth/verify-otp", {
        otp: otp,
        hash: otp_hash,
      });
      // console.log(res, "res for otp verification");
      if (res?.data?.status == "success") {
        // alert("success");
        setModal({
          showSuccess: true,
          isVisible: true,
          message: "OTP Verified successfully !!!",
          button1: true,
          showLoader: false,
        });
        setTimeout(() => {
          navigation.navigate("signin");
          setModal({
            ...modal,
            isVisible: false,
          });
        }, 2000);
        // alert(res?.data?.message);
      } else if (res?.data?.status != "success") {
        setModal({
          showSuccess: false,
          isVisible: false,
          message: "Something went wrong !!!",
          button1: true,
          showLoader: false,
        });
        // return;
      } else {
        setModal({
          showSuccess: false,
          isVisible: false,
          message: "Something went wrong !!!",
          button1: true,
          showLoader: false,
        });
        // alert(res?.data?.message);
      }
    } catch (error) {
      setModal({
        showSuccess: false,
        isVisible: true,
        message: "Something went wrong !!!",
        button1: true,
        showLoader: false,
      });
    }
  };

  const verifyHandler = () => {
    if (otp == "" || otp.length != 4) {
      setModal({
        showSuccess: false,
        isVisible: true,
        message: "Please enter a valid otp !!!",
        button1: true,
        showLoader: false,
      });
    } else {
      VerifyOTP();
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-start" }}
          keyboardShouldPersistTaps="always"
        >
          <View className="w-80 items-center mt-16">
            <Pressable onPress={() => navigation.replace("app")}>
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 mb-8"
              ></Image>
            </Pressable>
            <View className="h-[600px] justify-center items-center">
              <View className="h-[100px] w-[100px]">
                <CountdownCircleTimer
                  key={startTimer}
                  isPlaying={true}
                  size={100}
                  duration={60}
                  colors={["#f21411", "#f21411", "#f21411", "#f21411"]}
                  colorsTime={[7, 5, 2, 0]}
                >
                  {({ remainingTime }) => <Text>{remainingTime}</Text>}
                </CountdownCircleTimer>
              </View>
              <Font className="text-lg my-4 text-center">
                Please Enter the Otp You have Received on {userNumber}
              </Font>
              <View className="mb-10 lg:w-full">
                <OTPTextInput
                  handleTextChange={(e) => {
                    console.log(e);
                    setOtp(e);
                  }}
                  // caretHidden={trueokay} // hide text cursor
                  inputCount={4}
                  autoFocus={true}
                  tintColor={"#f21411"}
                  // offTintColor={"grey"}
                  containerStyle={{ width: "100%" }}
                  textInputStyle={{
                    borderColor: "grey",
                    borderWidth: 1,
                    borderRadius: 5,
                    width: "20%",
                  }}
                />
              </View>
              {/* <Pressable  className={" self-center w-full mt-4"} style={{backgroundColor:""}} onPress={() => verifyHandler()}>
          <Text >
            Verify
            </Text>
          </Pressable> */}

              <View className="w-80 mt-4 mb-2">
                <Button
                  type={`${otp.length != 4 ? "disabled" : "primary"}`}
                  label="Verify"
                  onPress={() => verifyHandler()}
                ></Button>
              </View>
              <View className="w-full h-[1px] bg-gray-200 my-2"></View>
              <View className="w-full my-2">
                <Font className="text-center mb-2">
                  Didn't received OTP yet ?
                </Font>
                <Pressable onPress={() => resendOtp()}>
                  <Font className="font-semibold text-app-e text-sm text-center">
                    Resend OTP
                  </Font>
                </Pressable>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d"></View>
      {modal.isVisible && (
        <View>
          <PleaseWaitModal
            showLoader={modal.showLoader}
            showSuccess={modal.showSuccess}
            message={modal.message}
            modalVisible={modal.isVisible}
            isButton1={modal.button1}
            setVisible={(e) => setModal({ ...modal, isVisible: e })}
            primaryButtonLabel="Dismiss"
            onPrimaryButtonPress={() => {
              setModal({ ...modal, isVisible: false });
              // navigation.navigate("app", { screen: "home" });
            }}
          ></PleaseWaitModal>
        </View>
      )}
    </View>
  );
};

export default VerifyOtp;
