import React from "react";
import { CustomerRouting } from "./screens/customer/routing";
import { AuthProvider } from "./context/authContext";
import { UserDataProvider } from "./context/userContext";
import { BusinessesProvider } from "./context/businessesContext";
import { ProductsProvider } from "./context/productsContext";
import { OrdersProvider } from "./context/ordersContext";
import { SharedContextProvider } from "./context/sharedContext";
import { MessagesProvider } from "./context/messagesContext";
import { BlogsContextProvider } from "./context/blogsContext";
import { StripeContextProvider } from "./context/stripeContext";
import VendorFeedProvider from "./context/user-feed-context";
import GroupContextProvider from "./context/groupsContext";
import { NotificationsProvider } from "./context/notificationContext";
export const Main = () => {
  return (
    <AuthProvider>
      <StripeContextProvider>
        <BlogsContextProvider>
          <UserDataProvider>
            <MessagesProvider>
              <ProductsProvider>
                <BusinessesProvider>
                  <OrdersProvider>
                    <SharedContextProvider>
                      <VendorFeedProvider>
                        <GroupContextProvider>
                          <NotificationsProvider>
                            <CustomerRouting></CustomerRouting>
                          </NotificationsProvider>
                        </GroupContextProvider>
                      </VendorFeedProvider>
                    </SharedContextProvider>
                  </OrdersProvider>
                </BusinessesProvider>
              </ProductsProvider>
            </MessagesProvider>
          </UserDataProvider>
        </BlogsContextProvider>
      </StripeContextProvider>
    </AuthProvider>
  );
};
